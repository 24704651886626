
.ant-modal-wrap {
    .mga__attribute_popup {
        width: 100%;
        max-width: 800px;
        padding: 30px 30px;
        box-sizing: border-box;
        .attribute_info_popup {
            .information_popup_text {
                width: 100%;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                margin-bottom: 30px;
                h2 {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: #000000;
                    margin: 0px 0px;
                }
            }
            .attribute_info_content {
                .info_cmnt_text_wrap {
                    width: 100%;
                    display: inline-block;
                    vertical-align: top;
                    h3 {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        color: #000000;
                        margin: 0px 0px 12px;
                    }
                    p {
                        width: 100%;
                        display: inline-block;
                        vertical-align: top;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: #777777;
                        margin: 0px 0px;
                    }
                    &:not(:last-child) {
                        border-bottom: 1px dashed #24537B;
                        margin-bottom: 20px;
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .ant-modal-wrap {
        .mga__attribute_popup {
            .attribute_info_popup {
                .information_popup_text {
                    h2 {
                        font-size: 24px;
                    }
                    margin-bottom: 20px;
                }
                .attribute_info_content {
                    .info_cmnt_text_wrap {
                        &:not(:last-child) {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
            padding: 20px 20px;
        }
    }
}
@media only screen and (max-width: 575px) {
    .ant-modal-wrap {
        .mga__attribute_popup {
            .attribute_info_popup {
                .information_popup_text {
                    h2 {
                        font-size: 20px;
                    }
                }
                .attribute_info_content {
                    .info_cmnt_text_wrap {
                        h3 {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}


// Popup Menu Index

:where(.css-dev-only-do-not-override-usln0u).ant-modal-root{
	.ant-modal-mask {
		z-index: 1000000;
	}
}
:where(.css-dev-only-do-not-override-usln0u).ant-modal-root{
.ant-modal-wrap{
    z-index: 1000000;
}
}