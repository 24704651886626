.table-container{
    margin-bottom: 20px;
    .responsive-table {
        width: 100%;
        border-collapse: collapse;
        th {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
            background-color: transparent;
        }
        td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
        }
    }
}
@media screen and (max-width: 600px) {
    .table-container{
        .responsive-table {
            display: block;
            overflow-x: auto;
            th {
                font-size: 12px;
            }
            td {
                font-size: 12px;
                &::before {
                    content: attr(data-label);
                    position: absolute;
                    left: 10px;
                    text-align: left;
                    font-weight: bold;
                }
            }
        }
    }
}


.theme_dir__rtl {
	ul.policy__item {
		li {
			&::before {
				right: 0px;
				left: auto;
			}
			padding-left: 0px;
			padding-right: 16px;
		}
	}
}
