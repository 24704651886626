$border-radius: 4px;
$font-size-large: 16px;

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  padding: 24px;
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
  .custom-form {
    .ant-form-item {
      margin-bottom: 16px;
      &-explain {
        margin-top: 8px; 
      }
    }
    .custom-input {
      height: 40px;
    }
   
    .ant-select-single {
      height: 40px !important;
    }
    .ant-select-selector {
      padding: 0 11px; 
    }
    .ant-btn {
      margin-top: 30px;
      padding: 12px, 24px;
      height: 44px;
      background-color: #1664fa;
      width: 123px;
      border-radius: 50px;
    }
  }
}
