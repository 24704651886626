.update__payment_wrap {
  .ant-modal-body {
    padding: 10px 10px !important;

    .upc__payment_update {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      text-align: center;

      .upc__payment_icon {
        margin-bottom: 15px;
      }

      .upc__payment_content {
        width: 100%;
        max-width: 430px;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 40px;

        h4 {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
          color: #000000;
          margin: 0px 0px 15px;
        }

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.6;
          color: #777777;
          margin: 0px 0px 15px;
        }

        .upc__update_btn {
          width: 100%;
          height: 100%;
          display: inline-block;
          vertical-align: top;

          button {
            display: inline-block;
            vertical-align: top;
            padding: 10px 30px;
            background-color: #4096ff;
            color: #ffffff;
            border-radius: 10px;
            font-size: 16px;
            line-height: 1.5;
            font-style: normal;
            font-weight: 500;
            text-transform: capitalize;
            outline: none;
            border: none;
            cursor: pointer;
          }
        }
      }

      .upc__cancel_info {
        padding: 30px 30px;
        border-radius: 10px;
        border-top: 1px solid #e8e8e8;
        background: rgba(36, 83, 123, 0.1);

        .upc__cancel_content {
          p {
            width: 100%;
            max-width: 290px;
            margin: 0px auto 25px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            color: #777777;
            line-height: 1.4;
          }

          .upc__cancel_btn {
            width: 100%;
            height: 100%;
            display: inline-block;
            vertical-align: top;

            button {
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              border: 1px solid #e8e8e8;
              background: #fafafa;
              padding: 10px 32px;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              color: #000000;
              line-height: 1.6;
              text-transform: capitalize;
              outline: none;
              border: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .ant-modal-content {
    padding: 0px 0px !important;
  }
}

@media screen and (max-width: 575px) {
  .update__payment_wrap {
    .ant-modal-body {
      .upc__payment_update {
        .upc__cancel_info {
          padding: 15px 15px;
        }
        .upc__payment_content {
          margin-bottom: 20px;
          h4 {
            font-size: 20px;
          }
        }
      }
    }
  }
}
