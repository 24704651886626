// Header Css
html {
  background-color: #f8f8f8;
  height: 100%;
}

.main_root * {
  box-sizing: border-box;
}

.text-left {
  text-align: left !important;
}

.main_container {
  width: 100%;
  max-width: 1130px;
  margin: 0px auto;
  padding: 0px 15px;
}

// Premium section 1
.white-bg-wrap {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 20px;
  margin-bottom: 30px;
  .email_number_wrapper {
    .email_number_wrapper_title {
      .mobile_number {
        display: inline-block;
        direction: ltr;
        margin: 0px 0px;
      }
    }
  }
}

// Premium section 2

.main_title {
  h2 {
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
    text-transform: capitalize;
    color: #000000;
    margin: 0px 0px 20px;
    a {
      color: #000000;
    }
    img {
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      cursor: pointer;
    }
  }
}

.left-content {
  h2 {
    margin-bottom: 0px;
    text-align: left;
  }
}

@media only screen and (max-width: 575px) {
  .main_title {
    h2 {
      font-size: 18px;
      img {
        width: 100%;
        max-width: 17px;
      }
    }
  }
}

//  Popup Css
body {
  &:has(.ant-modal-mask) {
    overflow: hidden !important;
    height: 100% !important;
    padding-right: 15px;

    div#root {
      height: initial !important;
      min-height: 100% !important;
      display: block !important;
    }
  }
}

// PDF SCSS

.phone-report-img {
  img {
    width: 104px;
    height: 104px;
  }
}
.phone-report-box {
  .phone-report-text {
    ul {
      li {
        img {
          width: 100%;
          max-width: 16px;
          height: 100%;
        }
      }
    }
  }
}
.location_table_info {
  table {
    tbody {
      tr {
        td {
          span {
            img {
              width: 100%;
              max-width: 16px;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
.main_title {
  h2 {
    img {
      width: 100%;
      max-width: 22px;
      height: 100%;
    }
  }
}
.reputation-phn-img {
  img {
    width: 100%;
    max-width: 217px;
    height: 100%;
  }
}
button.unlock-btn-info {
  .unlock-btn-icon {
    img {
      width: 100%;
      max-width: 40px;
      height: 100%;
    }
  }
}
.white-bg-wrap {
  .toggle_area {
    .premium_toggle {
      img {
        width: 21px;
        height: 4px;
      }
    }
  }
}
p.address-btn {
  img {
    width: 100%;
    max-width: 18px;
    height: 100%;
  }
}
.ds--phone-copy {
  p {
    img {
      width: 100%;
      max-width: 20px;
      height: 100%;
    }
  }
}
.ds--phone-image {
  img {
    width: 100%;
    max-width: 18px;
    height: 100%;
  }
}
.social-profiles {
  .social-profiles--boxes {
    .social-profiles-icon {
      img {
        width: 100%;
        max-width: 32px;
        height: 100%;
      }
    }
  }
}
.social-profiles-btn {
  p {
    a {
      img {
        width: 100%;
        max-width: 13px;
        height: 100%;
      }
    }
  }
}
.address-history-box {
  .history--box-cmt {
    img {
      width: 40px;
      height: 40px;
    }
  }
}
.unlock-information-sec {
  img {
    width: 100%;
    max-width: 50px;
    height: 100%;
  }
}
.ds--unlock-info-sec {
  .ds--alert-box {
    .alert--content-area {
      .alert--img {
        img {
          width: 100%;
          max-width: 50px;
          height: 100%;
        }
      }
    }
  }
}

.ds_full_reportMenu {
  .menu_item_box_wrap {
    .menu_item_btn {
      p {
        a.menu_button {
          img {
            vertical-align: text-bottom;
            width: 100%;
            max-width: 18px;
            height: 18px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.ds_full_reportMenu {
  .menu_item_box_wrap {
    .menu_item_btn {
      a.menu_button {
        img {
          vertical-align: text-bottom;
          width: 100%;
          max-width: 17px;
          height: 18px;
        }
      }
    }
  }
}

// New Doc. SCSS

.download_PDF_dropdown_menu_option {
  ul.ant-dropdown-menu {
    top: 20px;
  }
}
.high__lang_wrapper {
  .ds_full_reportMenu {
    .menu_item_box_wrap {
      .menu2_item_box_wrap {
        p {
          flex: inherit !important;
        }
      }
    }
  }
}
.ant-modal-wrap {
  .lp__report_popup {
    .wpb_popup_body {
      .popup_body_image {
        position: relative;
        p.dynemic__number_wrap {
          position: absolute;
          top: auto;
          bottom: 32%;
          right: auto;
          left: 50%;
          transform: translateX(-50%);
          margin: 0px;
          direction: ltr;
          font-size: 11px;
          line-height: 1.1;
          font-weight: 600;
          color: #000000;
        }
      }
    }
  }
}
@media only screen and (max-width: 460px) {
  .ant-modal-wrap {
    .lp__report_popup {
      .wpb_popup_body {
        .popup_body_image {
          p.dynemic__number_wrap {
            font-size: 7px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .wpb_report_wrapper {
    .wpb_text_content {
      .wpb_box_wrap {
        .vc_general_icon {
          max-width: 60px;
          flex: 0 0 60px;
        }
        .vc_general_tel {
          max-width: calc(100% - 60px);
          flex: 0 0 calc(100% - 60px);
        }
      }
    }
  }
}

.monitor__ds_btn {
  .button-container {
    .info-button {
      width: max-content;
      img {
        margin-right: 5px;
      }
    }
  }
}
.ant-modal-wrap {
  .multiples_popup__wrap_ {
    .multiple_popup__wrap {
      .wpb_report_popup {
        .mlt_popup__subscribe {
          .error {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .multiples_popup__wrap_ {
    padding-bottom: 50px !important;
  }
  .multiples_popup__wrap_close_button {
    padding-bottom: 50px !important;
  }
}

@media only screen and (max-width: 375px) {
  .theme_dir__rtl.notificationAddressinfo {
    left: 15px !important;
    .NotificationDropDownArea {
      width: 260px !important;
    }
  }
  .theme_dir__rtl {
    .NotificationDropDownArea {
      .NotificationDropDownBox {
        .unread_NotificatiobWrapper {
          text-align: left !important;
        }
      }
    }
  }
}

.location-info {
  .location-box {
    p {
      direction: ltr;
      margin: 0px 0px;
    }
  }
}

.wpb_box_wrap {
  .vc_general_tel {
    flex: 0 0 160px;
    max-width: 160px;
  }
}

// Locating Popup

.signup-info-wrap {
  .login-modal-section {
    .login-modal-title {
      background-color: #24537b;
    }
    .wrap-top-modal {
      .body-modal-ceate-account {
        color: #24537b !important;
      }
      .body-modal {
        .submit-btn-modal {
          .hl_cta_wrap {
            border: 1px solid #24537b;
            color: #24537b;
            &::before {
              border-bottom: 80px solid #24537b;
            }
            &::after {
              border-top: 80px solid #24537b;
            }
          }
        }
        .modal-login-form {
          input {
            &:hover {
              outline: #24537b;
              border: 1px solid #24537b;
            }
            &:focus {
              outline: #24537b;
              border: 1px solid #24537b;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .signup-info-wrap {
    .login-modal-section {
      .wrap-top-modal {
        .body-modal {
          .submit-btn-modal {
            .on-mobile {
              border: 1px solid #24537b;
              background-color: #24537b !important;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

// Payment Popup

.checkout_payment_modal {
  .login-modal-section {
    .login-modal-title {
      background-color: #24537b;
    }
    .wrap-top-modal {
      .body-modal {
        .payment-details {
          .df-details {
            .left-payment {
              p {
                color: #24537b;
              }
            }
            .right-payment {
              p {
                color: #24537b;
              }
            }
          }
        }
        .hl_cta_wrap {
          border: 1px solid #24537b;
          color: #24537b;
          &::before {
            border-bottom: 80px solid #24537b;
          }
          &::after {
            border-top: 80px solid #24537b;
          }
        }
      }
    }
  }
}

// Login Popup

.login-info-wrap.login-info-wrap {
  .login-modal-section {
    .login-modal-title {
      background-color: #24537b;
    }
  }
}
.login-info-wrap {
  .wrap-top-modal {
    .body-modal {
      .submit-btn-modal {
        .hl_cta_wrap {
          border: 1px solid #24537b;
          color: #24537b;
        }
      }
    }
  }
  .login-modal-section {
    .wrap-top-modal {
      .body-modal {
        .hl_cta_wrap {
          &::before {
            border-bottom: 80px solid #24537b;
          }
          &::after {
            border-top: 80px solid #24537b;
          }
        }
        .modal-login-form {
          input {
            &:hover {
              outline: #24537b;
              border: 1px solid #24537b;
              box-shadow: unset;
            }
            &:focus {
              outline: #24537b;
              border: 1px solid #24537b;
              box-shadow: unset;
            }
          }
        }
      }
    }
  }
}

// New SCSS Image

.mw-h li a {
  font-weight: 800;
}


// Image SCSS

span.BannerCallerContent {
  position: absolute;
  top: 27%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  right: auto;
  font-size: 21.531px;
  font-style: normal;
  font-weight: 700;
  color: #7e7e7e;
  width: 319px;
}

// Safari Scrolling SCSS

.NotificationDropDownArea{
  overflow-y: scroll;
}

.NotificationDropDownArea::-webkit-scrollbar{
  width: 8px; 
}

.NotificationDropDownArea::-webkit-scrollbar-thumb{
  background-color: rgba(0, 0, 0, 0.5   );
  border-radius: 10px; 
}


.NotificationDropDownArea::-webkit-scrollbar{
  opacity: 0;
  transition: opacity 0.3s ease;
}

.NotificationDropDownArea:hover::-webkit-scrollbar{
  opacity: 1;
}   