.monitor-white-bg-wrap {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 20px;
  margin-bottom: 30px;

  .ds--unlock__title {
    margin-bottom: 15px;
  }

  .monitor_monthly_wrap {
    border-radius: 10px;
    border: 1px dashed #24537b;
    background: rgba(217, 217, 217, 0.01);
    padding: 15px 15px;
    margin-bottom: 20px;
  }

  .monthly_info_wrap {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 15px;
    padding-bottom: 15px;

    p {
      display: inline-block;
      vertical-align: text-top;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #777777;
      margin: 0px 0px;
      img {
        width: 100%;
        max-width: 18px;
        margin-right: 7px;
        display: inline-block;
        vertical-align: text-top;
      }
      a {
        color: #777777;
      }
    }
  }

  .monthly_update_wrap {
    width: 100%;
    display: inline-block;
    vertical-align: top;

    h3 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #000000;
      margin: 0px 0px 20px;
      img {
        width: 100%;
        max-width: 18px;
        margin-left: 7px;
      }
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #777777;
      margin: 0px 0px;

      a {
        color: #777777;
      }

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }
}

.monitor-cmp-wrap {
  h2 {
    img {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      max-width: 14px;
    }
  }
}

@media screen and (max-width: 645px) {
  .monitor-white-bg-wrap {
    .monthly_info_wrap {
      p {
        font-size: 16px;
      }
    }
    .monthly_update_wrap {
      p {
        font-size: 16px;
        a {
          display: block;
        }
      }
      h3 {
        margin: 0px 0px 12px;
      }
    }
  }
}
