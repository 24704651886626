.bg-grad-section {
  padding-bottom: 150px;
  background-color: rgb(218, 215, 215);
  background-image: linear-gradient(#ffffff, #f7f6f9);

  .Locate-Any-Phone {
    background-image: url("../../assets/home/locate-any-phone-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;

    .wid-home-banner {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      padding: 150px 50px 0;
      align-items: center;

      @media only screen and (max-width: 768px) {
        height: 100%;
        min-height: 100vh;
      }

      .df-first-section {
        display: flex;
        max-width: 1200px;
        width: 100%;
        align-items: center;
        margin: 0 auto;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
          margin: auto auto;
          height: max-content;
        }

        .right-contant {
          width: 50%;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .banner-img {
            position: relative;

            img {
              width: 540px;
              height: 100%;
            }

            .location-banner-img-text {
              position: absolute;
              z-index: 1;
              top: 53%;
              left: 40%;
              font-size: 20px;
              font-weight: 700;
              color: #1664fa;
              height: max-content;
              width: max-content;
              margin: 0;
              rotate: 14deg;

              @media only screen and (max-width: 1028px) {
                top: 53%;
                left: 40%;
                font-size: 13px;
              }
            }

            .location-banner-img-desc {
              position: absolute;
              z-index: 1;
              top: 60%;
              left: 18%;
              font-size: 17px;
              font-weight: 500;
              color: black;
              width: 310px;
              height: max-content;
              margin: 0;
              rotate: 14deg;

              @media only screen and (max-width: 1028px) {
                top: 59%;
                left: 17%;
                font-size: 14px;
                width: 205px;
              }
            }

            .location-banner-img-view {
              position: absolute;
              z-index: 1;
              top: 70%;
              right: 24%;
              font-size: 14px;
              font-weight: 500;
              color: #1664fa;
              width: max-content;
              height: max-content;
              margin: 0;
              text-align: right;
              width: 300px;
              rotate: 15deg;

              @media only screen and (max-width: 1028px) {
                top: 67%;
                right: 23%;
                font-size: 12px;
              }
            }
          }
        }

        .left-contant {
          width: 50%;
          margin-top: -100px;

          .right-text {
            margin-bottom: 20px;
            color: #fff;

            p {
              margin: 0;
            }
          }

          .head-title {
            font-family: "GT Walsheim Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 56px;
            margin-top: 40px;
            line-height: 1.2;
          }

          .head-semi-title {
            font-family: "GT Walsheim Pro";
            font-style: normal;
            font-weight: 300;
            font-size: 21px;
            line-height: 30px;
          }

          .wrap-right-input-error {
            position: relative;

            p {
              margin: -16px -3px 10px 0px;
              color: white;
              position: absolute;
              background: #ff3855;
              width: max-content;
              padding: 6px 15px;
              top: -10px;
              border-radius: 8px;
              left: 50%;
              box-shadow: 0 4px 5px 2px #fd8a97;
              transform: translateX(-50%);
              line-height: 1.3;
              display: flex;
              align-items: center;
              gap: 10px;
              z-index: 1;
            }
          }

          .head-input {
            display: flex;
            position: relative;
            background: white;
            border-radius: 50px;

            .mobile-input {
              display: none;
            }

            .mobile-desktop {
              display: block;
            }

            .desktop-input,
            .mobile-input {
              border-radius: 50px 0 0 50px;
              background-color: white;
              width: 100px;

              .flag-dropdown {
                width: 100%;

                .selected-flag {
                  width: 100%;
                  padding: 0px 0px 0px 12px;
                  cursor: pointer;
                  background: transparent !important;
                }
              }
            }

            .react-tel-input {
              color: black;

              input {
                padding-left: 44px;
                background-color: white;
                border: none;
              }

              .flag-dropdown {
                padding: 0 5px;
                background: transparent;
                border: none;
              }
            }

            .desk {
              display: block;
              background: transparent;
              border: none;
              margin: auto;
              height: 80%;
              border-left: 1px solid gray;

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            .mobile {
              display: none;
              background: transparent;
              border: none;
              height: 80%;
              margin: auto;
              border-left: 1px solid gray;

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            .desktop-input-nd {
              border-radius: 0 50px 50px 0;
              @media only screen and (min-width: 1025px) and (max-width: 1220px) {
                &::placeholder {
                  width: calc(100% - 166px) !important;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              @media only screen and (min-width: 768px) and (max-width: 945px) {
                &::placeholder {
                  width: calc(100% - 166px) !important;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              @media only screen and (max-width: 380px) {
                &::placeholder {
                  width: calc(100% - 44px) !important;
                }
              }
            }

            input {
              height: 50px;
              width: 100%;
              border-radius: 50px 0 0 50px;
            }

            .input-suffix {
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: space-around;
              font-size: 20px;
              border-radius: 50px;
              position: absolute;
              top: 0px;
              right: 0px;
              padding: 0 17px;
              gap: 10px;
              height: 100%;
            }

            svg {
              cursor: pointer;
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .home-special-offer {
    margin-top: 70px;
    padding: 70px;

    @media only screen and (max-width: 991px) {
      padding: 70px 30px;
    }

    .specialOffer-container {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;

      .special-offerbox-partition {
        display: flex;
        gap: 30px;
        .right-content {
          width: 66%;
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          @media only screen and (max-width: 1199px) {
            width: 100%;
          }
          .pricing-title {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
          }
        }
        .bottom-btn {
          position: absolute;
          bottom: 30px;
          left: 30px;
          display: flex;
          width: calc(100% - 60px);
          height: 50px;

          a {
            width: 100%;
            text-decoration: none;
          }
        }

        .desc-special-span {
          color: inherit;
          margin-top: 30px;

          p {
            margin: 0;
          }
        }

        .desc-special {
          margin-top: 25px;
          color: inherit;

          h3 {
            font-size: 44px;
            margin: 0;
            font-weight: 700;
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1024px) {
              margin-bottom: 8px;
            }
          }
        }

        .box-heading {
          width: max-content;
          padding: 0 15px;
          border-radius: 20px;

          p {
            padding: 4px;
            margin: 0;
          }
        }

        .img-box-2,
        .img-box-3 {
          width: calc(50% - 75px);
          border-radius: 30px;
          height: auto;
          padding: 25px 30px;
          position: relative;
          @media only screen and (max-width: 1199px) {
            padding-bottom: 100px;
            width: calc(50% - 75px);
          }
        }

        .img-box-2 {
          background-color: #ecedef;
          color: #333333;

          .box-heading {
            color: white;
            background-color: #333333;
          }

          .bottom-btn {
            .hl_cta_wrap {
              position: relative;
              overflow: hidden;
              width: 100%;
              height: 100%;
              padding: 12px 20px;
              border: 1px solid currentColor;
              border-radius: 14px;
              transition: 0.5s ease-in-out;
              z-index: 1;
              color: #1664fa;
              font-weight: 700;
              font-size: 20px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover {
                color: #fff !important;
              }
            }
          }
        }

        .img-box-3 {
          background-color: #1664fa;
          color: #fff;

          .box-heading {
            color: #1664fa;
            background-color: #ffffff;
          }

          .bottom-btn {
            .hl_cta_wrap-white {
              position: relative;
              overflow: hidden;
              width: 100%;
              height: 100%;
              padding: 12px 20px;
              border: 1px solid currentColor;
              border-radius: 14px;
              border-color: #fff;
              transition: 0.5s ease-in-out;
              z-index: 1;
              background-color: #1664fa;
              color: #fff;
              font-weight: 700;
              font-size: 20px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .img-box {
          position: relative;
          background-color: #171c1c;
          height: auto;
          padding: 25px 30px;
          padding-bottom: 0;
          border-radius: 30px;
          width: 33%;
          overflow: hidden;
          color: white;
          .main-title {
            width: unset;
          }

          p {
            margin: 0;
            padding: 0;
          }

          .box-heading {
            font-weight: 700;
            padding: 0;
            font-size: 55px;
            margin-bottom: 20px;
            @media only screen and (max-width: 1500px) {
              font-size: 45px;
            }
          }

          .desc-special {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 20px;
          }

          .desc-special-span {
            font-weight: 400;
            font-size: 17px;
            width: 50%;
          }

          .backGroundImg2 {
            margin-top: -25px;
            width: 100%;
            display: flex;
            justify-content: space-around;
          }

          @media screen and (max-width: 1199px) {
            .backGroundImg2 {
              flex-direction: row-reverse;
              justify-content: normal !important;
              margin-top: -140px;
              position: relative;
              z-index: 0;

              img {
                max-width: 100%;
              }
            }
          }

          @media screen and (max-width: 1024px) {
            .backGroundImg2 {
              margin-top: -100px;
            }
          }

          @media screen and (max-width: 1199px) {
            .backGroundImg2 {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }

  .how-work-section {
    padding: 0 50px;

    .how-work-container {
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;

      .how-work-title {
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 1.2;
        margin: 70px 0 50px;

        p {
          margin: 0;
        }
      }

      .how-work-body {
        .how-work-desc-sections {
          display: flex;
          gap: 30px;
          justify-content: space-around;
          @media only screen and (max-width: 1299px) {
            flex-wrap: wrap;
          }
          .box {
            width: calc(33.333% - 10px);
            position: relative;
            overflow: hidden;
            border-radius: 60px 0 60px 60px;
            @media only screen and (max-width: 1299px) {
              width: calc(48% - 10px);
            }
            @media only screen and (max-width: 875px) {
              width: 100%;
            }
            .bgboximg {
              position: absolute;
              width: 100%;
              height: 100%;
              min-height: auto;
              object-fit: cover;
              object-position: top right;
            }

            p {
              margin: 0;
            }

            .how-work-bg {
              position: absolute;
              width: 100%;
              z-index: 0;
            }

            .how-work-contant {
              width: 100%;
              display: flex;
              justify-content: space-between;
              position: relative;

              .left-contant-count {
                position: absolute;
                right: 10px;
                width: 3.5vw;
                height: 3.5vw;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #1664fa;
                border-radius: 50%;
                top: 10px;
                font-weight: 900;
                font-size: 36px;
                color: white;
              }

              .how-work-svg {
                margin-bottom: 15px;
              }

              .left-contant {
                width: calc(100% - 78px);
                padding-left: 40px;
                padding-top: 40px;
                padding-bottom: 40px;

                .how-work-box-title {
                  margin-bottom: 15px;
                  font-style: normal;
                  font-weight: 800;
                  font-size: 24px;
                  line-height: 29px;
                }

                .how-work-desc {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 17px;
                  line-height: 26px;
                }
              }
            }

            .right-contant {
              width: 25%;
              position: relative;

              .cir-cont {
                background: #1664fa;
                color: white;
                font-style: normal;
                width: 90%;
                font-weight: 900;
                font-size: 38px;
                text-align: center;
                border-radius: 100%;
                position: absolute;
                right: 0;

                h1 {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.choose-tracelo-section {
  background-color: white;
  padding: 75px 50px;

  .choose-tracelo-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .wrap-choose-tracelo {
      .choose-tracelo-title {
        text-align: center;

        p {
          margin: 0;
          font-style: normal;
          font-weight: 700;
          font-size: 55px;
          line-height: 66px;
        }
      }

      .choose-tracelo-desc {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 50px;

        p {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
        }
      }

      .choose-tracelo-under-section {
        margin-top: 50px;

        .df-wrap-service {
          display: flex;
          gap: 30px;

          .choose-tracelo-box {
            width: calc(25% - 10px);

            .choose-tracelo-part {
              display: flex;
              flex-direction: column;

              .choose-tracelo-box-title {
                margin-top: 20px;

                p {
                  margin: 0;
                  font-style: normal;
                  font-weight: 800;
                  font-size: 20px;
                }
              }

              .choose-tracelo-box-desc {
                margin-top: 20px;

                p {
                  margin: 0;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 17px;
                }
              }
            }
          }
        }
      }

      .locate-btn {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        a {
          text-decoration: none;
        }

        .hl_cta_wrap {
          position: relative;
          overflow: hidden;
          width: max-content;
          height: 100%;
          padding: 12px 20px;
          border: 1px solid currentColor;
          border-radius: 14px;
          transition: 0.5s ease-in-out;
          z-index: 1;
          background-color: transparent;
          color: #1664fa;
          font-weight: 700;
          font-size: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: #fff !important;
          }
        }
      }
    }
  }
}

.bg-grd-section {
  padding: 100px 30px;
  background-color: rgb(218, 215, 215);
  background-image: linear-gradient(#f7f6f9, #ffffff);

  .frequently-asked-question {
    .question-title {
      text-align: center;
      margin-bottom: 70px;

      p {
        margin: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 66px;
        margin-bottom: 20px;
      }

      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .question-collapse {
      max-width: 1000px;
      margin: 0 auto;
      width: 100%;

      .collapse-box {
        background: white;
        padding: 20px 25px;
        border: 0.5px solid;
        border-color: #e3e3e3;
        border-radius: 12px;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        cursor: pointer;

        .collapse-box-df-title {
          display: flex;
          justify-content: space-between;

          .collapse-box-title {
            p {
              margin: 0;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
            }
          }
        }

        .open-tab-desc {
          width: 80%;
          margin-top: 20px;

          p {
            margin: 0;
            font-weight: 400;
            font-size: 17px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1199px) {
  .img-box-3,
  .img-box-2 {
    padding-bottom: 100px !important;
    width: calc(50% - 75px) !important;

    .desc-special {
      gap: 12px !important;

      h3 {
        font-size: 37px !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        padding: 149px 20px 0;
      }

      .wid-home-banner .df-first-section {
        .left-contant {
          padding-bottom: 50px;
          width: 100%;
          margin-top: 0;
        }

        .right-contant {
          img {
            width: 348px !important;
          }
        }
      }
    }
  }

  .desc-special {
    gap: 0 !important;
    align-items: flex-start !important;
    flex-direction: column;

    p {
      margin: 0;
    }
  }

  .df-wrap-service {
    flex-wrap: wrap;

    .choose-tracelo-box {
      width: calc(50% - 15px) !important;
    }
  }

  .bg-grad-section {
    padding-bottom: 70px !important;
  }
}

@media only screen and (max-width: 991px) {
  .special-offerbox-partition {
    flex-wrap: wrap;
    justify-content: space-around;

    .img-box-3,
    .img-box-2 {
      .desc-special-span {
        margin-bottom: 75px;
      }
    }
  }

  .collapse-box-df-title {
    gap: 50px;

    .collapse-box-title {
      p {
        font-size: 18px !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .desk {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        padding: 20% 10% 15%;
      }
    }
  }

  .bg-grad-section {
    .Locate-Any-Phone .wid-home-banner .df-first-section {
      .left-contant {
        width: 100%;

        .head-title {
          width: 220px;
          font-size: 38px;
          line-height: 1.2;
        }

        .heading-title {
          width: 300px;
          font-size: 38px;
          line-height: 1.2;
        }

        .head-semi-title {
          font-size: 18px;
          line-height: 25px;
        }

        .head-input .react-tel-input input {
          width: 90px;
        }
      }

      .right-contant {
        display: none !important;
      }
    }

    .home-special-offer .specialOffer-container {
      .special-offerbox-partition {
        .img-box,
        .img-box-2,
        .img-box-3 {
          width: 100% !important;
        }

        .img-box .box-heading {
          font-size: 25px;
        }
      }
    }

    .question-title {
      p {
        font-size: 45px !important;
      }
    }

    .how-work-section {
      padding: 0 20px;

      .how-work-container {
        .how-work-title {
          font-size: 35px;
        }

        .how-work-body .how-work-desc-sections {
          flex-wrap: wrap;
          justify-content: space-around;
          max-width: 550px;
          margin: 0px auto;

          .box {
            width: 100% !important;
            max-width: 100%;

            .how-work-contant .left-contant {
              width: 100% !important;
              padding: 30px 30px 30px !important;
              min-height: 192px;
            }

            img {
              width: 50px;
              height: 50px;
            }

            .how-work-box-title {
              font-size: 22px !important;
              line-height: 22px !important;
            }

            .how-work-desc {
              font-size: 17px !important;
              line-height: 22px !important;
            }
          }
        }
      }
    }

    .input-suffix {
      .span-text {
        display: none;
      }
    }
  }

  .bg-grad-section
    .Locate-Any-Phone
    .wid-home-banner
    .df-first-section
    .left-contant
    .head-input
    .input-suffix {
    -webkit-appearance: none;
    top: 0;
    bottom: 0;
    height: calc(100% - 6px);
    margin: 3px 0 3px;
    right: 3px;
    aspect-ratio: 1;
    background: #1664fa;
    color: #fff;
    padding: 8px;
    border-radius: 50px;

    svg {
      flex: 0 0 23px;
      height: 23px;
    }
  }

  .choose-tracelo-section {
    padding: 70px 20px;

    .choose-tracelo-title {
      padding: 0;
      font-size: 42px !important;
      line-height: 1.2px !important;
    }

    .choose-tracelo-container .choose-tracelo-tag {
      max-width: 80px;
      grid-row: 1/-1;

      img {
        max-width: 100%;
        height: auto;
        vertical-align: top;
        width: auto;
      }
    }

    .df-wrap-service {
      flex-wrap: wrap;
      gap: 15px !important;

      .choose-tracelo-box {
        width: 100% !important;
        border-bottom: 0.5px solid;
        padding-bottom: 15px;
        border-color: #d7d7d7;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    .choose-tracelo-container
      .wrap-choose-tracelo
      .choose-tracelo-under-section
      .df-wrap-service
      .choose-tracelo-box
      .choose-tracelo-part {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;
      column-gap: 12px;

      .choose-tracelo-box-title {
        margin-top: 5px;
      }

      .choose-tracelo-box-desc {
        margin-top: 8px;
      }
    }
  }

  .bg-grd-section {
    padding: 70px 20px;

    .frequently-asked-question {
      font-size: 40px !important;

      .question-title {
        margin-bottom: 30px;

        p {
          font-size: 35px;
          line-height: 1.2;
          margin: 0 0 15px;
        }

        span {
          font-size: 18px;
          line-height: 1.2;
          display: block;
        }
      }

      .question-collapse .collapse-box {
        .collapse-box-icon {
          flex: 0 0 18px;

          img {
            max-width: 100%;
            height: auto;
            display: block;
          }
        }

        .collapse-box-df-title {
          align-items: center;
          gap: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .bg-grad-section .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .ant-space {
            width: 100% !important;
          }

          .head-input .react-tel-input {
            width: 80px;
            flex: 0 0 80px;

            input {
              padding-left: 40px;
              width: 80px;
            }
          }
        }

        .right-contant {
          img {
            width: 390px !important;
          }
        }
      }
    }
  }

  .how-work-desc-sections {
    .group-box-1,
    .group-box-2,
    .group-box-3 {
      width: 100% !important;
    }
  }
  .bg-grad-section {
    .home-special-offer {
      .specialOffer-container {
        .special-offerbox-partition {
          .img-box {
            padding: 25px 15px 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .bg-grad-section .home-special-offer {
    padding: 20px !important;

    .specialOffer-container .special-offerbox-partition {
      .img-box {
        .desc-special {
          font-size: 16px;
        }

        .desc-special-span {
          margin-top: 20px !important;
          margin-bottom: 30px;
        }
      }

      .img-box-2,
      .img-box-3 {
        .desc-special {
          margin-top: 17px !important;

          h3 {
            font-size: 35px !important;
          }

          p {
            margin: 0;
          }
        }

        .desc-special-span {
          margin-top: 20px !important;
          margin-bottom: 30px;
        }
      }
    }
  }

  .collapse-box {
    .open-tab-desc {
      width: 100% !important;
    }
  }

  .mobile-input {
    display: block !important;
  }

  .desktop-input {
    display: none !important;
  }

  .img-box {
    .backGroundImg2 {
      img {
        height: 100%;
        margin: 0 auto;
      }
    }
  }

  .bg-grad-section {
    .Locate-Any-Phone .wid-home-banner .df-first-section {
      .left-contant {
        width: 100%;

        .heading-title {
          width: 300px;
          font-size: 33px;
          line-height: 1.2;
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        padding: 20% 4% 15%;
      }
    }
  }
}

.billing-form {
  .ant-select {
    span.ant-select-selection-item {
      color: #868686;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .bg-grad-section {
    .how-work-section {
      .how-work-container {
        .how-work-title {
          font-size: 26px;
        }
      }
    }
  }
}

// Common Footer Css

.footer {
  .footer-container {
    .footer-section {
      .footer-inner-container {
        .df-footer {
          .footer-right-contan {
            .df-right-footer-menu {
              .legal-info {
                margin-right: 10px;
              }
              .legal-menus {
                ul {
                  li {
                    &:not(:last-child) {
                      margin-bottom: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .footer {
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .footer-bottom-contant {
            .footer-bottom-flex {
              flex-wrap: wrap;
              .tracelo-footer {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
              }
              .policies-footer {
                width: 100%;
                text-align: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .footer {
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .footer-bottom-contant {
            .footer-bottom-flex {
              .policies-footer {
                flex-wrap: wrap;
                > div {
                  width: 100%;
                  margin-bottom: 8px;
                }
                .tems-policy {
                  margin-bottom: 0px;
                }
                .cookies-policy {
                  p {
                    &::after {
                      all: unset !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-modal {
  .ant-modal-close {
    right: 5%;
  }
}

@media only screen and (max-width: 1099px) {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .right-contant {
            .banner-img {
              width: 100%;
              img {
                width: 100%;
                max-width: 540px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .header-main-section {
    .header {
      .header-df {
        .header-menu-nd-login {
          .header-menu {
            li {
              a {
                font-size: 16px;
              }
            }
          }
          .header-login-btn {
            .hl_cta_wrap {
              padding: 10px 10px;
            }
            margin-left: 10px;
          }
          .header-lang-drop {
            margin-left: 10px;
          }
          .responsive-header-btn {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 350px) {
  .collapse-box-df-title {
    .collapse-box-title {
      p {
        max-width: 170px;
      }
    }
  }
}

.bg-grad-section {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .head-input {
            .react-tel-input {
              .country-list {
                .search {
                  input {
                    &.search-box {
                      padding: 5px 10px !important;
                      border-radius: 7px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1028px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .right-contant {
              .banner-img {
                .location-banner-img-view {
                  top: 68%;
                  width: 100%;
                  max-width: 235px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .right-contant {
            .banner-img {
              .location-banner-img-view {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .right-contant {
              .banner-img {
                .location-banner-img-view {
                  max-width: 225px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//reverlsy homepage

* {
  box-sizing: border-box;
}

.bg-white {
  background-color: #ffffff;
}

.NewContainer {
  max-width: 1290px;
}

.padding_wrap {
  padding: 100px 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .padding_wrap {
    padding: 70px 0px;
  }
}
@media screen and (max-width: 575px) {
  .padding_wrap {
    padding: 50px 0px;
  }
}

h2.SectionMainTitle {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 1px;
  text-align: center;
  margin: 0px 0px;
  color: #000000;
}

.mb-60 {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  h2.SectionMainTitle {
    font-size: 28px;
  }
  .mb-60 {
    margin-bottom: 40px;
  }
}

// Home Banner Image

.Sec_reversly__wrapper {
  h2.SectionMainTitle {
    text-align: center;
  }
}

.HomeBanner_Wrapper {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  background-image: url(../../assets/newhome/backgroundBannerImages.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.HomeBanner__Info {
  .HomeBanner__Content {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    h1 {
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.1;
      letter-spacing: 1px;
      text-transform: capitalize;
      width: 100%;
      max-width: 493px;
      margin: 0px 0px 16px;
    }
    p {
      width: 100%;
      max-width: 560px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      font-family: "Inter", serif;
      margin: 0px 0px 30px;
    }
    .BannerListItem__Wrapper {
      ul {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        padding: 0px 0px;
        margin: 0px 0px;
        list-style: none;
        li {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          padding-left: 24px;
          margin-bottom: 7px;
          color: #152536;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0px;
            bottom: auto;
            left: 0px;
            right: auto;
            width: 18px;
            height: 18px;
            background-image: url(../../assets/newhome/homeBannerList.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
    ul {
      li {
        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }
    .BannerPrivacyContent {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 23px;
      ul {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0px 0px;
        margin: 0px 0px;
        list-style: none;
        li {
          display: block;
          line-height: 0;
          position: relative;
          padding-right: 25px;
          margin-right: 25px;
          &:last-of-type {
            padding-right: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
            &:before {
              all: unset;
            }
          }
          &:before {
            content: "";
            position: absolute;
            width: 1px;
            height: 21px;
            background-color: #151515;
            top: 50%;
            transform: translateY(-50%);
            left: auto;
            right: 0px;
            bottom: auto;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .HomeBanner__Info {
    .HomeBanner__Content {
      h1 {
        font-size: 40px;
      }
    }
  }
}
@media screen and (max-width: 675px) {
  .HomeBanner__Info {
    .HomeBanner__Content {
      .BannerPrivacyContent {
        ul {
          flex-wrap: wrap;
          li {
            &:before {
              all: unset;
            }
            width: 100%;
            margin-bottom: 15px;
            padding-right: 0px;
            margin-right: 0px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .HomeBanner__Info {
    .HomeBanner__Content {
      p {
        margin: 0px 0px 20px;
      }
      .BannerPrivacyContent {
        margin-bottom: 15px;
      }
    }
  }
}

// Reversly Phone Report

.reversly__box_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0px -12.5px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  .reversly__box_info {
    max-width: 25%;
    flex: 0 0 25%;
    padding: 0px 12.5px;
    &:last-of-type {
      padding-right: 0px;
    }
    .wpb_box_wrapper {
      &:last-of-type {
        margin-bottom: 0px;
      }
      margin-bottom: 36px;
    }
  }
}
.reversly__box_info {
  .box__bg_wrapper {
    padding: 24px 18px 24px 24px;
    border-radius: 12px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 16px 16px 0px rgba(51, 146, 255, 0.2);
    .box__reversly_content {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      h3 {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3;
        color: #0e0f10;
        margin: 0px 0px 10px;
      }
      ul {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin: 0px 0px 20px;
        padding: 0px 0px;
        list-style: none;
        li {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          color: #0e0f10;
          line-height: 1.4;
          position: relative;
          padding-left: 18px;
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            bottom: auto;
            left: 0px;
            right: auto;
            transform: translateY(-50%);
            width: 13px;
            height: 13px;
            background-image: url(../../assets/newhome/reverslyCheckIcon.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }
  .box__reversly_image {
    img {
      width: 100%;
    }
    display: flex;
    flex-wrap: wrap;
  }
  .wpb__reversly_image {
    margin: 6px -6px;
    img {
      max-width: 50%;
      flex: 50%;
      padding: 6px 6px;
    }
  }
}
.box__reversly_icon {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 16px;
}
.d__desktop_none {
  display: none;
}
@media screen and (max-width: 1024px) {
  .reversly__box_info {
    .box__bg_wrapper {
      padding: 15px 15px 15px 15px;
    }
  }
}
@media screen and (max-width: 767px) {
  .d__desktop_block {
    display: none !important;
  }
  .d__desktop_none {
    display: block !important;
  }
  .Sec_reversly__wrapper {
    .phone_box_wrapper {
      margin: 0px 0px;
    }
  }
  .reversly__box_wrapper {
    .reversly__box_info {
      .wpb_box_wrapper {
        padding: 0px 5.5px;
        margin-bottom: 15px;
      }
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 0px 0px;
      padding: 0px 0px;
      .reversly__box1 {
        width: 100%;
      }
      .reversly__box2 {
        max-width: 50%;
        flex: 0 0 50%;
      }
      .reversly__box3 {
        max-width: 50%;
        flex: 0 0 50%;
      }
      .reversly__box4 {
        max-width: 50%;
        flex: 0 0 50%;
      }
      .reversly__box5 {
        max-width: 50%;
        flex: 0 0 50%;
      }
      .reversly__box7 {
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 0px;
      }
      .reversly__box8 {
        max-width: 50%;
        flex: 0 0 50%;
      }
      .reversly__box6 {
        width: 100%;
      }
    }
  }
  .phone_box_wrapper {
    .reversly__box1 {
      .box__bg_wrapper {
        display: flex;
        justify-content: space-between;
      }
      .box__reversly_image {
        img {
          height: 100%;
        }
      }
    }
    .reversly__box_info {
      .box__bg_wrapper {
        .box__reversly_content {
          ul {
            margin: 0px 0px 0px;
          }
          h3 {
            font-weight: 500;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 350px) {
  .reversly__box_wrapper {
    .reversly__box_info {
      .reversly__box7 {
        margin-bottom: 15px;
      }
      .wpb_box_wrapper {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

// Reversly Phone Report Help You

.HelpSectionBoxWrpper {
  display: flex;
  flex-wrap: wrap;
  margin: -12.5px -12.5px;
  .wplHelpSectionBox {
    max-width: 33.33%;
    flex: 0 0 33.33%;
    padding: 12.5px 12.5px;
  }
  .wpb_inner_box_wrap {
    height: 100%;
    padding: 24px 24px 48px 24px;
    border-radius: 12px;
    background: rgba(240, 103, 159, 0.05);
    .HelpSectionDescription {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #0e0f10;
        line-height: 1.5;
        margin: 0px 0px;
      }
    }
    .HelpSectionIcon {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      h3.HelpSectionTitle {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        color: #222222;
        text-transform: capitalize;
        line-height: 1.2;
        margin: 0px 0px;
      }
      img {
        width: 100%;
        max-width: 60px;
        height: 100%;
        margin-right: 12px;
      }
    }
  }
  .HelpSectionBox2 {
    background: rgba(55, 132, 49, 0.05);
  }
  .HelpSectionBox3 {
    background: rgba(231, 99, 91, 0.05);
  }
  .HelpSectionBox4 {
    background: rgba(229, 134, 64, 0.05);
  }
  .HelpSectionBox5 {
    background: rgba(132, 79, 248, 0.05);
  }
  .HelpSectionBox6 {
    background: rgba(68, 140, 227, 0.05);
  }
}
@media screen and (max-width: 991px) {
  .HelpSectionBoxWrpper {
    .wplHelpSectionBox {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }
}
@media screen and (max-width: 675px) {
  .HelpSectionBoxWrpper {
    .wplHelpSectionBox {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}
@media screen and (max-width: 575px) {
  .HelpSectionBoxWrpper {
    .wpb_inner_box_wrap {
      .HelpSectionIcon {
        img {
          max-width: 45px;
        }
      }
      padding: 15px 15px;
    }
  }
}

// Accordion Section Css

.accordion__wrapper {
  .accordion__item {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-top: 20px;
    .accordion-body-open {
      background: radial-gradient(
          453.98% 66.33% at 84.18% -42.86%,
          rgba(76, 237, 208, 0.08) 0%,
          rgba(76, 237, 208, 0) 100%
        ),
        linear-gradient(
          180deg,
          rgba(237, 245, 255, 0.4) 0%,
          rgba(182, 214, 254, 0.4) 100%
        ),
        #fff;
    }
    .accordion-body {
      padding: 10px 24px;
      border-radius: 12px;
      border: 3px solid #ffffff;
      margin-bottom: 16px;
      background-color: #ffffff;
      box-shadow: 0px 6px 16px 0px rgba(51, 146, 255, 0.15);
      .accordion__title {
        width: 100%;
        display: flex;
        h3 {
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          color: #0e0f10;
          line-height: 1.3;
          margin: 0px 0px;
          cursor: pointer;
          margin-top: 10px;
        }
      }
      .accordion__content {
        width: 100%;
        display: block;
        vertical-align: top;
        padding-left: 56px;
        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          margin: 0px 0px;
        }
      }
      .accordion__icon {
        margin-right: 16px;
      }
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .accordion__wrapper {
    .accordion__item {
      .accordion-body {
        padding: 15px 15px;
      }
    }
  }
}

// Reversly Report Section Css

.ReverslyReport__section {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  background-image: url("../../assets/newhome/reverslyReportImg.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .wpb__ReverslyReport {
    h2.SectionMainTitle {
      text-align: left;
    }
    display: flex;
    margin: 0px -12.5px;
    .accordion__wrapper {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 0px 12.5px;
    }
    .ReverslyReport__img {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 0px 12.5px;
    }
  }
}
.ReverslyReport__section.pb-0 {
  padding-bottom: 0px;
}
@media screen and (max-width: 991px) {
  .ReverslyReport__section {
    .wpb__ReverslyReport {
      flex-wrap: wrap;
      .ReverslyReport__img {
        img {
          max-width: 556px;
        }
        max-width: 100%;
        flex: 0 0 100%;
        text-align: center;
      }
      .accordion__wrapper {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 30px;
      }
    }
  }
}

.ReverslyReport__section {
  .wpb__ReverslyReport {
    .ReverslyReport__img {
      img {
        width: 100%;
        max-width: 556px;
      }
    }
  }
}

// FAQS Section SCSS

.section__faqs_wrapper {
  background-color: #ffffff;
}

.faqs_accordion-body {
  .faqs__accordion_info {
    border-radius: 8px;
    padding: 15px 15px;
    border: 1px solid rgba(169, 212, 255, 0.4);
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0px 0px 12px 0px #e6e7eb;

    .faqs_accordion__title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        color: #333333;
        margin: 0px 0px;
      }
    }

    .faqs_accordion__content {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      border-top: 1px solid #e6e7eb;
      margin-top: 15px;
      padding-top: 20px;

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.7;
        color: #4f4f4f;
        margin: 0px 0px;
      }
    }

    .faqs_accordion_arrow {
      .faqs_accordion_down {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        line-height: 0px;
      }

      margin-left: 20px;
    }
  }
}

.faqs_accordion {
  .faqs_accordion_area {
    .faqs_accordion-body {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    width: 100%;
    display: inline-block;
    vertical-align: top;
  }

  width: 100%;
  display: inline-block;
  vertical-align: top;
}

// Call To Action SCSS

.CallToAction__wrapper {
  padding-bottom: 200px;
  padding-top: 100px;
  .CallToAction__Content {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    padding: 50px 70px;
    border-radius: 12px;
    background-image: url("../../assets/newhome/callToActionBackground.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .section__title {
      width: 100%;
      max-width: 580px;
      h2.SectionMainTitle {
        text-align: left;
        margin-bottom: 10px;
      }
      p.TitleDescription {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #0e0f10;
        line-height: 1.4;
        margin: 0px 0px;
      }
    }
  }
}
.cta__wpb_content_element {
  .cta__content_element {
    border-radius: 4px;
    background: #ffffff;
    padding: 30px 30px;
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.16);
    .wpb__details_info {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      .wpb__presonal_text {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        h4.wpb__name_details {
          font-size: 15.5px;
          font-style: normal;
          font-weight: 700;
          line-height: 1.2;
          color: #151515;
          letter-spacing: 0.118px;
          font-family: "Inter", serif;
          margin: 0px 0px;
          img {
            width: 100%;
            max-width: 14px;
            margin-left: 3px;
          }
        }
        .wpb__address__info {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          ul {
            width: 100%;
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0px 0px;
            padding: 0px 0px;
            li {
              display: flex;
              align-items: center;
              vertical-align: top;
              font-size: 9.531px;
              font-style: normal;
              font-weight: 400;
              letter-spacing: 0.107px;
              &:first-child {
                margin-right: 8px;
              }
              img {
                width: 15px;
              }
            }
          }
        }
      }
      .wpb__user_image {
        margin-right: 10px;
        img {
          width: 100%;
          max-width: 50px;
          object-fit: cover;
        }
      }
    }
    .wpb__presonal_info {
      width: 100%;
      display: inline-block;
      vertical-align: text-bottom;
      .wpb__inner_info {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;
        .vc_custom_width {
          max-width: 50%;
          flex: 0 0 50%;
          display: flex;
          align-items: center;
        }
        &:last-of-type {
          margin-bottom: 0px;
        }
        .wpb__inner_status {
          width: 100%;
          max-width: max-content;
          font-size: 13.016px;
          font-weight: 500;
          border-radius: 4px;
          background-color: #dff8ea;
          justify-content: center;
          padding: 5px 5px;
          color: #3c7c65;
          font-family: "Inter", serif;
        }
        .wpb__inner_activity {
          img {
            margin-right: 6px;
          }
        }
      }
    }
    .wpb__inner_list {
      font-size: 13.672px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.105px;
    }
  }
}
.CallToAction__Content {
  .cta__wpb_content_element {
    width: 100%;
    max-width: 415px;
    display: inline-block;
    vertical-align: top;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: auto;
    right: 4%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 1199px) {
  .CallToAction__wrapper {
    .CallToAction__Content {
      .section__title {
        max-width: 480px;
      }
    }
  }
}
@media screen and (max-width: 1099px) {
  .CallToAction__wrapper {
    .CallToAction__Content {
      padding: 20px 20px;
    }
  }

  .CallToAction__Content {
    .cta__wpb_content_element {
      right: 0px;
    }
  }
}
@media screen and (max-width: 991px) {
  .CallToAction__wrapper {
    padding-bottom: 100px;
    padding-top: 0px;
  }
  .CallToAction__Content {
    .cta__wpb_content_element {
      display: none;
    }
  }
  .CallToAction__wrapper {
    .CallToAction__Content {
      .section__title {
        max-width: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .CallToAction__wrapper {
    padding-bottom: 70px;
    padding-top: 0px;
  }
}
@media screen and (max-width: 575px) {
  .CallToAction__wrapper {
    padding-bottom: 50px;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .theme_dir__rtl {
    .CallToAction__Content {
      .reverslyHomeBannerInput {
        .wpb_content_input {
          width: calc(100% - 20px);
        }
      }
    }
    .reverslyHomeBannerInput {
      .wpb_content_input {
        .reverslyInputMobile {
          margin-right: 0px;
          margin-left: 10px;
        }
      }
    }
  }
}
@media only screen and (max-width: 625px) {
  .theme_dir__rtl {
    .CallToAction__Content {
      .reverslyHomeBannerInput {
        .wpb_content_input {
          width: 100%;
        }
      }
    }
    .reverslyHomeBannerInput {
      .wpb_content_input {
        .reverslyInputMobile {
          margin-right: 0px;
          margin-left: 10px;
        }
      }
    }
  }
}

// Banner Image Html

.BannerCustomBoxWrap {
  .WplBannerImageBox1 {
    border-radius: 3.588px;
    background: #ffffff;
    box-shadow: 1.794px 1.794px 6.28px 2.691px rgba(0, 0, 0, 0.1);
    padding: 14px 14px;
    width: 100%;
    max-width: 140px;
  }

  .WplBannerImageBox2 {
    border-radius: 3.588px;
    background: #ffffff;
    box-shadow: 1.794px 1.794px 6.28px 2.691px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 190px;

    .BannerImageMapInfo {
      .BannerMapImage {
        img {
          width: 100%;
          border-radius: 0px 0px 3.588px 3.588px;
        }
      }
    }
  }

  .WplBannerImageBox4 {
    border-radius: 3.588px;
    background: #ffffff;
    box-shadow: 1.794px 1.794px 6.28px 2.691px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 220px;

    .BannerConnectedAccountsBox {
      padding: 10px 10px;

      h4 {
        font-size: 17.242px;
        font-style: normal;
        font-weight: 700;
        color: #151515;
        line-height: 1.5;
        margin: 0px 0px 10px;
      }

      .BannerSocialInfo {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 9px;

        .BannerSocialActive {
          margin-left: auto;
          font-size: 11.775px;
          font-style: normal;
          font-weight: 400;
          border-radius: 3.588px;
          background-color: #dff8ea;
          padding: 3px 7px 3px 7px;
          line-height: normal;
          color: #3c7c65;
          min-width: 90px;
        }

        h5 {
          font-size: 11.971px;
          font-style: normal;
          font-weight: 700;
          color: #151515;
          line-height: 1.4;
          margin: 0px 0px;
        }

        &:last-of-type {
          margin-bottom: 0px;
        }

        img {
          width: 100%;
          max-width: 24px;
          height: 100%;
          margin-right: 5px;
        }

        .BannerSocialNonActive {
          background-color: #ffe0dd;
          color: #e45f52;
        }
      }
    }
  }
}

.WplBannerImageBox1 {
  .BannerPhoneScoreBox {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;

    .BannerPhoneScoreContent {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 10px;

      h2 {
        font-size: 12.363px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.3;
        color: #151515;
        letter-spacing: 0.094px;
        margin: 0px 0px 3px;
      }

      span {
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        color: #000000;
        line-height: 0.7;
        letter-spacing: 0.153px;
      }
    }

    .BannerPhoneScoreImage {
      margin-bottom: 14px;
    }

    .BannerPhoneScoreButton {
      button {
        font-size: 10.093px;
        font-style: normal;
        font-weight: 300;
        letter-spacing: 0.101px;
        line-height: 23.325px;
        border-radius: 14.354px;
        border: 0.897px solid rgba(87, 191, 135, 0.7);
        padding: 0px 20.033px 0px 20.033px;
        background-color: #ffffff;
        font-family: "Inter", serif;
      }
    }
  }
}

.WplBannerImageBox2 {
  .BannerNumberLocate {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;

    a {
      font-size: 12.265px;
      font-style: normal;
      font-weight: 800;
      color: #151515;
      line-height: 1.3;
      letter-spacing: 0.094px;
    }
  }

  .BannerLocationList {
    padding: 10px 10px 17px 10px;

    ul {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      list-style: none;
      padding: 0px 0px;
      margin: 0px 0px;

      li {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 8px;

        .BannerLocationArea {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          pre {
            font-size: 10.093px;
            font-style: normal;
            font-weight: 300;
            color: #000000;
            letter-spacing: 0.116px;
            line-height: 1.1;
            margin: 0px 0px;
            text-align: left;
            word-wrap: break-word;
            white-space: break-spaces;
          }

          span.BannerLocationProvider {
            font-size: 11px;
            font-style: normal;
            font-weight: 300;
            color: #000000;
            letter-spacing: 0.116px;
            line-height: 1.1;
            margin: 0px 0px;
            display: flex;
            align-items: center;

            img {
              margin-right: 5px;
            }
          }

          span {
            line-height: 0px;
          }

          span.BannerLocationStatus {
            font-size: 11.775px;
            font-style: normal;
            font-weight: 400;
            border-radius: 3.588px;
            background-color: #dff8ea;
            padding: 3px 7px 3px 7px;
            line-height: normal;
            color: #3c7c65;
          }
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.WplBannerImageBox3 {
  width: 100%;
  max-width: 210px;
  display: inline-block;
  vertical-align: top;

  .BannerPhoneInformation {
    width: 100%;
    display: inline-block;
    vertical-align: top;

    .BannerPhoneInformationBox {
      border-radius: 3.588px;
      background: #ffffff;
      padding: 10px 10px;
      display: flex;
      margin-bottom: 7px;
      box-shadow: 1.794px 1.794px 6.28px 2.691px rgba(0, 0, 0, 0.1);

      .BannerPhoneInformationContent {
        margin-left: 8px;

        h4 {
          font-size: 12.56px;
          font-style: normal;
          font-weight: 700;
          line-height: 1;
          letter-spacing: 0.092px;
          text-transform: uppercase;
          color: #050505;
          margin: 0px 0px 6px;
          text-align: left;
        }

        p {
          font-size: 9.924px;
          font-style: normal;
          font-weight: 300;
          color: #050505;
          line-height: 1;
          letter-spacing: 0.116px;
          margin: 0px 0px;
          text-align: left;
        }
      }

      .BannerPhoneOwnerInfo {
        margin-left: 7px;
      }

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    .BannerPhoneInformationBox.justify-content {
      justify-content: space-between;
    }
  }
}

// 2nd Phone HTML Image

.ReverslyLookupHelp__img {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
}
ul {
  list-style: none;
  .list-item {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 11px;
  }
}
.full-report-box {
  width: 592px;
  height: 459px;
  background-color: #ffffff;
  position: relative;
}
.bg-pattern {
  width: 379px;
  height: 441px;
  background-color: #ffffff;
  float: right;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  top: 18px;
  .line {
    background-color: #d9d9d9;
    width: 339px;
    height: 0.71px;
    display: flex;
    align-items: center;
  }
}
.pattern-box {
  width: 330px;
  height: 123px;
  background-color: #24537b;
  border-radius: 9px;
  margin-top: 25px;
  opacity: 0.1;
}
.box-full-report {
  background-color: #ffffff;
  width: 414px;
  height: auto;
  border-radius: 4px;
  padding: 32px;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 4px;
  box-shadow: 0px 16px 40px 0px #00000016;
  z-index: 1;
  h3 {
    font-family: "Inter", sans-serif;
    font-size: 19px;
    font-weight: bold;
    line-height: 30px;
  }
}
.inner-check-list {
  display: flex;
  justify-content: space-between;
}
.report-check-list-box {
  height: auto;
  margin-top: 24px;
  width: 100%;
  padding-right: 20px;
}
.list-item.m-h {
  display: none;
}
.list-item {
  li {
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.14px;
    a {
      text-decoration: none;
      color: #000000;
    }
  }
  .check-box {
    width: 26px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dff8ea;
    border-radius: 4px;
  }
}
.check-box {
  img {
    width: 16px;
    height: 11px;
    object-fit: cover;
  }
}
.email-box {
  position: absolute;
  right: -20px;
  top: 10px;
  height: auto;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 5px 5px 16px 0px #00152e20;
  z-index: 1;
  background-color: #ffffff;
  float: right;
  h4 {
    font-size: 14px;
    font-weight: 500;
  }
}
.email-inner-box {
  width: 100%;
  border: 1px dashed #000000;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  .email-match {
    width: max-content;
    height: auto;
    padding: 4px;
    border-radius: 2px;
    background-color: #ecf7fb;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h5 {
    font-size: 13px;
    font-weight: 500;
  }
  h3 {
    font-size: 13px;
    font-weight: 500;
    margin-top: 8px;
  }
  .email-type-box {
    display: flex;
    align-items: start;
    margin-top: 14px;
    align-items: center;
  }
  h4 {
    display: none;
  }
}
.email-match {
  h5 {
    font-size: 10px;
    line-height: 10px;
    color: #24537b;
  }
  img {
    margin-right: 2px;
  }
}
.email-type-box {
  h3.e-type {
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    color: #747474;
    margin-top: 0px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    p {
      padding-right: 3px;
    }
  }
  p {
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    color: #535353;
  }
}
.owner-photo-box {
  position: absolute;
  left: 50px;
  top: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 5px 5px 16px 0px #00152e20;
  width: 240px;
  height: auto;
  padding: 10px;
  z-index: 9;
  h3 {
    font-size: 14px;
    font-weight: 500;
  }
}
.inner-photos-box {
  width: 100%;
  display: flex;
  align-items: start;
  margin-top: 7px;
}
.photo-box {
  width: 52px;
  height: 52px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  object-fit: cover;
  margin: 0 1px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.social-profile-box {
  width: auto;
  height: auto;
  background-color: #ffffff;
  padding: 10px;
  align-items: start;
  position: absolute;
  right: 0;
  bottom: 0;
  float: right;
  z-index: 99;
  border-radius: 12px;
  box-shadow: 5px 5px 16px 0 #00152e20;
  h4 {
    font-size: 14px;
    font-weight: 500;
  }
}
ul.social-media {
  width: 100%;
  display: block;
  list-style: none;
}
.media-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 8px;
  .icon {
    width: 16px;
    height: 16px;
    object-fit: cover;
    margin-right: 4px;
  }
  a {
    text-decoration: none;
    font-size: 10px;
    color: #2d2d2d;
  }
}
li {
  list-style: none;
}
.icon {
  img {
    width: 100%;
    height: 100%;
  }
}
.section__faqs_wrapper {
  background-color: #ffffff;
}
.ReverslyLookupHelp__area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px -12.5px;
  .ReverslyLookupHelp__img {
    max-width: 55%;
    flex: 0 0 55%;
    padding: 0px 12.5px;
  }
  .accordion__wrapper {
    max-width: 45%;
    flex: 0 0 45%;
    padding: 0px 12.5px;
  }
}
@media only screen and (max-width: 1440px) {
  .full-report-box {
    width: 522px;
  }
}
@media only screen and (min-width: 576px) {
  .full-report-box {
    margin: 0px auto;
  }
}
@media only screen and (max-width: 575px) {
  .email-inner-box {
    .email-match {
      width: auto;
      img {
        margin-right: 5px;
      }
    }
  }
  .email-inner-box {
    h5 {
      font-size: 10px;
    }
    h4 {
      display: none;
    }
  }
  .email-box {
    right: 0;
    right: -25%;
    top: auto;
    bottom: 0;
  }
  .owner-photo-box {
    left: 24px;
    left: 50%;
  }
  .bg-pattern {
    padding: 10px;
    width: max-content;
    margin-left: 0;
  }
  .social-profile-box {
    left: 89%;
    top: 38%;
    bottom: auto;
    right: auto;
    margin-right: 0;
    width: max-content;
  }
  .full-report-box {
    width: max-content;
  }
}
@media only screen and (max-width: 480px) {
  .inner-check-list {
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .pattern-box {
    height: 103px;
  }
  .list-item.h {
    display: none;
  }
  .box-full-report {
    width: 268px;
    padding: 15px;
    left: 30px;
  }
  .owner-photo-box {
    width: 175px;
    left: 59%;
    top: 12%;
  }
  .email-inner-box {
    h4 {
      display: block;
    }
    .email-match {
      width: 80px;
      margin-bottom: 5px;
    }
    padding: 5px;
    h3.h {
      display: none;
    }
  }
  .social-profile-box {
    width: 179px;
    bottom: 37%;
    margin-right: 23px;
  }
  .email-box {
    right: 5%;
    top: 68%;
    padding: 10px;
  }
  .email-type-box {
    h3.e-type {
      p.h {
        display: none;
      }
    }
  }
  .report-check-list-box.mt-small {
    margin-top: 0px;
  }
  .bg-pattern {
    .line {
      display: none;
    }
  }
  .report-check-list-box {
    padding-right: 10px;
  }
  .h {
    display: none;
  }
  .photo-box {
    &:nth-child(3).h {
      display: block;
    }
  }
  .list-item.m-h {
    display: flex;
  }
}
@media only screen and (max-width: 393px) {
  .bg-pattern {
    padding-right: 60px;
  }
  .email-inner-box {
    .email-match {
      width: 70px;
    }
    h5 {
      font-size: 8px;
    }
    padding: 5px;
    h3 {
      font-size: 10px;
    }
    h4 {
      font-size: 10px;
    }
  }
  .pattern-box {
    width: 200px;
    height: 100px;
    float: right;
    margin-top: 20px;
  }
  .h-small {
    margin-top: 51px;
  }
  .owner-photo-box {
    width: auto;
    left: 58%;
    top: 16%;
    padding: 10px;
  }
  .social-profile-box {
    width: 132px;
    right: 3%;
    bottom: 32%;
  }
  .email-box {
    padding: 10px;
    right: 9%;
    top: 72%;
  }
  .email-type-box {
    p {
      color: #000000;
    }
  }
  .inner-check-list {
    margin-top: 10px;
  }
  .mt-0 {
    margin-top: 10px;
  }
  .box-full-report {
    width: 216px;
  }
  .media-box {
    a {
      font-size: 9px;
    }
  }
  .photo-box {
    &:nth-child(3).h {
      display: none;
    }
  }
}
@media only screen and (max-width: 1199px) {
  .ReverslyLookupHelp__area {
    flex-wrap: wrap;
    .ReverslyLookupHelp__img {
      max-width: 100%;
      flex: 0 0 100%;
      margin-top: 30px;
      order: 1;
    }
    .accordion__wrapper {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 30px;
    }
  }
}
@media only screen and (max-width: 475px) {
  .social-profile-box {
    left: 59% !important;
  }
  .email-box {
    right: 0% !important;
  }
}
@media only screen and (max-width: 400px) {
  .ReverslyLookupHelp__img {
    display: none;
  }
}

.faqs_accordion-body.faqs_accordion-body-open {
  .faqs__accordion_info {
    border: 1px solid #a9d4ff;
  }
}

// Banner Input Fiedl

.homePage-main-section {
  .bg-grad-section {
    padding-bottom: 0px !important;
  }
}
.reverslyHomeBannerInput {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
button.reversly_cta_wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 14px 15px;
  border: 1px solid #24537b;
  border-radius: 5px;
  transition: 0.5s ease-in-out;
  z-index: 1;
  background-color: #24537b;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 212px;
  span.span-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-family: "Inter", serif;
    display: inline-block !important;
  }
}
@media only screen and (min-width: 768px) {
  .reverslyHomeBannerInput {
    .wpb_content_input {
      display: flex;
      width: 100%;
      max-width: 580px;
      .reverslyInputDesktop {
        display: flex;
        border-radius: 5px;
        border: 1px dashed #152536;
        background: #ffffff;
        box-sizing: border-box;
        flex: 0 0 calc(100% - 202px);
        margin-right: 10px;
        input.desktop-form-control {
          background-color: transparent;
          border: none;
          flex: 0 0 calc(100% - 80px);
          max-width: calc(100% - 80px);
          width: 100%;
          padding: 10px 15px;
          border-left: 1px dashed #152536;
          border-radius: 0px;
          box-shadow: unset;
          font-size: 14px;
          color: #000000;
          font-weight: 500;
        }
        .desktop-input {
          flex: 0 0 80px;
          max-width: 80px;
          display: flex;
          flex-direction: row-reverse;
          padding: 13px 0px 13px 10px;
          input.phone-input-field {
            border: unset;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #000000;
            line-height: 1;
            width: 43px;
            cursor: default;
            &:focus-visible {
              outline: unset;
            }
          }
          .flag-dropdown {
            background-color: transparent;
            border: unset;
            width: 35px;
            position: static;
          }
        }
        > * {
          box-sizing: border-box;
        }
      }
      .reverslyInputMobile {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .HomeBanner_Wrapper {
    overflow: visible;
  }
  .reverslyHomeBannerInput {
    .wpb_content_input {
      display: flex;
      width: 100%;
      max-width: 580px;
      .reverslyInputDesktop {
        display: none !important;
      }
      .reverslyInputMobile {
        display: flex;
        border-radius: 5px;
        border: 1px dashed #152536;
        background: #ffffff;
        box-sizing: border-box;
        flex: 0 0 calc(100% - 202px);
        margin-right: 10px;
        input.mobile-form-control {
          background-color: transparent;
          border: none;
          flex: 0 0 calc(100% - 80px);
          max-width: calc(100% - 80px);
          width: 100%;
          padding: 10px 15px;
          border-left: 1px dashed #152536;
          border-radius: 0px;
          box-shadow: unset;
          font-size: 14px;
          color: #000000;
          font-weight: 500;
        }
        .mobile-input {
          flex: 0 0 80px;
          max-width: 80px;
          display: flex !important;
          flex-direction: row-reverse;
          padding: 13px 0px 13px 10px;
          input.phone-input-field {
            border: unset;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #000000;
            line-height: 1;
            width: 43px;
            &:focus-visible {
              outline: unset;
            }
          }
          .flag-dropdown {
            background-color: transparent;
            border: unset;
            width: 35px;
            position: static;
          }
        }
        > * {
          box-sizing: border-box;
        }
      }
    }
  }
}
@media only screen and (max-width: 625px) {
  .reverslyHomeBannerInput {
    .wpb_content_input {
      flex-wrap: wrap;
      max-width: 100%;
      .reverslyInputMobile {
        flex: 0 0 100%;
        margin-right: 0px;
        margin-bottom: 15px;
      }
    }
  }
  button.reversly_cta_wrap {
    flex: 0 0 100%;
  }
}

// Header SCSS

.Header_wrapper {
  .HeaderFuncList {
    display: inline-flex;
    align-items: center;
    .header-lang-drop {
      li {
        img {
          border-radius: 100%;
          object-fit: cover;
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }
  }
}
.WplBannerImageBox2 {
  .BannerLocationList {
    ul {
      li {
        .BannerLocationArea {
          pre {
            max-width: 33.33%;
            flex: 0 0 33.33%;
          }
          .LocateDesignPattern {
            max-width: 33.33%;
            flex: 0 0 33.33%;
          }
          span {
            max-width: 33.33%;
            flex: 0 0 33.33%;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1024px) {
  .responsive-header-btn {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .Header_wrapper {
    .HeaderMenuList {
      display: none;
    }
  }
}
@media only screen and (max-width: 635px) {
  .responsive-header-btn {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .NewHomeHeader {
    .Header_wrapper {
      .HeaderMenuList {
        ul {
          li {
            padding: 0px 6px;
            a {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

.HomeBanner__Info {
  .reverslyHomeBannerInput {
    position: relative;
    p.invelid-number-error {
      position: absolute;
      top: -30px;
      left: 20%;
      display: flex;
      align-items: center;
      width: max-content;
      background: #ff3855;
      color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 4px 5px 2px #fd8a97;
      padding: 6px 15px;
      margin-bottom: 0px;
    }
  }
}
.theme_dir__rtl {
  .HomeBanner__Info {
    .reverslyHomeBannerInput {
      p.invelid-number-error {
        left: auto;
        right: 20%;
      }
    }
  }
}

.locating_popup__wrap {
  .wrap-top-modal {
    .body-dash-modal {
      .dashboard-message {
        textarea {
          max-width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) {
  .owner-photo-box {
    top: -15%;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 576px) {
  .ReverslyLookupHelp__area {
    .ReverslyLookupHelp__img {
      margin-top: 80px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .owner-photo-box {
    h3 {
      text-align: left;
    }
  }
}

// Review Banner

.BannerReviewInfo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .BannerReviewslogs {
    display: flex;
    align-items: center;
    .BannerReviewsContent {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #000000;
      line-height: 1.2;
      letter-spacing: 0.105px;
      position: relative;
      small {
        position: absolute;
        top: -3px;
      }
    }
    .BannerReviewsIcon {
      margin-right: 6px;
    }
  }
  .BannerReviewslogs.BannerReviewsPrivacy {
    border-radius: 20px;
    background: rgba(233, 201, 98, 0.5);
    padding: 6px 5px;
    height: 22px;
    .BannerReviewsContent {
      font-size: 10px;
      line-height: 10px;
    }
  }
  hr.vertical-line {
    width: 1px;
    height: 21px;
    outline: none;
    border: none;
    background-color: #000000;
    margin: 0px 25px;
  }
}
@media screen and (max-width: 675px) {
  .BannerReviewInfo {
    flex-wrap: wrap;
    .BannerReviewslogs {
      width: 100%;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    hr.vertical-line {
      display: none;
    }
    .BannerReviewslogs.BannerReviewsPrivacy {
      width: max-content;
    }
  }
}

.theme_dir__rtl {
  .BannerReviewInfo {
    .BannerReviewslogs {
      .BannerReviewsIcon {
        margin-left: 6px;
        margin-right: 0px;
      }
    }
  }
}

.theme_dir__rtl {
  .box-full-report {
    h3 {
      text-align: left;
    }
  }
}

.sec__faqs__wrapper {
  h2.SectionMainTitle {
    text-transform: capitalize;
  }
}

.reversly__box_wrapper {
  &::before {
    content: "";
    position: absolute;
    width: 361px;
    height: 361px;
    border-radius: 361px;
    background: rgba(41, 93, 226, 0.2);
    filter: blur(100px);
    z-index: -1;
    top: 0px;
    bottom: auto;
    left: -10%;
    right: auto;
  }
  &::after {
    content: "";
    position: absolute;
    width: 361px;
    height: 361px;
    border-radius: 361px;
    background: rgba(41, 93, 226, 0.2);
    filter: blur(100px);
    z-index: -1;
    top: auto;
    bottom: 0px;
    left: auto;
    right: -10%;
  }
}
@media screen and (max-width: 1500px) {
  .reversly__box_wrapper {
    &::before {
      left: 0%;
    }
    &::after {
      right: 0%;
    }
  }
}
@media screen and (max-width: 767px) {
  .reversly__box_wrapper {
    &::before {
      width: 300px;
      height: 300px;
    }
    &::after {
      width: 300px;
      height: 300px;
    }
  }
}
