.white-bg-wrap {
  .about-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #777777;
    line-height: 1.2;

    a {
      color: #777777;
    }
  }

  .premium-report-img {
    margin: 40px 0px;
    position: relative;
    text-align: center;

    > img {
      filter: blur(2px);
    }
    button {
      cursor: pointer;
    }
  }

  // Premium section 5

  .premium-list-report {
    border-radius: 4px;
    padding: 20px 20px;
    border: 1px solid rgba(36, 83, 123, 0.2);
    background: rgba(236, 247, 251, 0.3);

    h3 {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;
      color: #000000;
      display: inline-block;
      margin: 0px 0px 20px;
    }

    ul {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin: 0px 0px;
      padding: 0px 0px;
      list-style: none;

      li {
        display: flex;
        align-items: self-start;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3;
        color: #505050;

        img {
          margin-right: 8px;
        }
      }
    }
  }

  .toggle_area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .premium_toggle {
      border-radius: 3px;
      background-color: #ecf7fb;
      padding: 6px 10px;
      p {
        margin: 0;
        cursor: pointer;
      }

      img {
        width: 100%;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  button {
    &.phone-btn-info {
      display: inline-block;
      background-color: #24537b;
      padding: 14px 85px 14px 14px;
      border-radius: 10px;
      position: absolute;
      border: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      p {
        font-size: 18px;
        line-height: 1.3;
        margin: 0px;
        color: #ffffff;
        text-decoration: none;
      }

      .banner-btn-icon {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);
      }
    }
  }

  @media only screen and (max-width: 660px) {
    button {
      &.phone-btn-info {
        width: 100%;
        max-width: 280px;
        padding: 14px 55px 14px 14px;

        a {
          font-size: 16px;
        }
      }
    }

    .banner-btn-icon {
      img {
        width: 100%;
        max-width: 70px;
      }
    }

    .premium-list-report {
      padding: 15px 15px;

      h3 {
        margin: 0px 0px 10px;
        font-size: 20px;
      }

      ul {
        li {
          font-size: 14px;

          img {
            width: 100%;
            max-width: 18px;
          }
        }
      }
    }

    .toggle_area {
      .premium_toggle {
        padding: 3px 6px;
      }
    }
  }

  @media only screen and (max-width: 410px) {
    button {
      &.phone-btn-info {
        padding: 14px 40px 14px 14px;
        border-radius: 10px 50px 50px 10px;

        .banner-btn-icon {
          top: 0;
          right: 0;
          transform: unset;

          img {
            max-width: 50px;
          }
        }
      }
    }
  }
}
.theme_dir__rtl {
  .white-bg-wrap {
    .premium-list-report {
      ul {
        li {
          img.right_icon {
            margin-right: 0;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
