.phone-report-box {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;

  .phone-report-text {
    h2,
    a {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.4;
      color: #000000;
      margin: 0px 0px 15px;
      display: inline-block;
      vertical-align: top;
    }

    a {
      color: #24537b;
    }

    p {
      display: inline-block;
      vertical-align: top;
      margin: 0px 0px;
    }

    ul {
      padding: 0px 0px;
      margin: 0px 0px;
      list-style: none;

      li {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #000000;
        display: flex;
        align-items: center;
        grid-column-gap: 10px;
        img {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .phone-report-box {
    justify-content: center;
    text-align: center;

    .phone-report-img {
      width: 100%;
      text-align: center;

      img {
        width: 80px;
        height: 80px;
        margin-bottom: 15px;
      }
    }

    .phone-report-text {
      ul {
        text-align: center;

        li {
          font-size: 16px;
        }
      }

      h2,
      a {
        font-size: 16px;
      }
    }
  }

  .phone-report-text {
    .phone-report-info {
      text-align: center;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 50px;
    }
  }
}

@media only screen and (max-width: 520px) {
  .white-bg-wrap {
    padding: 20px 15px;
  }
}

// location_table_info

.location_table_info {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;

  table {
    width: 100%;
    background: rgba(199, 199, 199, 0.1);
    border-radius: 10px;
    border-collapse: collapse;
    overflow: hidden;

    thead {
      border-radius: 10px 10px 0px 0px;
      background: #ecf7fb;
      width: 100%;

      tr {
        th {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.3;
          padding: 15px 15px;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.3;
          color: #24537b;
          padding: 15px 15px;
          text-align: center;
          border-top: 1px solid rgba(0, 0, 0, 0.05);
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);

          span {
            img {
              display: inline-block;
              vertical-align: middle;
              margin-left: 40px;
              animation: spin 4s infinite linear;
            }
          }
        }
      }
    }

    tr {
      .border-right {
        border-right: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.gps_title {
  h2 {
    font-size: 24px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.location_map {
  margin-bottom: 20px;

  iframe {
    border-radius: 10px;
  }
}

.send_message_btn {
  .track_button {
    span {
      display: flex;
      align-items: center;
      gap: 10px;
      .send_message_btn_number {
        margin: 0;
        font-weight: 600;
        direction: ltr;
      }
    }
  }
}

a {
  &.track_button {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #ecf7fb;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 15px 15px 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    transition: all 0.4s ease-in-out;
    color: #000000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    strong {
      padding: 0px 7px;
    }
    img {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
      width: 40px;
      height: 40px;
    }

    &:hover {
      background: #ecf7fb;
    }
  }
}

.location_map {
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  a.track_button {
    font-size: 12px;
    padding: 9px 10px;

    strong {
      display: inline-block;
    }

    img {
      width: 30px;
      height: 30px;
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 475px) {
  .location_table_info {
    table {
      thead {
        tr {
          th {
            padding: 10px 10px;
            font-size: 14px;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 14px;
            padding: 10px 10px;

            span {
              img {
                margin-left: 10px;
                width: 100%;
                max-width: 13px;
              }
            }
          }
        }
      }
    }
  }
  .track_button {
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 425px) {
  .send_message_btn {
    .track_button {
      display: flex;
      justify-content: space-between;
      span {
        gap: 0;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
@media only screen and (max-width: 412px) {
  .defult_track_btn {
    a.track_button {
      display: flex;
      justify-content: space-between;
      p {
        display: block;
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .location-info-wrap {
    .location_map {
      .dashboard-map {
        .map-container {
          height: 310px !important;
        }
      }
    }
  }
}
