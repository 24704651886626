footer {
	* {
		box-sizing: border-box;
	}
	&.site_footer {
		.site_footer_padding{
			background-color: #FFFFFF;
			filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.20));
			padding: 20px 0px;
			text-align: center;
		}
	}
}

.main_container_footer {
	width: 100%;
	max-width: 1130px;
	margin: 0px auto;
	padding: 0px 15px;
}

.ds-footer-top-content {
	width: 100%;
	display: inline-block;
	vertical-align: top;
	max-width: 960px;

	p {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 1.2;
		color: #777777;
		width: 100%;
		display: inline-block;
		margin: 0px 0px 15px;
	}
}

.ds-copyright-footer {
	p {
		width: 100%;
		display: inline-block;
		vertical-align: top;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		color: #24537B;
		margin: 0px 0px 15px;
	}
}

.ds-footer-conditions {
	ul {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 0px 0px;
		margin: 0px 0px;
		list-style: none;
		column-gap: 15px;

		li {
			a {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 1.2;
				color: #24537B;
				position: relative;
				padding-right: 15px;
			}

			&:not(:last-child) {
				a {
					&::before {
						content: "";
						position: absolute;
						width: 100%;
						max-width: 1px;
						height: 13px;
						background-color: #24537B;
						top: 50%;
						right: 0px;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
}

.ds-footer-logo {
	width: 100%;
	display: inline-block;
	margin-bottom: 10px;
}

@media only screen and (max-width: 899px){
    footer.site_footer {
        width: 100%;
        display: inline-block;
        vertical-align: top;
		margin-bottom: 50px;
    }
}

@media only screen and (max-width: 405px) {
	.ds-footer-conditions {
		ul {
			row-gap: 15px;
		}
	}
}

// Footer Fixed Css

header.header_wrap{
	height: unset !important;
}
div#root {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height:100vh;
	height: 100%;
}
.main_root {
    height: 100%;
}
#root:has(:not(.site_footer)) footer.site_footer {
    margin-top: auto;
}