@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
.classUp_mrg {
  width: 100%;
  max-width: 900px;
  margin: auto;
  display: flex;
  align-items: center;
  height: 100vh;
}
.polyanimation_wrapper {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 0px 11px;
  overflow: hidden;
  .animation_title {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 60px;
    h2 {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      margin: 0px 0px 20px;
    }
    p {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #777777;
      text-align: center;
      margin: 0px 0px;
    }
  }
  .animation_inner_wrap {
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0px auto;
    z-index: 1;
  }
  .animation_text_wrap {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #000000;
    text-align: center;
  }
}
.animation_inner_wrap {
  .animation_spin1 {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    z-index: -1;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    -webkit-animation: spin 5s linear infinite;
    animation: spin 5s linear infinite;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/animation_spin1_img.svg);
  }
  .animation_spin2 {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    z-index: -1;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    -webkit-animation: spin-reverse 5s linear infinite;
    animation: spin-reverse 5s linear infinite;
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    background-position: calc(50% - 7px) 50%;
    background-image: url(../../assets/images/animation_spin2_img.svg);
  }
  .animation_spin3 {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    z-index: -1;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    -webkit-animation: spin 5s linear infinite;
    animation: spin 5s linear infinite;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    background-position: calc(50% - 0px) 50%;
    background-image: url(../../assets/images/animation_spin3_img.svg);
  }
  .animation_spin4 {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    z-index: -1;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    -webkit-animation: spin-reverse 5s linear infinite;
    animation: spin-reverse 5s linear infinite;
    width: calc(100% - 150px);
    height: calc(100% - 150px);
    background-position: calc(50% - 0px) calc(50% - 9px);
    background-image: url(../../assets/images/animation_spin4_img.svg);
  }
  .animation_spin5 {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    z-index: -1;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    -webkit-animation: spin 5s linear infinite;
    animation: spin 5s linear infinite;
    width: calc(100% - 200px);
    height: calc(100% - 200px);
    background-position: calc(50% - 0px) calc(50% - 1px);
    background-image: url(../../assets/images/animation_spin5_img.svg);
  }
}
@media only screen and (max-width: 575px) {
  .polyanimation_wrapper {
    .animation_text_wrap {
      font-size: 16px;
    }
    .animation_inner_wrap {
      width: 410px;
      height: 410px;
    }
  }
}
@media only screen and (max-width: 475px) {
  .polyanimation_wrapper {
    .animation_text_wrap {
      font-size: 14px;
      width: 100%;
      max-width: 80px;
    }
    .animation_inner_wrap {
      width: 300px;
      height: 300px;
    }
  }
  .animation_inner_wrap {
    .animation_spin2 {
      background-position: calc(50% - 5px) 50%;
    }
    .animation_spin4 {
      background-position: calc(50% - 0px) calc(50% - 4px);
    }
  }
}
