.general-info-wrap {
  .main_title {
    h2 {
      text-align: left;
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 15px;
    }
  }
}

.phone-info-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 30px;

  .phone-info-box {
    width: 100%;
    display: inline-block;
    vertical-align: top;

    hr {
      margin: 10px 0px 0px;
      border: unset;
      border-bottom: 1px solid rgba(36, 83, 123, 0.1);
    }

    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.4;
      margin: 0px 0px 0px;
    }

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      color: #777777;
      margin-bottom: 13px;
      display: inline-block;
      vertical-align: top;
    }
  }
}

.phone-info-wrap .phone-info-box:nth-last-child(-n + 3) hr {
  border: unset;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .phone-info-wrap .phone-info-box:nth-last-child(-n + 3) hr {
    border-bottom: 1px solid rgba(36, 83, 123, 0.1);
    margin-top: 10px;
  }

  .phone-info-wrap .phone-info-box:nth-last-child(-n + 2) hr {
    border: unset;
    margin: 0;
  }

  .phone-info-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 375px) {
  .phone-info-wrap {
    row-gap: 15px;
  }

  .phone-info-wrap .phone-info-box:nth-last-child(-n + 3) hr {
    border-bottom: 1px solid rgba(36, 83, 123, 0.1);
    margin-top: 10px;
  }

  .phone-info-wrap .phone-info-box:nth-last-child(-n + 1) hr {
    border: unset;
    margin: 0;
  }

  .phone-info-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
