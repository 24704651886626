.preview_modal_full_width {
  width: 100% !important;
  max-width: 100vw;
}
a {
  text-decoration: unset;
}

.location_popup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.location_popup {
  .location_popup-container {
    width: 100%;
    max-width: 540px;
    margin: 0px auto;
  }
}
.location-info {
  width: 100%;
  height: 100%;
  display: inline-block;
  .location-box {
    height: 100%;
    border-radius: 20px;
    background: #fff;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
    padding: 50px 30px 30px;
    text-align: center;
    margin: 30px 30px;
    @media only screen and (max-width: 991px) {
      padding: 20px 20px 20px;
      margin: 20px 20px;
    }
    h2,
    h2 a {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.3;
      color: #000;
      text-align: center;
      margin-top: 0px;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    > p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      color: #000000;
    }
  }
}
.location_button {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-top: 15px;
  > p {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin: 0px 0px;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
button {
  &.defult_btn {
    width: calc(100% - 30px);
    height: 100%;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    text-transform: uppercase;
    color: #24537b;
    text-align: center;
    border-radius: 5px;
    padding: 14px 15px;
    border: 1px solid rgba(226, 244, 249, 0.1);
    background-color: #e2f4f9;
    transition: all 0.4s ease-in-out;
    &.btn_border {
      background-color: transparent;
      border: 1px solid rgba(36, 83, 123, 0.15);
      &:hover {
        border: 1px solid rgba(226, 244, 249, 0.1);
        background-color: #e2f4f9;
      }
    }
    &:hover {
      background-color: transparent;
      border: 1px solid rgba(36, 83, 123, 0.15);
    }
  }
}

@media only screen and (max-width: 475px) {
  .location_image {
    img {
      display: block;
      text-align: center;
      margin: 0 auto;
    }
  }
  .location-info {
    .location-box {
      padding: 15px 15px;
      margin: 15px 15px;
    }
  }
  button.defult_btn {
    padding: 10px 15px;
  }
  .location-info {
    .location-box {
      h2 {
        br {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .location_image {
    img {
      width: 100%;
    }
  }
  .location-info {
    .location-box {
      h2 {
        a {
          display: block;
        }
      }
    }
  }
}

.location_image_container {
  position: relative;
}

.text-overflow {
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  max-width: 240px;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
  .message_overlay {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 210px;
    text-align: center;
    margin: 0 auto;
  }
}
.location_image {
  max-width: 100%;
  height: auto;
}

.error-message {
  color: red !important ;
  margin-top: 2px !important;
}

// Image HTML Css

.lp-tutorial-section.preview_tutorial_section {
  min-height: inherit;
  .tutorial__img {
    height: 100%;
    .ln_tutorial_mockup {
      max-width: 255px;
      padding-top: 90px;
      height: calc(363px - 90px);
      &::before {
        left: 50%;
        width: 286px;
        height: 363px;
        background-image: url("../../assets/images/lp_preview_popup_img.png") !important;
      }
    }
    .ln_tutorial_wrapper {
      justify-content: flex-start !important;
      .ln_preview_popup {
        background-color: #429cff;
        border-radius: 20px;
        padding: 10px 10px;
        margin: 10px 10px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: auto;
          bottom: -10px;
          left: auto;
          right: 0px;
          width: 100%;
          max-width: 25px;
          height: 15px;
          display: inline-block;
          background-image: url("../../assets/images/preview_popup_vector.svg") !important;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        span {
          font-size: 14px;
          line-height: normal;
          font-weight: 400;
          color: #ffffff;
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .lp-tutorial-section.preview_tutorial_section {
    .tutorial__img {
      .ln_tutorial_mockup {
        &::before {
          width: 270px;
        }
      }
    }
  }
}
