.wpl__access__modal {
	width: 100%;
	max-width: 610px;
	padding: 50px 50px;
	box-sizing: border-box;
	.access__info__wrap {
		width: 100%;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		.modal__wpl__img {
			margin-bottom: 30px;
			img {
				width: 100%;
				max-width: 100px;
				height: 100%;
			}
		}
		.modal__wpl__text {
			width: 100%;
			display: inline-block;
			vertical-align: top;
			margin-bottom: 100px;
		}
	}
}
h2.modal__popup_title {
	width: 100%;
	display: inline-block;
	vertical-align: top;
	font-size: 32px;
	line-height: 1.1;
	margin-top: 0px;
	margin-bottom: 40px;
}
.modal__field_wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 20px 20px;
	border-radius: 10px;
	background: #FFF;
	box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.20);
	img {
		width: 100%;
		max-width: 90px;
		height: 90px;
		margin-right: 50px;
	}
	.mobile_phone__wrapper {
		a {
			width: 100%;
			font-weight: 700;
			display: inline-block;
			vertical-align: top;
			font-size: 28px;
			line-height: 1.1;
			color: #24537B;
		}
	}
}
.report__popup_pay_btn {
	width: 100%;
	display: inline-block;
	vertical-align: top;
	p {
		margin: 0px 0px;
		button {
			width: 100%;
			display: inline-block;
			vertical-align: top;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 1.3;
			color: #ffffff;
			padding: 10px 15px;
			border-radius: 100px;
			background-color: #24537b;
			border: 1px solid #24537b;
			text-align: center;
			transition: all 0.4s ease-in-out;
			margin-bottom: 16px;
			cursor: pointer;
			&:hover {
				color: #24537B;
				border-color: #24537B;
				background-color: transparent;
			}
		}
		button.report_bg_color {
			&:hover {
				color: #24537B;
				border-color: #24537B;
				background-color: transparent;
			}
			color: #24537B;
			background-color: #E2F4F9;
			border: 1px solid rgba(226, 244, 249, 0.10);
			margin-bottom: 0px;
		}
	}
}
@media only screen and (max-width: 1024px) {
	.wpl__access__modal {
		padding: 30px 30px;
		.access__info__wrap {
			.modal__wpl__text {
				margin-bottom: 70px;
			}
		}
	}
}
@media only screen and (max-width: 767px) {
	h2.modal__popup_title {
		font-size: 22px;
		margin-bottom: 20px;
	}
	.wpl__access__modal {
		padding: 15px 15px;
		.access__info__wrap {
			.modal__wpl__text {
				margin-bottom: 40px;
			}
			.modal__wpl__img {
				img {
					max-width: 70px;
				}
			}
		}
	}
	.report__popup_pay_btn {
		p {
			button {
				margin-bottom: 10px;
			}
		}
	}
	.modal__field_wrapper {
		img {
			margin-right: 20px;
		}
	}
}
@media only screen and (max-width: 500px) {
	.modal__field_wrapper {
		img {
			margin-right: 0px;
			margin-bottom: 20px;
		}
		justify-content: center;
	}
	.mobile_phone__wrapper {
		width: 100%;
	}
}


// Modal 4 Scss

.unlock__report_processs{
	width: 100%;
	max-width: 530px;
	padding: 40px 40px;
	box-sizing: border-box;
	text-align: center;
}


.report_prepared__modal {
	.modal__wpl__text {
		margin-bottom: 50px;
	}
	.report_prepared__img {
		margin-bottom: 50px;
	}
	.mb-0 {
		margin-bottom: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.report_prepared__modal {
		.modal__wpl__text {
			margin-bottom: 20px;
		}
		.report_prepared__img {
			margin-bottom: 20px;
		}
	}
}

// Close Button SCSS
.report_wrapper__modal {
	button.ant-modal-close {
		background-color: #24537B;
		width: 25px;
		height: 25px;
		border-radius: 25px;
		svg {
			fill: #FFFFFF;
		}
		&:hover {
			background-color: #24537B !important;
		}
	}
}
