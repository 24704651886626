.ant-table-wrapper .ant-table-filter-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-btn {
    background-color: #1664fa;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    svg {
      font-size: 20px;
      color: white;
    }
  }
}
.currency_data {
  margin-top: 50px;
  overflow: auto;
  .add_currency_btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    button {
      align-items: center;
      border: none;
      background-color: #1664fa;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      padding: 0.75rem 1.5rem;
      border-radius: 1.5rem;
      text-transform: capitalize;
      font-size: 0.85rem;
      letter-spacing: 1px;
      gap: 5px;
      cursor: pointer;
      svg {
        font-size: 25px;
      }
    }
  }
}
.padd {
  padding: 15px 40px !important;
}
.form_modal_add_currency {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  p {
    margin: 0;
    line-height: 1;
    color: red;
  }
  input {
    height: 40px;
  }
  .add-user-df {
    display: flex;
    gap: 20px;
    .input {
      width: 100%;
    }
  }
  button {
    width: 100%;
    border: none;
    background-color: #1664fa;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    border-radius: 1.5rem;
    text-transform: capitalize;
    font-size: 0.85rem;
    letter-spacing: 1px;
    gap: 5px;
    cursor: pointer;
  }
}
