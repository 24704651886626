// Pay Modal Css

.ant-modal-wrap {
  .ant-modal-footer {
    display: none;
  }
  .multiple_popup__wrap {
    width: 100% !important;
    max-width: 500px !important;
    position: relative;
    padding-bottom: 150px;
    height: 100%;
    max-height: 560px !important;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      background-image: url("../../../../assets/images/pop_bg_img.svg");
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 80%;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    &:after {
      content: "";
      position: absolute;
      background-image: url("../../../../assets/images/unlock_pattern-img.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 8px;
      width: 100%;
      height: 110px;
      top: auto;
      bottom: 0px;
      left: auto;
      right: 0px;
      z-index: -1;
    }
    .wpb_report_popup {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      padding: 40px 40px;
      position: relative;
      z-index: 1;
      box-sizing: border-box;
      .mlt_popup__text {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        margin-bottom: 50px;

        h2 {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          color: #000000;
          margin: 0px 0px 12px;
        }

        p {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          color: #24537b;
          line-height: 1.3;
          margin: 0px 0px;
        }
      }
      .mlt_popup__payment {
        form {
          .dsl_report {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 5px;
            background-color: rgba(36, 83, 123, 0.1);
            border: 1px solid transparent;
            padding: 20px 20px;
            box-sizing: border-box;

            &:not(:last-child) {
              margin-bottom: 30px;
            }
            .dsl_report_field {
              span {
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                color: #24537b;
                line-height: 1.3;
              }
            }

            input[type="radio"] {
              accent-color: #24537b;
            }
            &:has(input[type="radio"]:checked) {
              border: 1px solid #24537b !important;
            }
            input {
              position: absolute;
              opacity: 0;

              &:checked + span.report_radio_wrap {
                &:before {
                  box-shadow: inset 0 0 0 6px #24537b;
                }
              }
            }

            span.report_radio_wrap {
              display: flex;
              align-items: center;
              border-radius: 99em;
              transition: all 0.25s ease;

              &:before {
                display: flex;
                flex-shrink: 0;
                content: "";
                background-color: transparent;
                box-shadow: inset 0 0 0 6px rgba(36, 83, 123, 0.4);
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 15px;
                transition: all 0.25s ease;
              }
            }

            .dsl_report_payment {
              span {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 1.3;
                color: #24537b;
              }

              small {
                display: block;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #777777;
              }
            }
          }
        }
      }
      .mlt_popup__subscribe {
        .policy__content_wrap {
          display: flex;
          align-items: center;
          margin: 40px 0px 0px 0px;

          input[type="checkbox"] {
            accent-color: #24537b;
            font-size: 20px;
            width: 15px;
            height: 15px;
          }

          label {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #24537b;
            margin-left: 10px;
            cursor: pointer;
          }
        }
        .error {
          p {
            margin: 0;
            color: red;
          }
        }
        .report__popup_pay_btn {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          margin: 40px 0px 0px 0px;

          button {
            width: 100%;
            display: inline-block;
            vertical-align: top;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.3;
            color: #ffffff;
            padding: 10px 15px;
            border-radius: 100px;
            background-color: #24537b;
            border: 1px solid #24537b;
            text-align: center;
            transition: all 0.4s ease-in-out;
            margin-bottom: 20px;
            cursor: pointer;

            &:hover {
              background-color: transparent;
              border: 1px solid #24537b;
              color: #24537b;
            }
          }

          p {
            width: 100%;
            display: inline-block;
            margin: 0px 0px;
            text-align: center;

            > span {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: #24537b;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .ant-modal-wrap {
    .multiples_popup__wrap_ {
      .ant-modal-content {
        overflow: hidden;
        .multiple_popup__wrap {
          padding-bottom: 80px;
          .wpb_report_popup {
            padding: 30px 30px;
            .mlt_popup__payment {
              form {
                .dsl_report {
                  padding: 12px 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap {
    .multiple_popup__wrap {
      max-width: 100% !important;
      padding-bottom: 0px !important;
      &:after {
        all: unset;
      }
      .wpb_report_popup {
        padding: 20px 20px;
        .mlt_popup__text {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .ant-modal-wrap {
    .multiple_popup__wrap {
      padding-bottom: 0px;
      .wpb_report_popup {
        .mlt_popup__text {
          h2 {
            font-size: 24px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .ant-modal-wrap {
    .multiple_popup__wrap {
      .wpb_report_popup {
        .mlt_popup__payment {
          form {
            .dsl_report {
              padding: 10px 10px;
              span.report_radio_wrap {
                font-size: 15px;
                &:before {
                  box-shadow: inset 0 0 0 4px rgba(36, 83, 123, 0.4);
                  width: 15px;
                  height: 15px;
                  margin-right: 7px;
                }
              }
              .dsl_report_payment {
                span {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.fixed-size {
  width: 500px;
  height: 700px;
  overflow: hidden;
}

.mlt_popup__text,
.mlt_popup__payment,
.mlt_popup__subscribe {
  overflow: auto;
  max-height: calc(100% / 3);
}
.wpb_report_popup.fixed-size img {
  margin: 0 auto;
  width: 100%;
}

.ant-modal-wrap {
  .multiples_popup__wrap_ {
    .multiple_popup__wrap {
      .wpb_report_popup {
        padding: 20px 20px;
        height: 100%;
      }
      padding-bottom: 50px;
      height: 100%;
      max-height: 100% !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap {
    .multiples_popup__wrap_ {
      .ant-modal-content {
        .multiple_popup__wrap {
          .wpb_report_popup {
            padding: 30px 15px;
          }
        }
      }
    }
  }
}
