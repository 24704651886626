.ds--access-report {
  .premium_box_wrap {
    .premium {
      max-width: 100% !important;
      flex: 0 0 72% !important;
      padding-left: 16px !important;
    }
    .premium-section-width {
      max-width: 100%;
      flex: 0 0 100%;
      padding-left: 0px;
    }
  }
}

// Phone Reports Css

.ds--report-wrap {
  .main_title {
    width: 100%;
    max-width: 950px;
    margin: 0px auto;
    h2 {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      color: #000000;
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      color: #777777;
      text-align: center;
      margin: 0px 0px 15px;
    }
  }
  .report--img {
    text-align: center;
  }
}

.report--listing-title {
  h4 {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1;
    margin: 15px 0px 30px;
    color: #000000;
    text-align: center;
  }
}

.report--img img {
  width: 100%;
  height: 100%;
  max-height: 210px;
}

.report--listing {
  width: 100%;
  max-width: 840px;
  margin: 0px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 20px;

  .report--listing-box {
    border-bottom: 1px solid rgba(36, 83, 123, 0.1);
    padding: 12px 0px;
    display: flex;
    align-items: center;
    column-gap: 15px;

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1;
      color: #24537b;
    }
  }
}

button.access-btn-info {
  display: inline-block;
  background-color: #24537b;
  padding: 14px 60px 14px 30px;
  border-radius: 30px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  border: none;
  cursor: pointer;
  p {
    font-size: 18px;
    line-height: 1.3;
    margin: 0px 0px;
    color: #ffffff;
    font-weight: 500;
    text-decoration: none;
  }
  .access-btn-info {
    position: absolute;
    top: 5px;
    right: 8px;
  }
}

button.disable-access-btn-info {
  display: inline-block;
  background-color: #3b6b95;
  padding: 14px 60px 14px 30px;
  border-radius: 30px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
  border: none;
  cursor: default;
  p {
    font-size: 18px;
    line-height: 1.3;
    margin: 0px 0px;
    color: #ffffff;
    font-weight: 500;
    text-decoration: none;
  }
  .access-btn-info {
    position: absolute;
    top: 5px;
    right: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .ds--report-wrap {
    .main_title {
      h2 {
        font-size: 24px;
      }
    }
  }

  .report--listing {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
}

@media only screen and (max-width: 550px) {
  .ds--report-wrap {
    .main_title {
      h2 {
        font-size: 20px;
      }
    }
  }

  .report--listing {
    grid-template-columns: repeat(1, 1fr);
  }
}

// Theme Modal Scss

.locating_popup__wrap {
  .login-modal-section {
    .login-modal-title {
      background-color: #24537b;
    }
    .wrap-top-modal {
      .body-dash-modal {
        .submit-btn-modal {
          button {
            background-color: #24537b !important;
          }
        }
      }
      .dot {
        background-color: #24537b !important;
      }
    }
  }
  .hl_cta_wrap {
    &::after {
      border-top-color: rgb(3 43 77 / 70%);
    }
    &::before {
      border-bottom-color: rgb(3 43 77 / 70%);
    }
  }
}
// Polly Animation Css
.polly_animation_modal {
  width: 100% !important;
  max-width: 100vw !important;
}

// RTL Related

.theme_dir__rtl {
  .ds--access-report.premium_sec_wrap {
    .main_container {
      .premium_box_wrap {
        .premium-section-width {
          .ds--report-wrap.white-bg-wrap {
            .report-btn-wrap {
              button.access-btn-info {
                padding: 14px 30px 14px 60px;
                div.access-btn-info {
                  left: 7px;
                  right: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
