.trace-pag {
  background-color: #fff !important;
  background-image: none !important;
  padding-bottom: 0 !important;
}
.tracelo-users-section {
  text-align: center;
}
.why-tracelo-section {
  text-align: center;
}
.how-to-use-section {
  text-align: center;
}
.homePage-main-section {
  .why-tracelo-section {
    .why-tracelo-top-contant {
      display: none;
      img {
        width: 100%;
      }
    }
    .why-tracelo {
      padding: 0 75px;
      .top-contant {
        width: 100%;
        display: none;
        img {
          width: 100%;
          height: auto;
        }
      }
      .df-first-section {
        display: flex;
        max-width: 1200px;
        width: 100%;
        align-items: center;
        margin: 0 auto;
        justify-content: space-between;
        .right-contant {
          width: 50%;
          img {
            width: 100%;
            height: auto;
          }
        }
        .left-contant {
          width: 50%;
          @media only screen and (max-width: 1024px) {
            width: 100%;
          }
          .why-tracelo-wrap {
            .df-why-tracelo {
              .sections {
                display: flex;
                gap: 15px;
                margin-bottom: 30px;
                .texts {
                  .section-title {
                    text-align: start;
                    p {
                      margin: 0;
                      margin-bottom: 0px;
                      font-weight: 700;
                      font-size: 20px;
                      line-height: 24px;
                    }
                  }
                  .section-desc {
                    text-align: start;
                    p {
                      margin: 0;
                      color: #777777;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .how-to-use-section {
    padding: 0 75px;
    .how-to-use {
      max-width: 1200px;
      margin: 0 auto;
      width: 100%;
      .how-to-use-title {
        text-align: center;
        margin-top: 50px;
        p {
          margin: 0;
          font-style: normal;
          font-weight: 700;
          font-size: 55px;
          line-height: 66px;
        }
      }
      .how-to-use-wrap {
        padding-top: 30px;
        padding-bottom: 50px;
        .how-to-use-top {
          display: none;
          width: 100%;
          img {
            width: 100%;
          }
        }
        .how-to-use-df {
          display: flex;
          align-items: center;
          .how-to-use-left {
            width: 50%;
          }
          .how-to-use-right {
            width: 50%;
            .df-why-tracelo {
              .sections {
                display: flex;
                align-items: center;
                gap: 15px;
                margin-bottom: 30px;
                .texts {
                  .section-title {
                    text-align: start;
                    p {
                      margin: 0;
                      margin-bottom: 0;
                      font-weight: 500;
                      font-size: 24px;
                      line-height: 36px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.tracelo-users-section {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  .tracelo-users-section-title {
    p {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 52px;
      line-height: 66px;
    }
  }
  .slider {
    margin: 30px 0;
    .slider-users {
      position: relative;
      width: 90% !important;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 154px;
        top: 8px;
        bottom: 0;
        background: #ecedef;
        z-index: -1;
        border-radius: 50px 0 0 50px;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 140px;
        bottom: 0;
        background: #ecedef;
        z-index: -1;
        border-radius: 0 0 50px 50px;
      }
      .bgboximg {
        position: absolute;
        width: 74px;
        height: 74px;
        right: 8px;
        top: 0;
        image-rendering: pixelated;
        border-radius: 50%;
        border: 8px solid #ecedef;
        object-fit: cover;
      }
      .box {
        position: relative;
        &:before {
          content: "";
          top: 8px;
          background: url(../../assets/track/review-shape.png);
          display: block;
          width: 155px;
          height: 137px;
          position: absolute;
          top: 8px;
          right: 0;
        }
        p {
          margin: 0;
        }
        .how-work-bg {
          position: absolute;
          width: 100%;
          z-index: 0;
        }
        .how-work-contant {
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: relative;
          .how-work-svg {
            margin-bottom: 15px;
          }
          .left-contant {
            width: calc(100% - 78px);
            padding-left: 40px;
            padding-top: 50px;
            padding-bottom: 40px;
            text-align: left;
            min-height: 170px;
            .how-work-box-title {
              font-style: normal;
              font-weight: 800;
              font-size: 20px;
              line-height: 29px;
            }
            .how-work-box-sub-title {
              margin-bottom: 12px;
              color: #777777;
            }
            .how-work-desc {
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 21px;
              color: #777777;
              span {
                color: blue;
              }
            }
          }
        }
      }
    }
    .slider-count {
      display: none;
      position: absolute;
      right: 115px;
      bottom: -50px;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .slick-slider {
    position: inherit;
    .slick-next {
      right: 80px;
      top: auto;
      bottom: -40px;
    }
    .slick-prev {
      right: 200px;
      top: auto;
      bottom: -40px;
      left: auto;
    }
  }
  .slick-slide {
    height: inherit !important;
    min-height: 280px;
  }
}

@media only screen and (max-width: 1295px) {
  .how-to-use-left {
    img {
      width: 500px;
    }
  }
}
@media only screen and (max-width: 1100px) {
  .how-to-use-left {
    img {
      width: 465px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .how-to-use-left {
    display: none;
    width: 0px !important;
  }
  .how-to-use-right {
    width: 100% !important;
  }
  .how-to-use-wrap {
    .how-to-use-top {
      display: block !important;
      margin-bottom: 30px;
    }
  }
  .how-work-contant {
    .left-contant {
      width: calc(90% - 78px) !important;
      padding-left: 60px !important;
    }
  }
  .slider-count {
    display: block !important;
  }
  .why-tracelo-top-contant {
    display: block !important;
    margin-bottom: 50px;
    img {
      width: 100%;
    }
  }
  .why-tracelo {
    .df-first-section {
      flex-wrap: wrap;
      .right-contant {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .how-work-contant {
    .left-contant {
      width: calc(95% - 78px) !important;
      padding-left: 45px !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .how-work-contant {
    .left-contant {
      width: calc(100% - 78px) !important;
      padding-left: 40px !important;
    }
  }
  .choose-tracelo-title {
    padding: 0 50px;
    p {
      font-size: 43px !important;
      font-weight: 600 !important;
      line-height: 63px !important;
    }
  }
  .tracelo-users-section-title {
    p {
      font-size: 43px !important;
      font-weight: 600 !important;
      line-height: 63px !important;
    }
  }
  .how-to-use-title {
    p {
      font-size: 43px !important;
      font-weight: 600 !important;
      line-height: 63px !important;
    }
  }
  .homePage-main-section .why-tracelo-section {
    .why-tracelo-top-contant {
      width: 80%;
      margin: 0 auto 50px;
    }
    .why-tracelo {
      padding: 0 20px;
      .df-first-section .left-contant {
        width: 100%;
      }
    }
  }
  .homePage-main-section .how-to-use-section {
    padding: 0 20px;
  }
  .homePage-main-section
    .how-to-use-section
    .how-to-use
    .how-to-use-wrap
    .how-to-use-top {
    max-width: 80%;
    margin: 0 auto 30px;
  }
  .homePage-main-section
    .how-to-use-section
    .how-to-use
    .how-to-use-wrap
    .how-to-use-df {
    max-width: 500px;
    margin: 0 auto;
    .how-to-use-right {
      .df-why-tracelo {
        .sections {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          .icons img {
            width: 75px;
            display: block;
          }
          .texts {
            .section-title {
              text-align: left !important;
              p {
                font-size: 19px;
                line-height: 1.3;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 680px) {
  .how-work-contant {
    .left-contant {
      width: 50% !important;
      margin: 0 auto;
      padding-left: 0 !important;
    }
  }
  .tracelo-users-section-title {
    p {
      font-size: 40px !important;
      font-weight: 600 !important;
      line-height: 60px !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .choose-tracelo-title {
    p {
      font-size: 35px !important;
      font-weight: 600 !important;
      line-height: 53px !important;
    }
  }
  .how-work-contant {
    .left-contant {
      width: 60% !important;
    }
  }
  .tracelo-users-section-title {
    p {
      font-size: 35px !important;
      font-weight: 600 !important;
      line-height: 53px !important;
    }
  }
  .how-to-use-title {
    p {
      font-size: 35px !important;
      font-weight: 600 !important;
      line-height: 1.2 !important;
    }
  }
}
@media only screen and (max-width: 500px) {
  .how-work-contant {
    .left-contant {
      width: 77% !important;
      padding-left: 25px !important;
    }
  }
  .why-tracelo {
    .df-why-tracelo {
      .sections {
        flex-direction: column;
        align-items: center;

        .texts {
          .section-title {
            text-align: center !important;
          }
          .section-desc {
            text-align: center !important;
          }
        }
      }
    }
  }
}

.tracelo-users-section {
  .slider {
    .slider-users {
      .box {
        .how-work-contant {
          .left-contant {
            padding: 50px 90px 30px 20px;
            @media only screen and (max-width: 767px) {
            }
            .how-work-box-title {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .right-contant {
          .banner-img {
            .location-banner-img-text {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .tracelo-users-section {
    .slider {
      .slider-users {
        width: 100% !important;
        .box {
          .how-work-contant {
            .left-contant {
              padding: 50px 40px 30px 15px !important;
            }
          }
          &:before {
            width: 135px;
            height: 117px;
            background-size: cover;
          }
        }
        .bgboximg {
          width: 54px;
          height: 54px;
          border: 4px solid #ecedef;
        }
        &:before {
          right: 125px;
        }
        &:after {
          top: 115px;
        }
      }
    }
  }
}
