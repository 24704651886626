.mt-50 {
  margin-top: 50px;
}
.mt-20 {
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .mt-50 {
    margin-top: 30px;
  }
}

.dashboard__title {
  h2 {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    color: #000000;
    margin: 0px 0px 25px;
  }
  .wpb_text_content {
    margin-bottom: 10px;
  }
}

// Start a New Search Css
.white-bg-wrap {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 20px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.location_search {
  form {
    .location_form_field {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin-bottom: 5px;
    }
    .form-control {
      padding: 12px 15px 12px 50px;
      border-radius: 10px 0px 0px 10px;
      border: 1px solid rgba(36, 83, 123, 0.5);
      color: #000000;
      background-color: #ffffff;
      font-size: 17px;
      font-weight: 400;
      line-height: 1.1;
      outline: none;
      height: 100%;
      min-height: 60px;
      &::placeholder {
        color: rgba(119, 119, 119, 0.3);
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .click-here {
      display: flex;
      padding: 0px 0px;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      bottom: auto;
      right: 15px;
      left: auto;
      transform: translateY(-50%);
      background-color: transparent;
      outline: none;
      border: 1px solid #24537b;
      width: 40px;
      height: 40px;
      cursor: pointer;
      border-radius: 50%;
      transition: all 0.4s ease-in-out;
      &:hover svg {
        fill: #ffffff;
      }
      > svg {
        fill: #24537b;
        transition: all 0.4s ease-in-out;
      }

      &:hover {
        background-color: #24537b;
        border: 1px solid #24537b;

        i {
          color: #ffffff;
        }
      }
    }
  }
  label {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    color: #777777;
  }
}

.location_form_field {
  position: relative;

  input {
    height: 50px;
    width: 100%;
    border-radius: 15px;
  }
  .input-form {
    padding-left: 15px !important;
    position: absolute;
    width: calc(100% - 90px);
    right: 0;
    top: 0;
    border-radius: 0px 10px 10px 0px !important;
  }

  .flag-dropdown {
    border-radius: 15px 0 0 15px;
  }

  .selected-flag {
    padding: 0 40px 0 20px;
    border-radius: 15px 0 0 15px;
  }
}

@media only screen and (max-width: 525px) {
  .location_search {
    form {
      .form-control {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 435px) {
  .location_search {
    form {
      .click-here {
        transform: unset;
        position: unset;
        width: 100%;
        border-radius: 10px;
        margin-top: 10px;
        justify-content: center;
      }
    }
  }
}

@media only screen and (min-width: 436px) {
  .location_search {
    form {
      .click-here {
        span {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 435px) {
  .location_search {
    form {
      .click-here {
        svg {
          font-size: 22px;
        }
        span {
          font-size: 18px;
          color: #24537b;
          font-weight: 400;
        }
      }
    }
    label {
      display: none;
    }
  }
}

// Report Section Css

.wpb_box_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 15px 15px;
  border: 1px dashed #24537b;
  background: #ffffff;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  .vc_general_text {
    strong,
    span {
      display: block;
      vertical-align: top;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.3;
      color: #000000;
      margin-bottom: 5px;
    }
    span {
      font-size: 14px;
      color: #000000;
      font-weight: 400;
    }
  }
  .vc_general_tel {
    p {
      a {
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.1;
        color: #000000;
      }
    }
    .mobile_number {
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.1;
      color: #000000;
      direction: ltr;
    }
  }
  .vc_general_status {
    span {
      display: flex;
      align-items: center;
    }

    img {
      width: 100%;
      max-width: 13px;
      margin-left: 25px;
      animation: spin 4s infinite linear;
    }
  }
  .vc_general_btn {
    p {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin: 0px 0px;
      a {
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 4px;
        background-color: #ecf7fb;
        border: 1px solid #ecf7fb;
        padding: 6px 12px 6px 20px;
        column-gap: 14px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1;
        color: #000000;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1024px) {
  .wpb_box_wrap {
    .vc_general_tel {
      p {
        a {
          font-size: 16px;
        }
      }
    }
    .vc_general_text {
      strong,
      span {
        font-size: 16px;
      }
    }
    .vc_general_status {
      img {
        margin-left: 15px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .wpb_box_wrap {
    .vc_general_tel {
      p {
        a {
          font-size: 14px;
        }
      }
    }
    .vc_general_text {
      strong,
      span {
        font-size: 14px;
      }
    }
    flex-wrap: wrap;
    row-gap: 15px;
    > * {
      &:not(:last-child) {
        max-width: 50%;
        flex: 0 0 50%;
      }
      &:last-child {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
    .vc_general_btn {
      p {
        a {
          justify-content: center;
        }
      }
    }
  }
}

.location_form_field {
  .react-tel-input {
    width: 90px;
    input.form-control {
      &::placeholder {
        color: rgba(119, 119, 119, 0.3);
      }
    }
    .flag-dropdown {
      background-color: transparent;
      border-radius: 10px 0px 0px 10px !important;
      .selected-flag {
        width: 90px;
        padding: 0px 20px;
        &:hover {
          background-color: transparent;
        }
      }
    }
    .flag-dropdown.open {
      background: transparent !important;
    }
  }
}

.located {
  color: rgb(5, 85, 20) !important;
  img {
    opacity: 0;
  }
}

.wpb_report_wrapper {
  .wpb_text_content {
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 435px) {
  .location_search {
    form {
      .form-control {
        min-height: 50px;
        height: 50px;
      }
      .form_input {
        width: 92px;
      }
      .click-here {
        height: 45px;
      }
    }
  }
}
