#sign-up-term {
  margin-top: 12px;
  line-height: 1.5;
  font-size: 12px;
  display: inline-block;
  color: #878787;
}

.ant-modal-mask {
  backdrop-filter: blur(5px);
}

.ant-modal .ant-modal-content {
  padding: 5px 10px;
}

.login-modal-section {
  .signup-header {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    height: 80px;
    left: 0;
    backdrop-filter: blur(10px);
  }

  .login-modal-title {
    text-align: center;
    font-size: 29px;
    line-height: 1.3;
    color: white;
    margin: 0 auto;
    padding: 5px 40px;
    background-color: #1664fa;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .number {
      font-size: 22px;
      display: inline-block;
      // white-space: pre;
      width: max-content;
    }

    @media only screen and (max-width: 576px) {
      font-size: 23px;
      padding: 5px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      min-height: 40px;
    }
  }

  .wrap-top-modal {
    margin-top: 20px;

    .body-modal {
      padding: 10px 10px;
      padding-bottom: 0;

      .body-modal-ceate-account {
        font-size: 1.25rem;
        text-align: center;
        margin: 20px 0px;
        font-weight: 500;
        line-height: 1.2;
        color: #1664FA !important;

        p {
          margin: 0;
        }
      }

      .payment-details {
        .df-details {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left-payment {
            p {
              margin: 0;
              font-size: 30px;
              color: #4096ff;
            }
          }

          .right-payment {
            text-align: right;
            line-height: 1;

            p {
              color: #4096ff;
              font-size: 24px;
              margin: 0;
            }

            span {
              font-size: 12.8px;
            }
          }
        }

        .time-span {
          text-align: right;
          line-height: 0.5;
        }
      }

      .mt-4 {
        margin-top: 30px;
      }

      .modal-card-form {
        position: relative;
        margin-bottom: 10px;

        .border {
          border: 1px solid#d9d9d9;
          padding: 10px 5px;
          border-radius: 5px;
        }
      }

      .modal-login-form {
        position: relative;
        margin-bottom: 10px;

        input {
          height: 49px;
        }

        .term-checkbox {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          margin-top: 20px;

          input {
            -webkit-appearance: none;
            height: 18px;
            width: 18px;
            opacity: 1;
            position: relative;
            border: 0;
            background: transparent;
            appearance: none;
            flex: 0 0 18px;
            align-self: center;
            margin: 0;
            outline: none;
            box-shadow: none;
            color: #d1d1d7;

            &:checked {
              color: #1664fa;
            }

            &:before {
              content: "";
              position: absolute;
              border: 2px solid currentColor;
              border-radius: 0.25rem;
              z-index: 1;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }

            &:after {
              position: absolute;
              content: "";
              background-color: currentColor;
              border-radius: 3px;
              top: 4px;
              left: 4px;
              right: 4px;
              bottom: 4px;
              z-index: 2;
              opacity: 0;
            }

            &:checked::after {
              opacity: 1;
            }
          }
        }

        .icon-container {
          font-size: 25px;
          display: flex;
          gap: 5px;
          position: absolute;
          bottom: 7px;
          right: 7px;
        }
      }

      .exp-cvv-modal {
        display: flex;
        gap: 20px;
        padding-bottom: 10px;

        .df-left,
        .df-right {
          width: 50%;

          .border {
            border: 1px solid #d9d9d9;
            padding: 10px 5px;
            border-radius: 5px;
          }
        }

        .ant-form-item {
          margin-bottom: 10px;
        }

        .ant-picker {
          width: 100%;
          height: 40px;
        }

        input {
          width: 100%;
          height: 40px;
        }
      }

      .forget-pass-modal {
        a {
          color: #858585;
        }
      }

      .postalCode-div {
        input {
          border: 1px solid #d9d9d9;
          padding: 8px 5px;
          border-radius: 5px;
        }
      }

      .submit-btn-modal {
        width: 100%;
        margin-top: 20px;
        @media screen and (max-width: 425px){
          margin-top: 13px !important;
        }
        .on-mobile {
          display: none;
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 49px;
          padding: 12px 20px;
          border: 1px solid currentColor;
          border-radius: 7px;
          transition: 0.5s ease-in-out;
          z-index: 1;
          background-color: #1664fa !important;
          color: white;
          font-weight: 700;
          font-size: 20px;
          cursor: pointer;
          letter-spacing: 0px;
          @media screen and (max-width: 425px) {
            display: block !important;
            font-weight: 500;
            font-size: 18px !important;
          }
        }

        .on-desk {
          @media screen and (max-width: 425px) {
            display: none !important;
          }
        }

        button {
          box-shadow: none !important;
        }

        .hl_cta_wrap {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 49px;
          padding: 12px 20px;
          border: 1px solid #24537b;
          border-radius: 7px;
          transition: 0.5s ease-in-out;
          z-index: 1;
          background-color: transparent !important;
          color: #24537b;
          font-weight: 700;
          font-size: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: #fff !important;
          }

          @media only screen and (max-width: 426px) {
            font-size: 17px;
          }
        }
      }

      select {
        -webkit-appearance: none;
        width: 100%;
        border: 1px solid #d9d9d9;
        padding: 10px 5px;
        background-color: #ffff;
        border-radius: 5px;
        font-size: 16px;
        color: black;

        &:focus {
          outline: none;
        }

        option {
          -webkit-appearance: none;
          background-color: #ffff;
          border-radius: 1vh;
          border-color: transparent;
        }
      }
    }
  }
}

.ant-select {
  width: 100% !important;

  .ant-select-selector {
    width: 100% !important;
    height: 40px !important;

    input {
      width: 100% !important;
      height: 40px !important;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
}

.payment-inputs:focus {
  border-color: #0573e180 !important;
  outline: none;
}

.ant-form-item {
  margin-bottom: 0;
}

.label-requred-star {
  color: red;
}

.errorFelid {
  border-color: red !important;
}

.payment-option-tabs {
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;

    .active-tab {
      border: 2px solid #6ac64c !important;
      position: relative;

      .rightIcon-div {
        position: absolute;
        top: -8px;
        left: -8px;

        img {
          height: 20px;
        }
      }
    }

    .card-payment-tab {
      border: 2px solid rgb(230, 230, 230);
      border-radius: 5px;
      height: 100%;
      padding: 10px;
      width: 100%;
      min-height: 47px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;

      .df-card-box {
        display: flex;
        align-items: flex-start;
        gap: 3px;
        flex-direction: column;

        svg {
          font-size: 21px;
        }

        p {
          margin: 0;
        }
      }
    }

    .gpay-payment-tab {
      border: 2px solid rgb(230, 230, 230);
      border-radius: 5px;
      height: 100%;
      padding: 10px;
      width: 100%;
      min-height: 47px;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .df-card-box {
        display: flex;
        align-items: flex-start;
        gap: 3px;
        flex-direction: column;

        img {
          width: 40px;
        }

        p {
          margin: 0;
        }
      }
    }

    .applePay-payment-tab {
      border: 2px solid rgb(230, 230, 230);
      border-radius: 5px;
      height: 100%;
      padding: 10px;
      width: 100%;
      min-height: 47px;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .df-card-box {
        display: flex;
        align-items: flex-start;
        gap: 3px;
        flex-direction: column;

        img {
          width: 40px;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

.google-pay-content {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  padding: 15px;

  .top-content {
    border-bottom: 1px solid rgb(230, 230, 230);

    .icon {
      img {
        width: 70px;
      }
    }

    p {
      margin: 5px;
      font-size: 20px;
    }
  }

  .bottom-content {
    padding: 0 5px;

    .df-icon-secure {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 14px;

      .icon {
        img {
          width: 40px;
        }
      }

      .content {
        p {
          font-size: 13px;
          line-height: 1.4;
          margin: 0;
          font-weight: 400;
          color: rgb(175 174 174);
          font-family: revert;
          font-family: Raleway;
        }
      }
    }
  }
}

.gpay-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  .google-pay-button-container {
    width: 100%;
  }

  .applePayButton {
    width: 100%;
    border: 1px solid #000;
    border-radius: 4px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 1px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    height: 40px;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: #f8f8f8;
    }
  }

  button {
    width: 100%;
    min-width: 300px !important;
    border: 1px solid #000;
  }
}

.checkout-form-container {
  display: flex;
  flex-direction: column;
  gap: 10;
}

.chekout-modal {
  .ant-modal-content {
    overflow: hidden;
  }
}

#spreedly-number {
  position: relative;

  .suffix-card-icons {
    width: 150px;
    position: absolute;
    right: 0.7em;
    top: 0.7em;

    @media screen and (max-width: "475px") {
      width: 100px;
    }
  }
  .suffix-card-icons-rtl {
    width: 150px;
    position: absolute;
    left: 0.7em;
    right: auto;
    margin-top: 10px;
  }
}

.spf-field-group {
  height: 45px;
}

.common-error {
  color: red;
  margin: 0px;
  line-height: 1.2;
}

.spf-month,
.spf-year {
  input {
    height: 38px;
    border: 0 !important;
    outline: none;

    &::placeholder {
      text-transform: uppercase;
      color: #cfcfcf;
    }
  }
}

.spf-exp-divider {
  padding-right: 5px;
  color: #cfcfcf;
}

#cvv_label {
  display: none;
}

.login-info-wrap {
  .login-modal-section {
    .login-modal-title {
      font-size: 25px !important;
      padding: 10px 15px;
      width: 100%;
      max-width: 250px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .login-info-wrap {
    .login-modal-section {
      .login-modal-title {
        max-width: max-content;
        font-size: 18px !important;
        min-height: 25px;
      }
    }
  }
  .ant-modal.login-info-wrap {
    max-width: calc(100vw - 15px);
    .ant-modal-content {
      padding: 0px 10px 25px !important;
    }
  }
}

// Locating modal Css

.login-modal-section {
  .wrap-top-modal {
    .body-modal {
      .payment-details {
        .right-payment.pay {
          text-align: right;
        }
        .df-details {
          .left-payment {
            p {
              font-size: 25px !important;
            }
          }
          .right-payment {
            p {
              font-size: 25px !important;
            }
          }
        }
      }
    }
    .body-modal.mb-top-wrapper {
      margin-top: 30px !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .login-modal-section {
    .wrap-top-modal {
      .body-modal {
        .payment-details {
          .df-details {
            .left-payment {
              p {
                font-size: 22px !important;
              }
            }
            .right-payment {
              p {
                font-size: 22px !important;
              }
            }
          }
          .right-payment.pay {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.locating-login-modal {
	.term-checkbox {
		margin-top: 20px !important;
		text-align: center;
    @media screen and (max-width: 768px){
      margin: 15px 0px;
    }
		span {
			color: rgba(0, 0, 0, 0.6) !important;
		}
	}
}

@media screen and (max-width: 576px) {
	.ant-modal.signup__locating_wrap {
		max-width: calc(100vw - 15px);
	}
  .login-modal-section {
		.wrap-top-modal {
			.body-modal {
				padding: 20px 0px 0px !important;
			}
		}
	}
}

@media screen and (max-width: 576px) {
  .signup__locating_wrap {
    width: 415px !important;
}
}

@media screen and (min-width: 475px) {
	.login-info-wrap {
		.wrap-top-modal {
			padding: 0px 20px;
		}
	}
}

.login-info-wrap .login-modal-section .wrap-top-modal .body-modal {
  margin-top: 10px !important;
  padding-bottom: 25px !important;
  @media screen and (max-width: 576px){
    padding-bottom: 0px !important;
  }
}

.login-modal-section {
	.wrap-top-modal {
		.body-modal {
			.modal-login-form {
				input {
					&:hover {
						border-color: #24537b;
					}
					&:focus {
						border-color: #24537b;
					}
				}
			}
		}
	}
}


.reverslyHomeBannerInput {
	.react-tel-input {
		.flag-dropdown {
			.selected-flag {
				width: 80px;
				cursor: pointer;
			}
		}
	}
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
	.theme_dir__rtl {
		.reverslyHomeBannerInput {
			.wpb_content_input {
				width: 480px;
			}
		}
	}
}



.theme_dir__rtl {
	header.header_wrap {
		.main_container_header {
			.header_wrap_area {
				.header_functionality {
					.search-animation-set {
						input[type=text] {
							direction: ltr;
							text-align: right;
						}
					}
				}
			}
		}
	}
	.mobile-header {
		.header_mobile_number_input {
			direction: ltr;
			text-align: right;
		}
	}
}
