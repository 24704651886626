.ant-modal-mask {
  backdrop-filter: blur(5px);
}

.login-modal-section {
  .login-modal-title {
    text-align: center;
    font-size: 30px;
    color: white;
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0 auto;
    transform: translateX(-50%);
    padding: 5px 40px;
    background-color: #4096ff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .wrap-top-modal {
    .google-button-login {
      padding: 0 10px;
      .login-with-google-btn {
        transition: background-color 0.3s, box-shadow 0.3s;
        padding: 12px 16px 12px 42px;
        border: 1px solid #d1d1d7;
        border-radius: 3px;
        color: #777777;
        font-size: 17px;
        font-weight: 400;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
        background-color: white;
        background-repeat: no-repeat;
        background-position: 12px 11px;
        width: 100%;
        cursor: pointer;
      }

      body {
        text-align: center;
        padding-top: 2rem;
      }
    }

    .body-modal {
      margin-top: 40px;
      padding: 25px;

      .modal-login-form {
        .mb {
          margin-bottom: 15px;
        }

        .ant-form-item {
          margin-bottom: 0;

          .ant-col {
            padding-bottom: 0;
          }
        }
      }

      .forget-pass-modal {
        text-align: center;
        a {
          color: #858585;
        }
      }

      .submit-btn-modal {
        width: 100%;
        margin-top: 30px;

        .hl_cta_wrap {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 100%;
          padding: 12px 20px;
          border: 1px solid currentColor;
          border-radius: 14px;
          transition: 0.5s ease-in-out;
          z-index: 1;
          background-color: transparent;
          color: #1664fa;
          font-weight: 700;
          font-size: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: #fff !important;
          }
        }

        @media only screen and (max-width: 426px) {
          font-size: 17px;
        }
      }
    }
  }
}
.auth-divider.login-auth-divider {
  margin-top: 36px !important;
}

// Login RTL Css

.login-info-wrap {
  .ant-modal-footer {
    display: none;
  }
}

.login__locating_wrap {
  .wrap-top-modal {
    .google-button-login {
      width: 100%;
      max-width: 380px;
      margin: 0px auto;
      padding: 0px 0px;
      @media only screen and (max-width: 474px) {
        max-width: 100%;
        padding: 0px 0px;
      }
      .login-with-google-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        background-position: 15px center;
        padding: 14px 15px;
        border: 1px solid rgba(209, 209, 215, 0.6);
      }
      .login-with-apple-btn {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M11.6305 2.73564C12.3078 2.01055 12.7659 1.00386 12.6405 0C11.6647 0.0348222 10.4821 0.572776 9.78175 1.29781C9.1539 1.93967 8.60421 2.96551 8.75091 3.94967C9.83972 4.02332 10.9521 3.45956 11.6305 2.73564ZM13.7085 9.66829C13.6877 7.41775 15.4903 6.33684 15.5701 6.28461C14.5567 4.75632 12.9784 4.5472 12.4163 4.52362C11.0724 4.38254 9.79589 5.33749 9.11358 5.33749C8.43405 5.33749 7.38297 4.54214 6.26835 4.5635C4.80303 4.58764 3.45287 5.44091 2.7003 6.79323C1.17813 9.51375 2.31016 13.5438 3.79344 15.7505C4.51853 16.8308 5.38186 18.0427 6.51556 17.9988C7.60993 17.955 8.02248 17.271 9.34283 17.271C10.6637 17.271 11.0336 17.9988 12.1892 17.9769C13.365 17.955 14.1092 16.8758 14.8281 15.7921C15.66 14.5393 16.0029 13.3263 16.0225 13.265C15.9973 13.2504 13.7326 12.3595 13.7085 9.66829Z' fill='white'/%3E%3C/svg%3E");
        background-color: #000000;
        color: #ffffff;
        border: 1px solid #000000;
        margin: 10px 0px;
      }
      .login-with-facebook-btn {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cg clip-path='url(%23clip0_3308_946)'%3E%3Cpath d='M18 9C18 13.4923 14.7087 17.2157 10.4062 17.8907V11.6016H12.5033L12.9023 9H10.4062V7.3118C10.4062 6.59988 10.755 5.90625 11.873 5.90625H13.0078V3.69141C13.0078 3.69141 11.9777 3.51562 10.993 3.51562C8.93742 3.51562 7.59375 4.76156 7.59375 7.01719V9H5.30859V11.6016H7.59375V17.8907C3.29133 17.2157 0 13.4923 0 9C0 4.02961 4.02961 0 9 0C13.9704 0 18 4.02961 18 9Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3308_946'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-color: #4096ff;
        color: #ffffff;
        border: 1px solid #4096ff;
      }
      box-sizing: border-box;
    }
    .auth-divider {
      span.or-text {
        color: rgba(0, 0, 0, 0.65);
        font-size: 13.6px;
        font-weight: 400;
        letter-spacing: 0.2px;
        font-family: "GT Walsheim Pro";
        @media only screen and (max-width: 475px) {
          margin: 0px 10px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .login-info-wrap {
    width: 415px !important;
  }
  .auth-divider.login-auth-divider {
    margin-top: 1rem !important;
  }
}

@media only screen and (max-width: 474px) {
  .login-modal-section {
    .wrap-top-modal {
      .body-modal {
        padding: 0px 0px;
      }
    }
  }
}

@media screen and (max-width: 475px) {
	.login-info-wrap {
		.wrap-top-modal {
			.google-button-login {
				.login-with-google-btn {
					font-size: 16px;
					padding: 12px 15px;
				}
			}
		}
	}
}
