.account_bg_wrap {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 30px 30px;
  margin-bottom: 30px;
}

.account_title {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  h2 {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
    border-bottom: 1px solid rgb(217 217 217 / 70%);
    margin: 0px 0px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.wpb_content_account {
  .account_info_cmt {
    display: flex;
  }
  .account_info_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .wi-50 {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }
  .account_address {
    strong {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.1;
      color: #444444;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    p {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin: 0px 0px;
      a {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 150px;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1;
        color: #596171;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .account_title {
    h2 {
      font-size: 24px;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }
  .wpb_content_account {
    .account_address {
      p {
        a {
          column-gap: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .account_bg_wrap {
    padding: 15px 15px;
  }
  .account_title {
    h2 {
      font-size: 20px;
    }
  }
  .wpb_content_account {
    .account_info_inner {
      flex-wrap: nowrap;
      justify-content: space-between;
      .wi-50 {
        max-width: max-content;
        flex: 0 0 max-content;
      }
    }
    .account_address {
      strong {
        width: auto;
      }
      p {
        width: auto;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .wpb_content_account {
    .account_info_inner {
      flex-wrap: wrap;
      row-gap: 20px;
      .wi-50 {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .account_address {
        &:first-child {
          border-bottom: 1px solid rgb(119 119 119 / 10%);
          padding-bottom: 20px;
        }
      }
    }
  }
}

// Billing Information Css

.account-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 30px;

  .field-account.address {
    grid-column: 1 / 3;
  }

  label.lbl_wpb_wrapper {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    color: #777777;
    margin-bottom: 10px;
  }

  .field-account {
    input[type="text"],
    input[type="number"],
    select {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #dfeaee;
      background: rgba(236, 247, 251, 0.5);
      padding: 16px 13px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1;
      outline: none;
      color: #000;
      appearance: none;
      -webkit-appearance: none;

      &::placeholder {
        color: #9ea8bd;
      }
    }

    input[type="number"] {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0px 0px;
      }
    }
  }
}

button.payment__account {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  padding: 10px 15px;
  color: #ffffff;
  background-color: #24537b;
  border: 1px solid #24537b;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover {
    background-color: transparent;
    border: 1px solid #24537b;
    color: #24537b;
  }
}

@media only screen and (max-width: 625px) {
  .account-container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 15px;
    .field-account.address {
      grid-column: 1 / 1;
    }
    .field-account {
      input[type="text"],
      input[type="number"] {
        padding: 13px 13px;
        font-size: 14px;
      }
    }
  }
}

// Subscriptions Css

.subscriptions_table {
  table {
    width: 100%;
    border-collapse: collapse;

    tbody {
      td {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1;
        color: #444444;
        padding: 7px 7px;
        text-align: left;

        &::before {
          content: attr(data-title);
          font-size: 0px;
          font-style: normal;
          font-weight: 600;
          line-height: 1.1;
          color: #333333;
        }

        .tracking_btn {
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.5px;
          color: #ffffff;
          border-radius: 3px;
          background-color: #24537b;
          border: 1px solid #24537b;
          padding: 5px 6px;
          outline: none;
          box-sizing: unset;
          margin-left: 8px;
        }

        img {
          width: 100%;
          max-width: 20px;
          height: 100%;
          max-height: 20px;
          display: inline-block;
          vertical-align: middle;
        }

        .cancel_btn {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.1;
          color: #24537b;
          background-color: transparent;
          outline: none;
          box-shadow: unset;
          border: 0px;
          padding: 0px 0px;
          text-align: left;
          cursor: pointer;
        }
      }
      td.cancel_subscription {
        text-align: right;
      }
    }

    thead {
      tr {
        th {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.1;
          color: #333333;
          text-align: left;
          padding: 7px 7px;
        }

        border-bottom: 1px solid rgb(217 217 217 / 50%);
      }
    }
  }
}
.theme_dir__rtl {
  .subscriptions_table {
    table {
      tbody,
      thead {
        td,
        th {
          text-align: right;
          .tracking_btn {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .subscriptions_table {
    table {
      tbody {
        tr {
          width: 100%;
          padding: 15px 15px;
          display: block;
          border-radius: 5px;
          border: 1px dashed #24537b;
          background: rgba(236, 247, 251, 0.3);
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          td {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(1, 33% 68%);
            align-items: center;

            &::before {
              font-size: 14px;
            }

            &:first-child {
              display: none;
            }
            &:not(:last-child) {
              border-bottom: 1px solid rgb(217 217 217 / 50%);
            }
          }
        }
      }
      thead {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 435px) {
  .subscriptions_table {
    table {
      tbody {
        tr {
          padding: 5px 5px;
          td {
            align-items: unset;
            grid-template-columns: repeat(1, 40% 60%);
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .subscriptions_table {
    table {
      tbody {
        tr {
          &:not(:last-child) {
            border-bottom: 1px solid rgba(217, 217, 217, 0.5);
          }
        }
      }
    }
  }
}

.error-message {
  color: red;
  margin-top: 2px;
}

.required-field {
  color: red;
}

@media only screen and (max-width: 475px) {
  .subscriptions_table {
    table {
      tbody {
        tr {
          td {
            .tracking_btn {
              margin-left: 0px;
              display: block;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}

// Theme Color Scss

/**************Modal 3**************/
.ant-modal.tracelo_unsubscribe-modal {
  width: 100% !important;
  max-width: calc(595px - 30px) !important;

  @media screen and (max-width: 575px) {
    max-width: calc(100vw - 20px) !important;
  }
}

.tracelo_unsubscribe-modal {
  .ant-modal-content {
    padding: 0px 0px !important;
  }
}

.tracelo_unsubscribe-modal {
  .login-modal-section {
    .unsubscribe_title {
      h2 {
        font-size: 24px;
        line-height: 1.1;
        margin: 0px 0px;
        margin-bottom: 15px;
        color: #000000;
        text-align: center;
        @media screen and (max-width: 575px) {
          font-size: 20px;
        }
      }
    }
    .unsubscribe-modal-body {
      .submit-btn-modal {
        button {
          background-color: #24537b !important;
          box-shadow: none;
        }
      }
      .back-btn-modal {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-top: 20px;
        button {
          background-color: transparent;
          outline: unset;
          border: unset;
          width: 100%;
          display: inline-block;
          vertical-align: top;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: #777777;
          text-transform: capitalize;
          padding: 0px 0px;
          cursor: pointer;
        }
      }
    }
  }
}

.wpb_content_billing {
  .form_group_warp {
    form {
      .account-container {
        .field-account {
          select.ant-input-prefix {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            padding-right: 13px !important;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.122' height='3.561' viewBox='0 0 6.122 3.561'%3E%3Cpath d='m.707.707 2.354 2.354L5.415.707' fill='none' stroke='%2365676b' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-position: 97% center;
            background-repeat: no-repeat;
            background-size: 12px 7px;
          }
        }
      }
    }
  }
}

div.ant-input-prefix {
  height: 50.5px;
  @media screen and (max-width: 625px) {
    height: 43.4px !important;
  }
  .ant-select-selector {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dfeaee !important;
    background: rgba(236, 247, 251, 0.5) !important;
    padding: 16px 13px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    outline: none;
    color: #000;
    appearance: none;
    -webkit-appearance: none;
    height: 50.5px !important;
    @media screen and (max-width: 625px) {
      height: 43.4px !important;
    }
    input {
      height: 50.5px !important;
      @media screen and (max-width: 625px) {
        height: 43.4px !important;
      }
    }
  }
}

// Unsubscribe Modal Scss
.tracelo_unsubscribe-modal {
  .login-modal-section {
    margin: 18px 0px;
    @media screen and (max-width: 576px) {
      margin: 18px 5px;
    }
  }
}
