.wpb_info_wrapper {
	.mb-1 {
		&:last-of-type {
			margin-bottom: 0px;
		}
	}
}
ul.policy__item {
	li {
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
		color: #68686B;
		margin-bottom: 10px;
		position: relative;
		padding-left: 16px;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 8px;
			border-radius: 100%;
			width: 5px;
			height: 5px;
			background: #68686B;
		}
		&:last-of-type {
			margin-bottom: 0px;
		}
        a{
            color: #24537B;
        }
	}
}
@media only screen and (max-width: 575px) {
	ul.policy__item {
		padding: 10px;
	}
}

.text__uppercase {
    text-transform: uppercase;
}