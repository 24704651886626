.login-modal-section {
  .login-modal-title {
    .span {
      font-size: 15px;
      opacity: 0.75;
      color: #fff;
    }

    p {
      font-size: 20px;
      margin: 0;
      white-space: nowrap;
    }
  }

  .wrap-top-modal {
    .body-dash-modal {
      padding: 50px 25px 0;

      @media only screen and (max-width: 767px) {
        padding: 50px 0px 0;
      }

      .dashboard-find-number {
        label {
          display: flex;
          align-items: center;
          margin-bottom: 1rem !important;
          text-transform: capitalize;
          font-size: 0.85rem;
          line-height: 1.3;
          letter-spacing: 1px;

          @media screen and (max-width: 767px) {
            letter-spacing: 0.5px;
          }

          .dot {
            display: inline-block;
            background-color: #24537b;
            width: 8px;
            height: 8px;
            flex: 0 0 8px;
            border-radius: 100%;
          }

          span:last-child {
            margin-left: 1rem;
            font-weight: 500;
            color: #868686;

            @media screen and (max-width: 767px) {
              margin-left: 0.6rem;
            }
          }
        }

        .dashboard-locate {
          position: relative;
          .dsh_locating_popup {
            .form-control {
              padding-left: 50px;
              width: 100px;
              border-radius: 15px 0 0 15px;
            }
            .flag-dropdown {
              background-color: transparent;
              width: 90px;
            }
          }
          .form_input_phone_number {
            position: absolute;
            top: 0;
            right: 0;
            width: calc(100% - 89px);
            padding-left: 15px;
            border-radius: 0 15px 15px 0;
          }
          input {
            height: 50px;
            width: 100%;
            padding-left: 75px;
            border-radius: 0 15px 15px 0;
          }

          .flag-dropdown {
            border-radius: 15px 0 0 15px;
          }

          .selected-flag {
            padding: 0 40px 0 20px;
            border-radius: 15px 0 0 15px;
          }
        }
      }

      .dashboard-message {
        label {
          display: flex;
          align-items: center;
          margin-top: 1rem !important;
          margin-bottom: 1rem !important;
          text-transform: capitalize;
          font-size: 0.85rem;
          line-height: 1.3;
          letter-spacing: 1px;

          @media screen and (max-width: 767px) {
            letter-spacing: 0.5px;
          }

          .dot {
            display: inline-block;
            background-color: #24537b;
            width: 8px;
            height: 8px;
            border-radius: 100%;
          }

          span:last-child {
            margin-left: 1rem;
            font-weight: 500;
            color: #868686;

            @media screen and (max-width: 767px) {
              margin-left: 0.6rem;
            }
          }
        }

        textarea {
          height: 70px;
          width: 100%;
          max-width: calc(100% - 50px);
          border-radius: 15px;
          padding: 0.75rem 1.5rem;
          overflow: auto;
          resize: vertical;
          line-height: 1.3;
          font-size: 16px;
        }
      }
      .phone-report-send-message {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 13px;
      }
      .submit-btn-modal {
        width: 100%;
        margin-top: 30px;

        button {
          width: 100%;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          padding: 0.75rem 1.5rem;
          border: none;
          border-radius: 1.5rem;
          text-transform: capitalize;
          font-size: 0.85rem;
          line-height: 1.3;
          letter-spacing: 1px;
          color: #fff;
          box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15),
            0 2px 30px rgba(118, 145, 255, 0.4);
          display: flex;
          width: 100%;
          background-color: #24537b !important;
          cursor: pointer;
        }
      }
    }
  }
}
.send_message_Modal {
  width: 480px;
}
.login-modal-section {
  padding: 10px 0px !important;
}

// Locating Popup

@media only screen and (max-width: 600px) {
  .locating_popup__wrap {
    width: calc(100% - 30px) !important;
    max-width: 100%;
    .send_message_Modal {
      width: auto;
    }
  }
}

@media only screen and (max-width: 767px) {
  .track_modal_locating {
    .login-modal-section {
      .wrap-top-modal {
        .body-dash-modal {
          padding: 50px 25px 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .track_modal_locating {
    .login-modal-section {
      .wrap-top-modal {
        .body-dash-modal {
          padding: 50px 15px 0;
        }
      }
    }
  }
}
