.contact_wrap {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 100px 0px;
  .contact__inner_wrap {
    display: flex;
    align-items: center;
    .contact_sec_content {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 0px 15px;
      h2 {
        font-size: 54px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #000000;
        margin: 0px 0px 30px;
      }
      span {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #868686;
        margin: 0px 0px 30px;
        a {
          color: #868686;
        }
      }
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;
        margin: 0px 0px;
        color: #868686;
        a {
          color: #24537b;
          text-decoration: underline;
        }
      }
    }
    .contact_sec_img {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 0px 15px;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .contact_wrap {
    padding: 70px 0px;
    .contact__inner_wrap {
      .contact_sec_content {
        h2 {
          font-size: 40px;
          margin: 0px 0px 15px;
        }
        span {
          font-size: 20px;
          margin: 0px 0px 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .contact_wrap {
    padding: 40px 0px;
    .contact__inner_wrap {
      flex-wrap: wrap;
      .contact_sec_content {
        text-align: center;
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 30px;
      }
      .contact_sec_img {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .contact_wrap {
    .contact__inner_wrap {
      .contact_sec_content {
        p {
          font-size: 16px;
        }
      }
    }
  }
}
