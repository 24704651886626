
.unlock__report_process {
	width: 100%;
	max-width: 530px;
	padding: 40px 40px;
	box-sizing: border-box;
	text-align: center;
    @media only screen and (max-width:767px){
        padding: 20px 20px;
    }
	h2.modal__popup_title {
		margin-bottom: 15px;
	}
	.mb-mrg {
		margin-bottom: 50px;
        @media only screen and (max-width:767px){
            margin-bottom: 20px;
        }
	}
}

.mb_mrg__02{
    margin-bottom: 20px;
}
.progress_percentage{
	font-size: 32px;
	font-weight: bold;
	color: #24537B;
}


.unlock__report_process {
	h2.modal__popup_title {
		margin-bottom: 15px;
	}
	.mb-mrg {
		margin-bottom: 50px;
	}
}

// Modal SCSS

.unlock__report_process {
    .reportr__img {
        position: relative;
        .AnimationTextWrap {
            position: absolute;
            top: 50%;
            bottom: auto;
            left: 50%;
            right: auto;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 150px;
            span {
                width: 100%;
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                font-weight: 400;
                color: #000000;
            }
        }
    }
}