.back-grad-title.location__bg_wrapper {
    padding: 40px 0px 80px;
    border-radius: 0px 0px 32px 32px;
    background: rgba(233, 243, 255, 0.50);

    .pricing-main-title {
        .location-main-section {
            .location-main-logo {
                margin-bottom: 40px;
                img {
                    width: 100%;
                    max-width: 323px;
                    height: 100%;
                }
            }

            .location__banner_img {
                width: 100%;
                display: inline-block;
                vertical-align: top;
                margin-bottom: 40px;
            }
        }
    }

    .location-main-section {
        padding: 0px 20px;
    }
}

.location-main-section {
    .location-your-address {
        max-width: 433px;
        margin: 0px auto;
        padding: 20px 20px;
        border-radius: 5px;
        border: 1px dashed #24537B;
        background: #E4F0FF;

        img {
            width: 100%;
            max-width: 28px;
            height: 100%;
            display: block;
            vertical-align: top;
            margin: 0px auto;
            padding-bottom: 12px;
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5;
            color: #1E1E1E;
        }
    }
}

@media screen and (max-width: 425px) {
    
    .back-grad-title.location__bg_wrapper {
        .pricing-main-title {
            .location-main-section {
                .location-main-logo {
                    img {
                        max-width: 253px;
                    }
                }
                .location__banner_img {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

}