.multiples_popup__wrap_ {
  width: auto !important;

  .ant-modal-content {
    padding: 0 !important;
  }

  .ant-modal-body {
    padding-top: 0 !important;
    max-height: 100%;
  }
}

// New Close Css

.multiples_popup__wrap_close_button {
  padding: 0px 5px;
  span.ant-modal-close-x {
    .anticon-close {
      width: 30px;
      height: 30px;
      line-height: 30px;
      background-color: #24537b;
      border-radius: 50%;
      text-align: center;
      justify-content: center;
    }
    svg {
      fill: #ffffff;
      font-size: 15px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .ant-modal-close {
    position: unset !important;
    margin-top: 10px !important;
    width: 100% !important;
    span.ant-modal-close-x {
      justify-content: flex-end;
      margin-right: 10px;
    }
  }
}

.ant-modal-close {
	&:hover {
		background-color: unset !important;
	}
}

.multiples_popup__wrap_ {
  & .ant-modal-content {
      button.ant-modal-close {
          right: 2%;
      }
  }
}

