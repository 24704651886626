.theme_dir__rtl {
  .step1_tutorial_section {
    .tutorial__img {
      .ln_tutorial_mockup {
        &::before {
          left: calc(50% + 5.1px);
        }
      }
    }
  }
  .login-modal-section {
    .wrap-top-modal {
      .body-dash-modal {
        .dashboard-find-number {
          .dashboard-locate {
            input {
              padding-left: 10px;
              padding-right: 80px;
              border-radius: 15px 15px 15px 15px;
            }
          }
        }
      }
    }
  }
  .location_search {
    form {
      .form-control {
        padding: 12px 80px 12px 15px;
        text-align: right;
      }
      .click-here {
        right: auto;
        left: 15px;
      }
    }
  }
  .location_form_field {
    .react-tel-input {
      .selected-flag {
        .arrow {
          right: 20px;
          left: auto;
        }
      }
      .flag-dropdown {
        .selected-flag {
          border-radius: 0px 3px 3px 0px;
        }
        border-radius: 0px 10px 10px 0px;
        border-right: unset;
      }
    }
  }
  .premium-section-width {
    .ds--report-wrap {
      .report-btn-wrap {
        text-align: center;
        button.access-btn-info {
          left: auto;
          transform: unset;
        }
      }
    }
  }
  header.header_wrap {
    .main_container_header {
      .header_wrap_area {
        .header_functionality {
          .search-animation-set {
            .react-tel-input {
              .form-control {
                padding-right: 48px;
                padding-left: 0px;
                text-align: right;
              }
              .flag-dropdown {
                .selected-flag {
                  padding: 0px 15px 0px 0px;
                  .flag {
                    .arrow {
                      right: 20px;
                      left: auto;
                    }
                  }
                }
              }
            }
            svg {
              left: 7px;
              right: auto;
            }
          }
        }
      }
    }
  }
  .ds-footer-conditions {
    ul {
      li {
        &:not(:last-child) {
          a {
            &::before {
              right: auto;
              left: 0px;
            }
          }
        }
        a {
          padding-right: 0px;
          padding-left: 15px;
        }
      }
    }
  }
  .premium_box_wrap {
    .premium-section-width {
      padding-right: 16px;
      padding-left: 0px;
    }
  }
  .overview_menu_head {
    span {
      a {
        img {
          margin-right: 0px;
          margin-left: 8px;
        }
      }
    }
  }
  .general-info-wrap {
    .main_title {
      h2 {
        text-align: right;
      }
    }
  }
  .left-content {
    h2 {
      text-align: right;
    }
  }
}

.theme_dir__rtl {
  .premium-section-width {
    .location-info-wrap {
      .main_title {
        h2.text-left {
          text-align: right !important;
        }
      }
    }
  }
}

.theme_dir__rtl {
  button.unlock-btn-info {
    left: -50%;
    transform: translateX(50%);
  }
  .unlock_title {
    h2 {
      &::before {
        right: 0px;
        left: auto;
      }
    }
  }
  .social-profiles--boxes {
    .social-profiles-content {
      margin-left: 0px;
      margin-right: 20px;
    }
    .social-profiles-btn {
      margin-right: auto;
      margin-left: 0px;
      p {
        a {
          img {
            margin-right: 2px;
            margin-left: 0px;
          }
        }
      }
    }
  }
  .button-container {
    .info-button {
      width: max-content;
      img {
        margin-left: 5px;
      }
    }
  }
  .download_PDF_menu {
    .premium_toggle {
      left: 0px;
      right: auto;
    }
  }
  .ds--unlock-photos {
    .ds-phone-slider {
      .slick-next {
        left: auto;
        right: 10px;
      }
    }
  }
  p.address-btn {
    img {
      margin-right: 0px;
      margin-left: 8px;
    }
  }
  .main_title {
    h2 {
      img {
        vertical-align: text-bottom;
        margin-left: 0px;
        margin-right: 8px;
      }
    }
  }
  a.track_button {
    img {
      margin-left: 0px;
      margin-right: 15px;
    }
  }
  a.track_button {
    img.send_message_icon {
      rotate: 180deg;
    }
  }
  a.menu_button {
    img {
      margin-right: 0px;
      margin-left: 5px;
    }
  }
  .ds--secondary-head {
    .ds--phone-copy {
      margin-left: 0px;
      margin-right: auto;
    }
  }
  button.unlock-btn {
    left: -50%;
    transform: translateX(50%);
  }
  .multiple_popup__wrap {
    .wpb_report_popup {
      .mlt_popup__payment {
        form {
          .dsl_report {
            span.report_radio_wrap {
              &:before {
                margin-left: 5px;
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
  }
  li.ant-dropdown-menu-item {
    &:last-child {
      span.ant-dropdown-menu-title-content {
        .mobile_menu_option {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 460px) {
  .theme_dir__rtl {
    .social-profiles--boxes {
      .social-profiles-content {
        margin-right: 0px;
      }
    }
  }
}

.theme_dir__rtl {
  .step3_tutorial_section {
    .tutorial__img {
      .ln_tutorial_mockup {
        .ln_tutorial_wrapper {
          .step3_inner_wrap {
            .step3_tutorial_header {
              .tutorial_header_trigger {
                margin-right: auto;
                margin-left: 0px;
              }
            }
            .tutorial_location_info {
              .tutorial_table_info {
                table {
                  tbody {
                    tr {
                      td {
                        span {
                          img {
                            margin-left: 0px;
                            margin-right: 3px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .data_notfound {
    img {
      margin-right: 0px;
      margin-left: 5px;
    }
  }
  .ds-unlock-content {
    .reputation__risk_score {
      p.reputation__description_wrapper {
        font-weight: 700;
      }
    }
  }
}

.theme_dir__rtl {
  .step3_tutorial_section {
    .tutorial__img {
      .ln_tutorial_mockup {
        .ln_tutorial_wrapper {
          .step3_inner_wrap {
            .tutorial_report_header {
              .tutorial_report_info {
                ul {
                  li {
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// New SCSS

.theme_dir__rtl {
  .menu_item_box_wrap {
    .menu_item_btn {
      p {
        padding-left: 5px;
        padding-right: 0px;
      }
    }
  }
  .phone-report-box {
    .phone-report-text {
      a {
        direction: ltr;
      }
    }
  }
  .overview_menu_head {
    span {
      a {
        direction: ltr;
        justify-content: flex-end;
        img {
          order: 1;
        }
      }
    }
  }
  .reputation-phn-content {
    ul.reputation_listing_area {
      li {
        span {
          margin-left: 0px;
          margin-right: auto;
        }
      }
    }
  }
  .download_PDF_dropdown_menu_option {
    ul.ant-dropdown-menu {
      left: 0em;
      right: auto;
    }
  }
  .wpb_box_wrap {
    .vc_general_status {
      img {
        margin-left: 0px;
        margin-right: 25px;
      }
    }
  }
  .mobile-header {
    .react-tel-input {
      .form-control {
        padding-right: 60px;
        padding-left: 0px;
        text-align: right;
      }
      .flag-dropdown {
        border-right: unset;
        border-left: 1px solid #cacaca;
        padding-right: 13px;
        .selected-flag {
          .flag {
            .arrow {
              right: 20px;
              left: unset;
            }
          }
        }
      }
    }
    .mobile-header-search-icon {
      right: auto;
      left: 9px;
    }
  }
  .multiple_popup__wrap {
    .wpb_report_popup {
      .mlt_popup__subscribe {
        .policy__content_wrap {
          label {
            margin-left: 0px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .accuracy_popup {
    .accuracy_popup_info {
      .accuracy_popup_field {
        form {
          .accuracy_container {
            .ant-input-optional {
              .placeholder {
                right: 15px;
                left: auto;
              }
            }
          }
        }
      }
    }
  }
  .monitor-white-bg-wrap {
    .monthly_info_wrap {
      p {
        img {
          margin-right: 0px;
          margin-left: 7px;
        }
      }
    }
    .monthly_update_wrap {
      h3 {
        img {
          margin-right: 7px;
          margin-left: 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .theme_dir__rtl {
    .wpb_box_wrap {
      .vc_general_status {
        img {
          margin-right: 15px;
          margin-left: 0px;
        }
      }
    }
  }
}

.Searchvolume .toggle_area {
  justify-content: space-between;
}

.dropdown-setting.download_PDF_dropdown_menu_option {
  min-width: 150px !important;
}

// New Input Field SCSS

// Header Input Search SCSS

header.header_wrap {
  .main_container_header {
    .header_wrap_area {
      .header_functionality {
        .searchbar_wrapper {
          > input.form-control {
            border: 1px solid #24537b;
            border-left: unset;
            font-size: 14px;
            padding: 7.5px 0px 7.5px 0px;
          }
          input.form-control {
            &::placeholder {
              font-size: 0px;
            }
          }
        }
        .search-animation-set {
          .react-tel-input {
            input.form-control {
              border: 1px solid #24537b;
              border-right: unset;
              width: 90px;
            }
          }
        }
      }
    }
  }
}
.theme_dir__rtl {
  header.header_wrap {
    .main_container_header {
      .header_wrap_area {
        .header_functionality {
          .search-animation-set {
            input[type="text"] {
              border-radius: 50px 0px 0px 50px;
              border-right: unset;
              &::placeholder {
                font-size: 0px;
              }
            }
            .react-tel-input {
              .form-control {
                border-radius: 0px 25px 25px 0px;
                border-left: unset;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .mobile-header {
    .react-tel-input {
      .form-control {
        width: 90px !important;
        background-color: transparent;
      }
      .flag-dropdown {
        border-right: unset;
      }
    }
    .header_mobile_number_input {
      width: calc(100% - 80px);
      border-left: unset;
      background-color: transparent !important;
      &:hover {
        border-color: #d9d9d9 !important;
        background-color: transparent !important;
        outline: unset !important;
        box-shadow: unset !important;
        padding: 4px 20px;
      }
      &::placeholder {
        font-size: 0px;
      }
    }
  }
  .theme_dir__rtl {
    .mobile-header {
      .react-tel-input {
        .form-control {
          border-radius: 0px 25px 25px 0px !important;
          border-left: unset;
          padding-left: 17px;
          padding-right: 0px;
        }
        .flag-dropdown {
          border-right: unset;
          border-left: 1px solid #cacaca;
        }
      }
      .mobile-header-search-icon {
        left: 9px;
        right: auto;
      }
      .header_mobile_number_input {
        border-radius: 25px 0px 0px 25px !important;
        right: auto;
        left: 0px;
        width: calc(100% - 80px);
        &:hover {
          outline: unset !important;
          border: 1px solid #cacaca;
          box-shadow: unset !important;
        }
        &::placeholder {
          font-size: 0px;
        }
      }
    }
    .react-tel-input {
      .selected-flag {
        .arrow {
          right: 20px;
          left: auto;
        }
      }
    }
  }
}

// Dashboard Input Field SCSS

.theme_dir__rtl {
  .dashboard_search__report {
    .location_search {
      form {
        .location_form_field {
          > input.form-control {
            right: auto;
            left: 0px;
            border-radius: 10px 0px 0px 10px !important;
            padding: 12px 30px 12px 15px;
            width: calc(100% - 100px);
          }
        }
      }
    }
    .location_form_field {
      .react-tel-input {
        .flag-dropdown {
          border-radius: 0px 10px 10px 0px !important;
          border: 1px solid #cacaca;
        }
      }
    }
  }
  .location_search {
    form {
      .react-tel-input {
        input.form-control {
          border-radius: 0px 10px 10px 0px;
          padding: 12px 50px 12px 15px;
        }
      }
    }
  }
  .location_form_field {
    .react-tel-input {
      width: 100px;
      .flag-dropdown {
        .selected-flag {
          width: 100px;
        }
      }
    }
  }
}
@media only screen and (max-width: 435px) {
  .theme_dir__rtl {
    .location_search {
      form {
        .react-tel-input {
          input.form-control {
            width: 100px;
            border-left: unset;
          }
        }
      }
    }
  }
}

// Locating Popup SCSS

.login-modal-section {
  .wrap-top-modal {
    .body-dash-modal {
      .dashboard-find-number {
        .dashboard-locate {
          .dsh_locating_popup {
            .flag-dropdown {
              z-index: 999;
              cursor: pointer;
            }
          }
          .selected-flag {
            width: 100%;
          }
          .form_input_phone_number {
            &:hover {
              box-shadow: unset;
              border-color: #d9d9d9;
            }
            &:focus {
              box-shadow: unset;
              border-color: #d9d9d9;
            }
            &:focus-within {
              box-shadow: unset;
              border-color: #d9d9d9;
            }
            display: block !important;
          }
        }
      }
    }
  }
}
.theme_dir__rtl {
  .login-modal-section {
    .wrap-top-modal {
      .body-dash-modal {
        .dashboard-find-number {
          .dashboard-locate {
            .dsh_locating_popup {
              input.form-control {
                border: unset;
                padding-right: 40px;
                padding-left: 0px;
                width: 90px;
              }
            }
            .selected-flag {
              .flag {
                .arrow {
                  right: 20px;
                  left: auto;
                }
              }
              padding: 0px 10px 0px 0px;
              z-index: 9999;
              width: 100%;
            }
            input {
              border-radius: 15px 0px 0px 15px;
              padding-right: 10px;
              &:hover {
                box-shadow: unset;
                border-color: #d9d9d9;
              }
              &:focus-within {
                box-shadow: unset;
                border-color: #d9d9d9;
              }
              &:focus {
                box-shadow: unset;
                border-color: #d9d9d9;
              }
            }
          }
        }
      }
    }
  }
}

.dashboard_search__report {
  .location_search {
    .location_form_field {
      input.input-form {
        width: calc(100% - 100px);
      }
    }
  }
}
.location_form_field {
  .react-tel-input {
    .flag-dropdown {
      .selected-flag {
        width: 100px;
      }
    }
  }
}


.theme_dir__rtl {
	.dashboard_search__report {
		.location_search {
			form {
				.location_form_field {
					>input.form-control {
						direction: ltr;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	.theme_dir__rtl {
		.mobile-header {
			.react-tel-input {
				.form-control {
					padding-left: 15px;
					padding-right: 40px;
				}
				.flag-dropdown {
					border-right: unset;
					border-left: unset;
				}
			}
		}
	}
}



.theme_dir__rtl {
	.Searchvolume {
		.toggle_area {
			justify-content: flex-end;
		}
	}
}


.Searchvolume {
	.toggle_area {
		justify-content: flex-end;
	}
}
