// Modal One Css

.ant-modal-wrap {
  .lp__report_popup {
    width: 100% !important;
    max-width: 740px !important;
    .wpb_table_element {
      background-color: #fafafa;
      border-radius: 10px 10px 0px 0px;
      padding: 15px 15px;

      table {
        width: 100%;

        tbody {
          tr {
            td {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: #24537b;
              text-align: center;

              a {
                color: #24537b;
              }
              p {
                direction: ltr;
                margin: 0;
                color: #24537b;
              }
            }
          }
        }

        thead {
          tr {
            th {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 1.1;
              color: #000000;
              text-transform: uppercase;
              padding-bottom: 30px;
            }
          }
        }
      }
    }
    .wpb_popup_body {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      padding: 30px 30px;
      box-sizing: border-box;

      .popup_body_description {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 20px;

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.8;
          text-align: center;
          color: #777777;
          margin: 0px 0px;

          .mobile_number {
            color: #777777;
            display: inline;
            direction: ltr;
          }
        }
      }
      .popup_body_image {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        margin-bottom: 20px;

        img {
          width: 100%;
          max-width: 420px;
          margin: 0px auto;
        }
      }

      .popup_body_listing {
        .body__list {
          display: flex;
          justify-content: space-between;
          row-gap: 25px;
          width: 100%;
          max-width: 640px;
          margin: 0px auto 30px;

          ul {
            padding: 0px 0px;
            margin: 0px 0px;
            list-style: none;

            li {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 1.1;
              color: #24537b;
              display: flex;
              align-items: center;
              text-align: left;
              column-gap: 10px;
              &:not(:last-child) {
                margin-bottom: 25px;
              }
              > svg {
                font-size: 12px;
              }
            }
          }
        }

        .popup__title {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          margin-bottom: 20px;
          h2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.1;
            margin: 0px 0px;
            text-align: center;
            color: #000000;
          }
        }

        .popup_radio_btn {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 10px;
          margin-bottom: 20px;
          position: relative;

          label {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #777777;
          }

          input[type="radio"],
          input[type="checkbox"] {
            accent-color: #24537b;
            margin: 0px 0px;
            flex: 0 0 13px;
            max-width: 13px;
          }
        }

        .popup_checkbox {
          align-items: flex-start;
          margin-top: 20px;
          margin-bottom: 0px;

          label {
            text-align: center;
          }
        }

        .report__btn {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          margin-bottom: 20px;
          text-align: center;

          button.popup_btn__report {
            font-size: 16px;
            font-style: normal;
            display: inline-block;
            vertical-align: top;
            font-weight: 500;
            line-height: 1.5;
            color: #ffffff;
            border-radius: 100px;
            background-color: #24537b;
            border: 1px solid #24537b;
            padding: 12px 60px;
            transition: all 0.4s ease-in-out;

            &:hover {
              background-color: transparent;
              color: #24537b;
              border: 1px solid #24537b;
            }
          }
        }

        .thanks_btn a {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          color: #24537b;
          text-decoration-line: underline;
          text-align: center;
        }
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap {
    .popup_body_image {
      img {
        max-width: 300px;
      }
    }

    .lp__report_popup {
      max-width: 100% !important;
      .wpb_popup_body {
        padding: 15px 15px;

        .popup_body_description {
          p {
            font-size: 14px;
          }
        }
        .popup_body_listing {
          .body__list {
            margin-bottom: 20px;

            h2 {
              font-size: 20px;
            }

            ul {
              grid-template-columns: repeat(2, 1fr);
              row-gap: 15px;
            }
          }
          .report__btn {
            button.popup_btn__report {
              font-size: 14px;
              padding: 10px 40px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .ant-modal-wrap {
    .lp__report_popup {
      .wpb_popup_body {
        .popup_body_listing {
          .body__list {
            flex-wrap: wrap;
            row-gap: 10px;
            ul {
              li {
                font-size: 14px;
                column-gap: 6px;
                svg {
                  font-size: 9px;
                }
                &:not(:last-child) {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .ant-modal-wrap {
    .lp__report_popup {
      .wpb_table_element {
        table {
          tbody {
            tr {
              td {
                font-size: 14px;
              }
            }
          }
          thead {
            tr {
              th {
                font-size: 14px;
                padding-bottom: 15px;
              }
            }
          }
        }
      }
      .wpb_popup_body {
        .popup_body_listing {
          .body__list {
            ul {
              li {
                font-size: 14px;
                column-gap: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .ant-modal-wrap {
    .lp__report_popup {
      .wpb_popup_body {
        .popup_body_listing {
          .body__list {
            ul {
              grid-template-columns: repeat(1, 1fr);
              row-gap: 10px;
            }
          }
          .popup__title {
            h2 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.white-bg-wrap {
  button.phone-btn-info {
    .banner-btn-icon {
      img.d-md-block {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .white-bg-wrap {
    button.phone-btn-info {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px 50px;
      column-gap: 10px;
      padding: 14px 14px;
      a {
        margin-top: 5px;
      }
      .banner-btn-icon {
        position: unset;
        img.d-md-block {
          display: block !important;
          max-width: 30px;
        }
        img.d-md-none {
          display: none;
        }
      }
    }
  }
}

.checkbox-error {
  border: 2px solid red;
}

.invalid-number-error {
  color: #ffffff;
  background: #ff3855;
  width: max-content;
  padding: 6px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 5px 2px #fd8a97;
  line-height: 1.3;
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: 0px;
  right: auto;
  top: -60px;
  bottom: auto;
}

@media only screen and (max-width: 499px) {
  .wpb_popup_body {
    .invalid-number-error {
      width: 100%;
      max-width: max-content;
      font-size: 12px;
      padding: 8px 8px;
      left: 50%;
      top: -70px;
      transform: translateX(-50%);
    }
  }
}

@media only screen and (max-width: 1400px) {
  .ant-modal-wrap {
    .lp__report_popup {
      .wpb_popup_body {
        padding: 15px 15px;
        .popup_body_description {
          margin-bottom: 10px;
          p {
            font-size: 14px;
            line-height: 1.2;
          }
        }
        .popup_body_image {
          img {
            max-width: 360px;
          }
          margin-bottom: 10px;
        }
        .popup_body_listing {
          .body__list {
            ul {
              li {
                &:not(:last-child) {
                  margin-bottom: 12px;
                }
              }
            }
            margin: 0px auto 15px;
          }
          .popup_checkbox {
            margin-top: 10px;
          }
          .report__btn {
            margin-bottom: 10px;
          }
          .popup__title {
            h2 {
              font-size: 21px;
            }
          }
        }
      }
      .wpb_table_element {
        padding: 10px 10px;
        table {
          thead {
            tr {
              th {
                padding-bottom: 10px;
                font-size: 14px;
              }
            }
          }
          tbody {
            tr {
              td {
                a {
                  font-size: 14px;
                }
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
