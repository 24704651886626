// Menu Item
.menu_item_box_wrap {
  width: 100%;
  display: inline-block;
  border-radius: 10px;
  background: #fff;
  margin-top: 30px;
  padding: 15px 15px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);

  .menu_item_btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 20px;

    p {
      display: inline-block;
      vertical-align: top;
      margin: 0px 0px;

      &:last-child {
        margin-left: auto;
      }
    }

    .left_button {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.ds_full_reportMenu {
  .menu_item_box_wrap {
    .menu_item_btn {
      justify-content: space-between;

      p {
        &:last-child {
          margin-left: unset;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .ds_full_reportMenu {
    .menu_item_box_wrap {
      .menu_item_btn {
        p {
          span {
            display: none;
          }
        }
      }
    }
  }
}

a {
  &.menu_button {
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px 15px;
    background: #ecf7fb;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #24537b;
    position: relative;
    min-width: 150px;
    @media only screen and (max-width: 930px) {
      justify-content: center;
      min-width: 140px;
    }
    @media only screen and (max-width: 900px) {
      min-width: 115px;
    }
    @media only screen and (max-width: 704px) {
      min-width: 94px;
    }
    @media only screen and (max-width: 545px) {
      min-width: unset;
    }

    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

@media only screen and (max-width: 675px) {
  .menu_item_box_wrap {
    padding: 15px 10px;

    a.menu_button {
      font-size: 14px;
      padding: 7px 8px;
    }
  }
}

@media only screen and (max-width: 580px) {
  .menu_item_box_wrap {
    .menu_item_btn {
      p {
        a {
          &.menu_button {
            font-size: 0;
          }

          img {
            margin-right: 0px;
            width: 100%;
            max-width: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .menu_item_box_wrap {
    p {
      a.menu_button {
        &::before {
          content: "";
          position: absolute;
          display: none;
          top: auto;
          bottom: -16px;
          left: 40%;
          transform: translateX(-40%);
          border: 5px solid transparent;
          border-top: 0px;
          border-bottom: 8px solid #24537b;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          animation: tooltips-horz 500ms ease-out forwards;
        }

        &::after {
          content: attr(data-title);
          position: absolute;
          font-size: 14px;
          font-weight: 300;
          line-height: 1.2;
          text-align: center;
          display: none;
          min-width: 120px;
          max-width: 370px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 8px 10px;
          border-radius: 3px;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
            rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          background-color: #24537b;
          color: #ffffff;
          z-index: 1000;
          animation: tooltips-horz 300ms ease-out forwards;
          top: auto;
          bottom: -60px;
          left: 0%;
        }

        &:hover {
          &:after {
            display: block;
          }

          &:before {
            display: block;
          }
        }
      }

      &:nth-last-child(2),
      &:nth-last-child(1) {
        a.menu_button {
          &::after {
            right: 0px;
            left: auto;
          }
        }
      }
    }
  }

  @keyframes tooltips-horz {
    to {
      transform: translate(0, -50%);
    }
  }
}

@media only screen and (max-width: 884px) {
  a.menu_button {
    font-size: 13px;
    padding: 10px 10px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      max-width: 15px;
    }
  }

  .ds_full_reportMenu {
    .menu_item_box_wrap {
      .menu_item_btn {
        column-gap: 5px;
        row-gap: 5px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .menu_item_box_wrap {
    a.menu_button {
      padding: 11px 20px;
      font-size: 0px;

      img {
        margin-right: 0px;
        max-width: 25px;
      }
    }
  }
}

@media only screen and (max-width: 415px) {
  .menu_item_box_wrap {
    a.menu_button {
      padding: 8px 13px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .menu_item_box_wrap {
    a.menu_button {
      padding: 8px 13px;
      img {
        max-width: 15px;
      }
    }
  }
}

// Header Monitor Button Switch

.monitor__switch_wrap {
  vertical-align: text-bottom !important;
  padding-right: 5px;
  button.ant-switch {
    min-width: 24px;
    height: 20px;
    background-color: #e9e8ec;
    @media only screen and (max-width: 640px) {
      min-width: 40px;
    }
    .ant-switch-handle {
      top: 3px;
      width: 14px;
      height: 14px;
      &::before {
        border-radius: 25px;
      }
    }
  }
  .ant-switch-checked {
    background-color: #c2dfea !important;

    .ant-switch-handle {
      inset-inline-start: calc(100% - 17px);
    }
  }
}

// New RTL


.theme_dir__rtl {
	button.unlock-btn-info {
		padding: 14px 30px 14px 60px;
		.unlock-btn-icon {
			right: auto;
			left: 5px;
		}
	}
	button.unlock-btn {
		padding: 14px 30px 14px 60px;
		.unlock-btn-icon {
			right: auto;
			left: 5px;
		}
	}
	.white-bg-wrap {
		button.phone-btn-info {
			.banner-btn-icon {
				right: auto;
				left: -20px;
			}
			padding: 14px 14px 14px 85px;
		}
	}
	.ds_full_reportMenu {
		.menu_item_box_wrap {
			.menu_item_btn {
				column-gap: 0px;
			}
		}
	}
}
@media only screen and (max-width: 375px) {
	.theme_dir__rtl {
		.menu_item_box_wrap {
			a.menu_button {
				padding: 8px 8px;
			}
		}
	}
}
@media only screen and (max-width: 410px) {
	.theme_dir__rtl {
		.white-bg-wrap {
			button.phone-btn-info {
				padding: 14px;
			}
		}
	}
}


@media only screen and (max-width: 900px) {
	.theme_dir__rtl {
		.premium_box_wrap {
			.premium-section-width {
				padding-right: 0px !important;
				padding-left: 0px !important;
			}
		}
	}
}
