.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 425px){
    flex-wrap: wrap;
  }
  .info-button {
    border: none;
    background-color: #24537b;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    text-transform: capitalize;
    font-size: 0.85rem;
    letter-spacing: 1px;
    cursor: pointer;
    width: 150px;
    @media only screen and (max-width: 425px){
      width: 100%;
      justify-content: center;
    }
    img{
      @media only screen and (max-width: 425px){
        margin-right: 5px;
      }
    }
  }
}


