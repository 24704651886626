.text-left {
  text-align: left;
}
.theme_dir__rtl .text-left {
  text-align: right !important;
}

.testimonial-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px auto;
  padding-top: 30px;
  position: relative;
}

.testimonial-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.testimonial-row {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  @media only screen and (max-width: 767px) {
    gap: 15px;
  }
}

.testimonial-card {
  flex: 0 0 20%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-right: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.testimonial-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial-body {
  margin-top: 20px;
}

.testimonial-rating {
  margin-top: 10px;
}

.testimonial-rating span {
  color: #ffbb00;
  font-size: 20px;
}

.prev-button,
.next-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 18px;
}

.prev-button:hover,
.next-button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 767px) {
  .testimonial-card {
    margin-right: 0;
  }
}

// Slick Slider SCSS

.SectionSliderWrapper {
  background-color: #24537b;
  margin: 0px 20px;
  border-radius: 20px;
  h2.SectionMainTitle {
    color: #ffffff;
  }
}
.SlickSliderWrapper {
  width: 100%;
  max-width: calc(50% + 645px);
  padding: 0px 0px 0px 15px;
  margin-left: auto;
  margin-right: 0;
  .testimonial-slider {
    .testimonial-container {
      .testimonial-row {
        .testimonial-card {
          text-align: left;
          h3 {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            color: #29313d;
            letter-spacing: 1px;
            line-height: 1.5;
            margin: 0px 0px;
            margin-left: 0px;
            margin-right: auto;
          }
          .testimonial-header {
            justify-content: flex-start;
            img.testimonial-image {
              margin-left: 0px;
              margin-right: auto;
            }
            .testimonial-rating {
              margin-left: 0px;
              margin-right: auto;
              margin-bottom: 20px;
            }
          }
          .testimonial-body {
            margin-top: 10px;
            p {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: -0.14px;
              color: #333333;
              margin: 0px 0px;
            }
          }
        }
      }
    }
  }
}

.testimonial--btn--wrap {
  position: absolute;
  top: -20px;
  bottom: auto;
  left: 0px;
  right: auto;
  .dots-container {
    text-align: center;
    margin-top: 0px;
    .dot {
      height: 4px;
      width: 20px;
      background-color: #e6e6e6;
      border-radius: 6px;
      display: inline-block;
      margin: 0 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    .dot.active {
      width: 50px;
      height: 4px;
      border-radius: 6px;
      background-color: #47aaff;
    }
  }
}

@media screen and (max-width: 1199px) {
  .testimonial-card {
    flex: 0 0 25%;
  }
}

@media screen and (max-width: 991px) {
  .testimonial-card {
    flex: 0 0 33.33%;
  }
}

@media screen and (max-width: 767px) {
  .testimonial-card {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 575px) {
  .SlickSliderWrapper {
    padding: 0px 15px 0px 15px;
  }

  .testimonial-card {
    flex: 0 0 100%;
  }
}

@media screen and (max-width: 1330px) {
  .SlickSliderWrapper {
    max-width: calc(50% + 605px);
  }
}

// Image Position

.HomeBanner_Wrapper {
  .HomeBanner__Info {
    display: flex;
    .HomeBanner__Content {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .HomeBanner__Image {
      max-width: 50%;
      flex: 0 0 50%;
      text-align: center;
      .BannerImageWrapper {
        .BannerPhoneImage {
          position: relative;
          width: 100%;
        }
      }
    }
  }
}
.BannerCustomBoxWrap {
  .WplBannerImageBox1 {
    top: -4%;
    position: absolute;
    left: 14%;
  }
  .WplBannerImageBox2 {
    position: absolute;
    bottom: 14%;
    top: auto;
    left: 6%;
  }
  .WplBannerImageBox3 {
    top: 9%;
    position: absolute;
    left: auto;
    right: -1%;
  }
  .WplBannerImageBox4 {
    position: absolute;
    bottom: 16%;
    top: auto;
    right: -1%;
  }
}

@media screen and (max-width: 1024px) {
  .BannerCustomBoxWrap {
    .WplBannerImageBox1 {
      top: -5%;
      left: 20%;
    }
    .WplBannerImageBox2 {
      bottom: 15%;
      left: 12%;
    }
    .WplBannerImageBox3 {
      top: 8%;
      right: 15%;
    }
    .WplBannerImageBox4 {
      bottom: 10%;
      right: 15%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .HomeBanner_Wrapper {
    .HomeBanner__Info {
      flex-wrap: wrap;
      .HomeBanner__Content {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .HomeBanner__Image {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 80px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .HomeBanner_Wrapper {
    .HomeBanner__Info {
      .HomeBanner__Image {
        display: none;
      }
    }
  }
}


// BG Pattern

.HomeBanner__Image {
  .BannerImagePattern {
    &::before {
      content: "";
      position: absolute;
      background-image: url("../../assets/newhome/BannerBgPattern.svg");
      width: 100%;
      max-width: 969px;
      height: 717px;
      top: auto;
      bottom: -5%;
      left: auto;
      right: 1%;
      background-position: top center;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
      overflow: hidden;
    }
  }
}
@media screen and (max-width: 1024px) {
  .HomeBanner__Image {
    .BannerImagePattern {
      &::before {
        right: 0;
      }
    }
  }
}

// New Home RTL SCSS

.CallToAction__Content {
  .reverslyHomeBannerInput {
    margin-top: 30px;
  }
}
.HomeBanner_Wrapper {
  .HomeBanner__Info {
    .HomeBanner__Content {
      .reverslyHomeBannerInput {
        margin-bottom: 30px;
      }
    }
  }
}

.theme_dir__rtl {
  .WplBannerImageBox3 {
    .BannerPhoneInformation {
      .BannerPhoneInformationBox {
        .BannerPhoneInformationContent {
          margin-right: 8px;
          margin-left: 0px;
          h4 {
            text-align: right;
          }
        }
      }
    }
  }
  .BannerCustomBoxWrap {
    .WplBannerImageBox4 {
      .BannerConnectedAccountsBox {
        .BannerSocialInfo {
          .BannerSocialActive {
            margin-right: auto;
            margin-left: 0;
          }
          img {
            margin-right: 0px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .WplBannerImageBox2 {
    .BannerLocationList {
      ul {
        li {
          .BannerLocationArea {
            pre {
              text-align: right;
            }
            span {
              text-align: left;
            }
          }
        }
      }
    }
  }
  .HomeBanner__Image {
    .BannerImagePattern {
      &::before {
        left: 1%;
        right: auto;
      }
    }
  }
  .HomeBanner__Info {
    .HomeBanner__Content {
      .BannerListItem__Wrapper {
        ul {
          li {
            padding-right: 24px;
            padding-left: 0px;
            &:before {
              left: auto;
              right: 0px;
            }
          }
        }
      }
      .BannerPrivacyContent {
        ul {
          li {
            padding-right: 0px;
            margin-right: 0px;
            padding-left: 25px;
            margin-left: 25px;
            &:before {
              left: 0px;
              right: auto;
            }
          }
        }
      }
    }
  }
  .reversly__box_info {
    .box__bg_wrapper {
      .box__reversly_content {
        ul {
          li {
            padding-right: 18px;
            padding-left: 0px;
            &:before {
              right: 0px;
              left: auto;
            }
          }
        }
      }
    }
  }
  .accordion__wrapper {
    .accordion__item {
      .accordion-body {
        .accordion__icon {
          margin-left: 16px;
          margin-right: 0px;
        }
      }
    }
  }
  .HelpSectionBoxWrpper {
    .wpb_inner_box_wrap {
      .HelpSectionIcon {
        img {
          margin-left: 12px;
          margin-right: 0px;
        }
      }
    }
  }
  .faqs_accordion-body {
    .faqs__accordion_info {
      .faqs_accordion_arrow {
        margin-right: 20px;
        margin-left: 0px;
      }
    }
  }
  .CallToAction__Content {
    .cta__wpb_content_element {
      left: 0px;
      right: auto;
      left: 0px;
      right: auto;
    }
  }
  .CallToAction__wrapper {
    .CallToAction__Content {
      .section__title {
        h2.SectionMainTitle {
          text-align: right;
          text-align: right;
        }
      }
    }
  }
  .cta__wpb_content_element {
    .cta__content_element {
      .wpb__presonal_info {
        .wpb__inner_info {
          .wpb__inner_activity {
            img {
              margin-left: 6px;
              margin-right: 0px;
              margin-left: 6px;
              margin-right: 0px;
            }
          }
        }
      }
      .wpb__details_info {
        .wpb__presonal_text {
          h4.wpb__name_details {
            img {
              margin-right: 3px;
              margin-left: 0px;
              margin-right: 3px;
              margin-left: 0px;
            }
          }
        }
        .wpb__user_image {
          margin-left: 10px;
          margin-right: 0px;
        }
      }
    }
  }
  .Header_wrapper {
    .HeaderFuncList {
      .header-lang-drop {
        li {
          img {
            margin-left: 15px;
            margin-right: 0px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .theme_dir__rtl {
    .reverslyHomeBannerInput {
      .wpb_content_input {
        .reverslyInputDesktop {
          margin-right: 0px;
          margin-left: 10px;
          input.desktop-form-control {
            border-left: unset;
            border-right: 1px dashed #152536;
          }
          .desktop-input {
            .flag-dropdown {
              width: 20px;
              .flag {
                .arrow {
                  right: 18px;
                  left: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 625px) {
  .theme_dir__rtl {
    .reverslyHomeBannerInput {
      .wpb_content_input {
        .reverslyInputMobile {
          margin-right: 0px;
          margin-left: 10px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .theme_dir__rtl {
    .reverslyHomeBannerInput {
      .wpb_content_input {
        .reverslyInputMobile {
          input.mobile-form-control {
            border-right: 1px dashed #152536;
            border-left: unset;
          }
          .mobile-input {
            .flag-dropdown {
              width: 23px;
              .flag {
                .arrow {
                  right: 20px;
                  left: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-device-width: 1025px) and (max-device-width: 1199px) {
  .theme_dir__rtl {
    .BannerCustomBoxWrap {
      .WplBannerImageBox3 {
        right: 8%;
      }
      .WplBannerImageBox4 {
        right: 5%;
      }
    }
  }
}

// Image Design SCSS
.full-report-box::before {
  content: "";
  position: absolute;
  width: 331px;
  height: 331px;
  border-radius: 100%;
  background: rgba(41, 93, 226, 0.2);
  filter: blur(100px);
  top: auto;
  bottom: -20%;
  left: -20%;
  right: auto;
}
.theme_dir__rtl {
  .full-report-box::before {
    content: "";
    position: absolute;
    width: 331px;
    height: 331px;
    border-radius: 100%;
    background: rgba(41, 93, 226, 0.2);
    filter: blur(100px);
    top: auto;
    bottom: -20%;
    left: -20%;
    right: auto;
    @media only screen and (max-width: 750px) {
      left: 0;
    }
  }
}

/* ======================================
================ EN CSS ================
/* ======================================
 */

 .social-profile-box {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 12px;
    bottom: 0;
    box-shadow: 5px 5px 16px 0 rgba(0, 21, 46, .125);
    float: right;
    height: auto;
    padding: 10px;
    position: absolute;
    right: 4%;
    z-index: 99;
    width: 100%;
    max-width: 130.56px;
}
@media only screen and (max-width: 1440px) {
  .social-profile-box {
    right: 0;
  }
}
@media only screen and (max-width: 575px) {
    .social-profile-box {
        display: unset;
        height: fit-content;
        transform: translateX(4%);
        right: 14%;
    }
    .email-inner-box {
        h3.h {
            display: none;
        }
        h4 {
            display: unset;
            font-size: 10px;
        }
        h3 {
            font-size: 10px;
        }
    }
    .email-box {
        bottom: 0;
        top: auto;
        right: -29%;
        transform: translateX(0);
        width: 100%;
        max-width: max-content;
        padding: 5px;
    }
    .box-full-report {
        max-width: 370px;
    }
    .list-item {
        li {
            line-height: 1.5;
        }
    }
}
@media only screen and (max-width: 480px) {
  .inner-photos-box {
    .photo-box {
      &:first-child {
        display: none;
      }
    }
    justify-content: center;
  }
  .owner-photo-box {
    h3 {
      text-align: center;
    }
    width: 128px;
    top: 6%;
    left: 58%;
  }
  .box-full-report {
    width: 216px;
    left: 0;
  }
  .social-profile-box {
    width: 132px;
    margin-right: 0;
    bottom: unset;
    left: 54.5% !important;
  }
  .email-inner-box {
    h4 {
      font-size: 10px;
    }
  }
  .email-box {
    height: fit-content;
    right: 4% !important;
    top: 72%;
  }
}

/* ======================================
================ RTL CSS ================
/* ======================================
 */

.theme_dir__rtl {
  .media-box {
    .icon {
      height: 16px;
      margin-left: 4px;
      object-fit: cover;
      width: 16px;
    }
  }
  .social-profile-box {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 12px;
    bottom: 0;
    box-shadow: 5px 5px 16px 0 rgba(0, 21, 46, 0.125);
    float: right;
    height: auto;
    padding: 10px;
    position: absolute;
    right: 76px;
    z-index: 99;
    width: 100%;
    max-width: 130.56px;
  }
}
@media only screen and (max-width: 1440px) {
  .theme_dir__rtl {
    .social-profile-box {
      display: unset;
      height: fit-content;
      right: 0;
    }
    .email-box {
      top: -20px;
    }
  }
  .theme_dir__rtla {
    .social-profile-box {
      right: 0;
    }
  }
}
@media only screen and (max-width: 767.5px) {
  .theme_dir__rtl {
    .email-box {
      right: 0;
    }
  }
}
@media only screen and (max-width: 575px) {
  .theme_dir__rtl {
    .social-profile-box {
      display: unset;
      height: fit-content;
      right: 10%;
    }
    .email-inner-box {
      h3.h {
        display: none;
      }
      h4 {
        display: unset;
        font-size: 10px;
      }
      h3 {
        font-size: 10px;
      }
    }
    .email-box {
      bottom: 0;
      transform: translateX(0);
      width: 100%;
      max-width: 140px;
      top: auto;
      height: fit-content;
      right: 8.4%;
      padding: 10px;
    }
    .box-full-report {
      max-width: 370px;
    }
    .list-item {
      li {
        line-height: 1.5;
      }
    }
    .full-report-box {
      width: auto;
    }
  }
}
@media only screen and (max-width: 530px) {
  .theme_dir__rtl {
    .email-box {
      right: 0;
      padding: 10px;
    }
    .social-profile-box {
      right: 2%;
    }
  }
}
@media only screen and (max-width: 500px) {
  .theme_dir__rtl {
    .social-profile-box {
      right: -10px;
    }
    .email-box {
      right: -10px;
    }
  }
}
@media only screen and (max-width: 480px) {
  .theme_dir__rtl {
    .inner-photos-box {
      .photo-box {
        &:first-child {
          display: none;
        }
      }
      justify-content: center;
    }
    .owner-photo-box {
      h3 {
        text-align: center;
      }
      width: 128px;
      top: 6%;
      left: 58%;
    }
    .box-full-report {
      width: 216px;
      left: 0 !important;
    }
    .social-profile-box {
      width: 132px;
      margin-right: 0;
      bottom: unset;
      right: 4% !important;
    }
    .email-inner-box {
      h4 {
        font-size: 10px;
      }
    }
    .email-box {
      height: fit-content;
      right: 2% !important;
      top: 72%;
    }
    .ReverslyLookupHelp__area {
      .ReverslyLookupHelp__img {
        flex: unset;
      }
    }
  }
}

.theme_dir__rtl {
  .SlickSliderWrapper {
    margin-left: 0px;
    margin-right: auto;
    padding: 0px 15px 0px 15px;
  }
  .testimonial--btn--wrap {
    right: 15px;
    left: auto;
  }
}


.box-full-report {
    height: 450px;
}

// New Image SCSS

.theme_dir__rtl {
  .WplBannerImageBox3 {
      .BannerPhoneInformation {
          .BannerPhoneInformationBox {
              .BannerPhoneOwnerInfo {
                  margin-right: auto;
                  margin-left: 0px;
              }
          }
      }
  }
}


.HomeBanner__Image {
  .BannerImagePattern {
      &::before {
          width: 919px;
          height: 657px;
          right: 6%;
      }
  }
}


@media screen and (max-width: 1670px) {
  .HomeBanner__Image {
      .BannerImagePattern {
          &::before {
              width: 769px;
              height: 517px;
              right: 2%;
          }
      }
  }
}

@media screen and (max-width: 1024px) {
  .HomeBanner__Image {
      .BannerImagePattern {
          &::before {
              width: 869px;
              height: 617px;
              right: auto;
              left: 50%;
              transform: translateX(-50%);
          }
      }
  }
  .theme_dir__rtl {
      .HomeBanner__Image {
          .BannerImagePattern {
              &::before {
                  width: 869px;
                  height: 617px;
                  right: auto;
                  left: 50%;
                  transform: translateX(-50%);
              }
          }
      }
  }
}

.ReverslyLookupHelp__img {
	.full-report-box {
		a {
			pointer-events: none;
		}
	}
}