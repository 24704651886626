/**************PDF Modal**************/
.ant-modal.wpb_deownload__pdf_wrapper {
    width: 100% !important;
    max-width: calc(595px - 30px) !important;

    @media screen and (max-width: 575px) {
        max-width: calc(100vw - 20px) !important;
    }
}

.wpb_deownload__pdf_wrapper {
    .ant-modal-content {
        padding: 0px 0px !important;
    }
}

.wpb_report__wrapper {
    .vc_button__general {
        button.vc_back-inline {
            width: 100%;
            display: inline-block;
            vertical-align: top;
            font-size: 18px;
            line-height: 1.3;
            font-weight: 500;
            color: #777777;
            background-color: transparent;
            border: unset;
            cursor: pointer;
        }
    }
}

.wpb_deownload__pdf_wrapper {
	.wpb_report__wrapper {
		.vc_icon__general {
			margin-bottom: 40px;
            padding: 10px 28px;
            border-radius: 10px;
            background-color: #EAEEF2;
            img{
                width: 100%;
            }
            @media screen and (max-width: 767px){
                margin-bottom: 20px;
            }
		}
	}
}