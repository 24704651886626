header {
  * {
    box-sizing: border-box;
  }

  .header_logo {
    img {
      cursor: pointer;
    }
  }

  &.header_wrap {
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 20px 0px;
    border-bottom: 1px solid rgb(217 217 217 / 40%);

    .main_container_header {
      width: 100%;
      max-width: 1130px;
      margin: 0px auto;
      padding: 0px 15px;

      .header_wrap_area {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .header_functionality {
          display: flex;
          flex-wrap: wrap;
          column-gap: 15px;
          .search-animation-set {
            display: flex;
            align-items: center;
            width: 190px;
            position: relative;
            @media only screen and (min-width: 575px) {
              width: 250px;
            }
            @media only screen and (min-width: 768px) {
              width: 300px;
            }
            @media only screen and (max-width: 768px) {
              display: none;
            }
            .Number_Error {
              position: absolute;
              bottom: -37px;
              left: 21px;
              color: red;
              @media only screen and (max-width: 768px) {
                display: none;
              }
            }

            input[type="text"] {
              // position: absolute;
              right: 0;
              // width: 0;
              height: 35px;
              z-index: 1000;
              box-sizing: border-box;
              border: 1px solid #2a587e;
              border-radius: 0 50px 50px 0;
              font-size: 16px;
              background-color: #ffffff;
              padding: 7.5px 0px 7.5px 7.5px;
              -webkit-transition: width 0.4s ease-in-out;
              transition: width 0.4s ease-in-out;
              cursor: pointer;
            }
            .mobile_search_input {
              cursor: text !important;
              border: 1px solid #2a587e !important;
              padding: 7.5px 33px 7.5px 7.5px !important;
            }
            .flag-dropdown {
              .selected-flag {
                width: 90px;
              }
            }
            input[type="text"]:focus {
              width: 100%;
              border: 1px solid #2a587e;
              outline: none;
              padding: 7.5px 33px 7.5px 7.5px;
            }
            svg {
              position: absolute;
              right: 7px;
              top: 6px;
              font-size: 24px;
              fill: #2a587e;
              cursor: pointer;
              z-index: 1000;
              @media screen and (max-width: 768px) {
                display: none;
              }
            }
            .react-tel-input {
              width: unset;
              .form-control {
                border-radius: 25px 0 0 25px;
                width: 80px;
              }
              .flag-dropdown {
                background-color: transparent;
                border-radius: 25px;
                border: none;
                .selected-flag {
                  border-radius: 25px;
                  padding: 0 0 0 15px;
                  &:hover {
                    background-color: transparent;
                  }
                }
              }
            }
          }
          .mobile-drawer {
            display: none;
            @media only screen and (max-width: 575px) {
              display: block;
            }
          }
          .desktop-drawer {
            display: block;
            @media only screen and (max-width: 575px) {
              display: none;
            }
          }
          .openbtn {
            background: #10568f;
            width: 35px;
            height: 36px;
            border-radius: 100%;
            border-radius: 5px;
            border-radius: 100%;
            cursor: pointer;
            position: relative;
          }

          .openbtn .openbtn-area {
            transition: all 0.4s;
          }

          .openbtn span {
            position: absolute;
            background: #fff;
            display: inline-block;
            border-radius: 3px;
            left: 9px;
            height: 3px;
            transition: all 0.4s;
            width: 50%;
            &:nth-of-type(1) {
              top: 11px;
            }
            &:nth-of-type(2) {
              top: 17px;
            }
            &:nth-of-type(3) {
              top: 23px;
            }
          }
        }
      }
    }
  }
}
.mobile-drawer {
  .ant-drawer-header {
    padding: 25px 14px 0px;
    border: 0;
    .ant-drawer-title {
      display: none;
    }
    .ant-drawer-extra {
      .menu_close_btn {
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 30px;
        display: flex;
        align-items: center;
        padding: 0px 0px;
        svg {
          padding: 0px 0px;
        }
      }
    }
  }
  .ant-drawer-body {
    .profile_icon {
      width: max-content;
      margin: 0 auto;
      text-align: center;
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.03px;
        color: #777777;
        margin: 0;
      }
    }
    .mobile-drawer-menu {
      margin-top: 30px;
    }
  }
}
.dropdown-setting {
  min-width: 260px !important;
  .ant-dropdown-menu {
    background-color: #fff !important;
    background-image: none !important;
    li {
      color: black !important;
      padding: 0px 0px !important;
      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}
.mobile_menu_option {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 13px 15px;
  font-size: 20px;
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.03px;
  }
}
.mobile_menu_option.active {
  display: flex;
  background: #ecf7fb;
  border-radius: 5px;
  width: -webkit-fill-available;
}
.mobile-header {
  position: relative;
  width: 100%;
  .mobile-header-search-icon {
    display: none;
    position: absolute;
    right: 9px;
    top: 5px;
    font-size: 24px;
    fill: #2a587e;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  .mobile-number-error {
    display: none;
    position: absolute;
    bottom: -37px;
    left: 21px;
    color: red;
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
  .header_mobile_number_input {
    position: absolute;
    height: 50px;
    top: 0;
    right: 0;
    width: calc(100% - 80px);
    border-radius: 0 25px 25px 0 !important;
  }
}
@media only screen and (max-width: 575px) {
  .header_logo {
    img {
      width: 100%;
      max-width: 150px;
    }
  }
}
@media only screen and (max-width: 475px) {
  header.header_wrap {
    .main_container_header {
      .header_wrap_area {
        .header_logo {
          max-width: 60%;
          flex: 0 0 60%;
        }

        .header_functionality {
          max-width: 40%;
          flex: 0 0 40%;
          justify-content: flex-end;
          margin-bottom: 10px;

          .search-animation-set {
            width: 70%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .web-input {
    display: none;
  }
  .mobile-header {
    margin-top: 15px;
    .react-tel-input {
      .form-control {
        border-radius: 25px 0 0 25px !important;
        width: 80px !important;
        height: 50px;
        padding-left: 43px;
      }
      .flag-dropdown {
        background-color: transparent !important;
        border: unset;
        border-right: 1px solid #cacaca;
        border-radius: 0px !important;
        padding-right: 10px;
        .selected-flag {
          border-radius: 25px !important;
          padding: 0 0 0 15px !important;
          width: 70px;
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
  .mobile-header-search-icon {
    top: 50% !important;
    transform: translateY(-50%);
  }
}

@media (min-width: 769px) {
  .phone-input {
    display: none;
    width: 100%;
    margin: 5px !important;
  }
  .input-field {
    display: none;
    width: 100%;
    margin: 5px !important;
  }
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: unset !important;
}

.header_functionality {
  .searchbar_wrapper {
    .react-tel-input {
      input.form-control {
        border: 1px solid #24537b;
      }
    }
  }
}

header.header_wrap {
  .main_container_header {
    .header_wrap_area {
      .header_functionality {
        .ant-dropdown-open {
          .openbtn {
            span {
              transform: rotateY(-360deg);
              &:nth-of-type(1) {
                width: 52%;
                top: 11px;
                left: 8px;
                transform: translateY(6px) rotate(-135deg);
              }
              &:nth-of-type(2) {
                opacity: 0;
              }
              &:nth-of-type(3) {
                width: 52%;
                top: 23px;
                left: 8px;
                transform: translateY(-6px) rotate(135deg);
              }
            }
          }
        }
      }
    }
  }
}

// Notification Bell SCSS

.NotificationDropDownArea {
  width: calc(300px - 20px) !important;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  .NotificationDropDownBox {
    display: flex;
    transition: all 0.4s ease-in-out;
    padding: 6px 8px;
    align-items: end;
    margin-bottom: 5px;
    cursor: pointer;
    &:last-of-type {
      margin-bottom: 0px;
    }
    .wpb__NotificationDropDown {
      max-width: 70%;
      flex: 0 0 70%;
      .wpb__NotificationCenter {
        h5 {
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          color: #000000;
          line-height: 1.4;
          margin: 0px 0px 4px;
        }
        p {
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          color: #262626;
          line-height: 1.2;
          margin: 0px 0px;
        }
      }
    }
    .unread_NotificatiobWrapper {
      max-width: 30%;
      flex: 0 0 30%;
    }
    &:hover {
      background-color: #e5f3ff;
      border-radius: 5px;
    }
  }
}
.NotificationCenterWrap {
  cursor: pointer;
  img.ant-dropdown-trigger {
    width: 100%;
  }
}
@media only screen and (max-width: 475px) {
  .AntNotificationDropDownArea {
    inset: 60px 10px auto auto !important;
  }
}

.header_wrap_area {
  .header_functionality {
    .NotificationCenterWrap {
      position: relative;
      span {
        font-size: 8px;
        font-weight: 600;
        color: #ffffff;
        line-height: 16px;
        text-align: center;
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #24537b;
        border-radius: 100%;
        top: -1px;
        bottom: auto;
        right: -3px;
        left: auto;
      }
    }
  }
}
.notificationAddressinfo {
  min-width: unset !important;
}

.theme_dir__rtl {
  .NotificationDropDownArea {
    .NotificationDropDownBox {
      .unread_NotificatiobWrapper {
        text-align: left !important;
      }
      .wpb__NotificationDropDown {
        .wpb__NotificationCenter {
          h5 {
            direction: ltr;
            text-align: right;
          }
          .wbp_notificationContent{
            span{
            white-space: nowrap;
             direction: ltr;
            text-align: right;
            display: block;
            }
          }
        }
      }
    }
  }
}
