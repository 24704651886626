.transaction__popup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  padding: 50px 0px;
  .transaction_bg_wrap {
    width: 100%;
    max-width: 810px;
    margin: 0px auto;
    border-radius: 10px;
    background: #ffffff;
    text-align: center;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 20px 20px;
    .transaction__title {
      h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.1;
        margin: 0px 0px 15px;
      }
      p {
        width: 100%;
        max-width: 360px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3;
        color: #777777;
        margin: 0px auto;
      }
      .transaction__img {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 15px;
      }
      margin-bottom: 30px;
    }
    .form-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
      row-gap: 16px;
      margin-bottom: 50px;
      .field-container {
        &:first-child {
          grid-column: 1 / 3;
        }
        label {
          display: none;
        }
        input {
          width: 100%;
          border-radius: 5px;
          border: 1px solid #dfeaee;
          background: rgba(236, 247, 251, 0.5);
          padding: 16px 13px;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.1;
          outline: none;
          color: #9ea8bd;
          &::placeholder {
            color: #9ea8bd;
          }
        }
        .cq_panel__components {
          background-size: 18px;
          background-repeat: no-repeat;
        }
        .cq_panel__components.main-phone-card {
          padding-left: 45px;
          background-position: 13px;
          background-image: url("./../../assets/images/credit-card-icon.svg");
        }
        .cq_panel__components.main-phone-date {
          background-position: right 13px center;
          background-image: url("./../../assets/images/form-date-icon.svg");
        }
        .cq_panel__components.main-phone-cvv {
          background-position: right 13px center;
          background-image: url("./../../assets/images/form_cvv_icon.svg");
        }
      }
    }
    .order__field {
      > span {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.1;
        color: #24537b;
        margin-bottom: 20px;
      }
      .order__details {
        width: 100%;
        max-width: 600px;
        display: flex;
        justify-content: space-between;
        padding: 17px 15px;
        margin: 0px auto;
        border-radius: 5px;
        border: 1px dashed #24537b;
        background: rgba(236, 247, 251, 0.3);
        > span {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.1;
          color: #777777;
        }
        strong {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: #444444;
        }
      }
    }
    button.payment__Submit {
      width: 100%;
      max-width: 270px;
      margin: 20px auto 0px;
      display: inline-block;
      vertical-align: top;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.3;
      padding: 12px 15px;
      border-radius: 10px;
      background-color: #24537b;
      color: #ffffff;
      border: 1px solid #24537b;
      transition: all 0.4s ease-in-out;
      &:hover {
        background-color: transparent;
        border: 1px solid #24537b;
        color: #24537b;
      }
    }
    .stripe_retry_error {
      margin: 0;
      color: red;
    }
    .terms__Privacy {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      margin-top: 20px;
      label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1;
        color: #777777;
      }
      input[type="radio"] {
        accent-color: rgba(36, 83, 123, 1);
        margin: 0px 0px;
        width: 15px;
        height: 15px;
      }
      .term_error {
        margin: 0;
        color: red;
      }
    }
    .visa_card {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin-top: 20px;
      img {
        width: 100%;
        max-width: 410px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

@media only screen and (max-width: 675px) {
  .transaction__popup {
    .transaction_bg_wrap {
      .transaction__title {
        h2 {
          font-size: 24px;
        }
      }
      .transaction_form {
        .form-container {
          grid-template-columns: repeat(1, 1fr);
          margin-bottom: 20px;
          .field-container {
            &:first-child {
              grid-column: 1;
            }
            input {
              font-size: 13px;
              padding: 13px 13px;
            }
            .cq_panel__components {
              background-size: 15px;
            }
          }
        }
      }
      .terms__Privacy {
        align-items: flex-start;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .transaction__popup {
    .transaction_bg_wrap {
      .order__field {
        .order__details {
          flex-wrap: wrap;
          justify-content: center;
          flex-direction: column-reverse;
          span {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.transaction-declined-modal {
  width: 100% !important;
  max-width: 610px;
  .transaction__popup {
    padding: 30px 0px;
    .transaction_bg_wrap {
      width: unset;
      max-width: unset;
      box-shadow: unset;
      padding: 0px 0px;
      .form-container {
        margin-bottom: 30px;
        .field-container {
          input {
            box-sizing: border-box;
            padding: 13px 13px;
            font-size: 13px;
          }
        }
      }
      .order__field {
        .order__details {
          box-sizing: border-box;
          padding: 13px 15px;
        }
      }
    }
  }
}
