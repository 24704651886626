.location-main-section {
  padding: 0 50px;
  .location-your-address {
    p {
      margin: 0;
      font-size: medium;
    }
  }
}
.loading-spin-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  transform: translate(-50%, -50%);
}
