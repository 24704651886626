.unlock-report {
  background-color: #24537b !important;

  .main_title {
    p {
      text-align: center;
    }
  }
}

// Premium section 4

.unlock-report {
  .main_title {
    p {
      width: 100%;
      max-width: 650px;
      margin: 0px auto;
    }
  }
}

.unlock-report {
  background: #24537b;
}
.unlock-report {
  .main_title h2 {
    color: #ffffff;
  }
  p,
  a {
    color: #ffffff !important;
  }
}
.report-box-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 20px;
  margin-top: 20px;
  align-items: center;
  .report-box {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.15);
    padding: 12px 15px;
    display: flex;
    column-gap: 15px;
    cursor: pointer;
    align-items: center;
    img {
      width: 100%;
      max-width: 20px;
    }
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      color: #ffffff;
    }
  }
}

button {
  &.unlock-btn-info {
    display: inline-block;
    background-color: #ffffff;
    padding: 14px 60px 14px 30px;
    border-radius: 60px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    border: none;
    cursor: pointer;
    span {
      font-size: 18px;
      line-height: 1.3;
      margin: 0px 0px;
      color: #24537b !important;
      font-weight: 500;
      text-decoration: none;
    }
  }
}
.unlock-btn-icon {
  position: absolute;
  top: 5px;
  right: 8px;
}

@media only screen and (max-width: 1200px) {
  .report-box-info {
    .report-box {
      span {
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .report-box-info {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 520px) {
  .report-box-info {
    column-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    .report-box {
      column-gap: 10px;
      padding: 12px 2px;
      span {
        font-size: 14px;
      }
    }
  }
  button {
    &.unlock-btn-info {
      padding: 14px 60px 14px 30px;
      a {
        font-size: 14px;
      }
    }
  }
  .unlock-btn-icon {
    top: 2px;
  }
}

@media only screen and (max-width: 375px) {
  .report-box-info {
    grid-template-columns: repeat(1, 1fr);
  }
}
