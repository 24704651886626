.ant-modal-wrap {
  .accuracy_popup {
    width: 100% !important;
    max-width: 750px !important;
    padding: 30px 30px;
    box-sizing: border-box;
    .accuracy_popup_info {
      .accuracy_popup_title {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        position: relative;
        box-sizing: border-box;
        h2 {
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0px 0px 15px;
        }
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.4;
          margin: 0px 0px;
        }
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          max-width: 80px;
          height: 2px;
          top: auto;
          bottom: 0px;
          right: auto;
          left: 50%;
          transform: translateX(-50%);
          background-color: #dfeaee;
        }
      }
      .accuracy_popup_field {
        form {
          .accuracy_container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
            row-gap: 20px;
            margin-bottom: 40px;
            .accuracy_field-account {
              label.lbl_wpb_wrapper {
                width: 100%;
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.1;
                color: #777777;
                margin-bottom: 10px;
              }
              input,
              select {
                width: 100%;
                border-radius: 5px;
                border: 1px solid #dfeaee;
                // background: rgba(236, 247, 251, 0.5);
                padding: 16px 13px;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.1;
                outline: none;
                color: #000;
                appearance: none;
                -webkit-appearance: none;
                box-sizing: border-box;
                &::placeholder {
                  color: #9ea8bd;
                }
              }
              &:nth-child(3) {
                grid-column: 1 / 3;
              }
              &:nth-child(4) {
                grid-column: 1 / 3;
              }
              &:nth-child(5) {
                grid-column: 1 / 3;
              }
            }
          }
          .accuracy_button {
            button.accuracy__account {
              width: 100%;
              display: inline-block;
              vertical-align: top;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 1.3;
              padding: 10px 15px;
              color: #ffffff;
              background-color: #24537b;
              border: 1px solid #24537b;
              border-radius: 7px;
              cursor: pointer;
              transition: all 0.4s ease-in-out;
              &:hover {
                background-color: transparent;
                border: 1px solid #24537b;
                color: #24537b;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 676px) {
  .ant-modal-wrap {
    .accuracy_popup {
      padding: 15px 15px;
      .accuracy_popup_info {
        .accuracy_popup_title {
          h2 {
            font-size: 24px;
          }
        }
      }
      .accuracy_popup_info {
        .accuracy_popup_field {
          form {
            .accuracy_container {
              grid-template-columns: repeat(1, 1fr);
              .accuracy_field-account {
                input {
                  font-size: 13px;
                  padding: 10px 10px;
                }
                &:nth-child(3) {
                  grid-column: unset;
                }
                &:nth-child(4) {
                  grid-column: unset;
                }
                &:nth-child(5) {
                  grid-column: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .ant-modal-wrap {
    .accuracy_popup {
      .accuracy_popup_info {
        .accuracy_popup_title {
          h2 {
            font-size: 20px;
          }
        }
      }
    }
  }
}

// New Input placeholder Css

.ant-input-optional {
  position: relative;
  input {
    position: relative;
  }
  .placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    color: #9ea8bd;
    z-index: 0;
    span {
      color: #b3c1de;
    }
  }
  .ant-select .ant-select-selection-placeholder {
    color: #9ea8bd;
  }
}

.ant-input-optional {
  input {
    &:valid {
      + {
        .placeholder {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 676px) {
  .ant-input-optional {
    .placeholder {
      font-size: 13px;
    }
  }
}

div.accuracy_field_country {
  height: 50.5px;
  @media screen and (max-width: 625px) {
    height: 43.4px !important;
  }
  .ant-select-selector {
    padding: 0 13px !important;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dfeaee !important;
    background: rgba(236, 247, 251, 0.5) !important;

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    outline: none;
    color: #000;
    appearance: none;
    -webkit-appearance: none;
    height: 50.5px !important;
    @media screen and (max-width: 625px) {
      height: 43.4px !important;
    }
    span.ant-select-selection-search {
      inset-inline-start: 0px;
      inset-inline-end: 0px;
    }
    span.ant-select-selection-item{
      color: #000;
    }
    input.ant-select-selection-search-input {
      height: 50.5px !important;
      box-shadow: unset;
      background: transparent;
      border: unset;
      &:focus {
        box-shadow: unset;
        outline: none;
      }
      @media screen and (max-width: 625px) {
        height: 43.4px !important;
      }
    }
  }
}
