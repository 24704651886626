.session-Model {
  .session-modal-logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;

    .bd-logo {
      border: 1px solid;
      border-radius: 50%;
      border-color: #ff6163;
      height: 77px;
      width: 77px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      svg {
        font-size: 70px;
        color: #ff6163;
      }
    }
  }

  .session-modal-text {
    text-align: center;

    h3 {
      margin-bottom: 0;
      font-size: 30px;
    }

    p {
      font-size: 16px;
    }
  }

  .session-modal-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 35px;
  }
}
.session_expired_modal {
  .ant-modal-body {
    .ant-modal-confirm-body-wrapper {
      .ant-modal-confirm-body {
        justify-content: space-around;
        span {
          display: none;
        }
      }
    }
  }
}
