.text-center {
  text-align: center;
}

// premium Section

.premium_sec_wrap {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-top: 30px;
}

.premium_box_wrap {
  display: flex;
  flex-wrap: wrap;

  .premium-menu-width {
    max-width: 28%;
    flex: 0 0 28%;
  }

  .premium-section-width {
    max-width: 72%;
    flex: 0 0 72%;
    padding-left: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .premium_box_wrap {
    .premium-menu-width {
      max-width: 100%;
      flex: 0 0 100%;
      order: 1;
      .desktop-menu {
        display: none;
      }
      .menu-mobile {
        display: block;
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 1000000;
        height: 50px;
        margin: 0;
        left: 0;
      }
      .menu-mobile.active {
        display: none;
      }
    }

    .premium-section-width {
      max-width: 100%;
      flex: 0 0 100%;
      padding-left: 0px;
    }
  }
}

@media only screen and (min-width: 900px) {
  .premium_box_wrap .premium-menu-width {
    height: fit-content;
    position: sticky;
    top: 20px;
  }
}

// Unlock Title Css

.unlock_title {
  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin: 0px 0px 30px;
    padding-bottom: 10px;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      max-width: 30px;
      height: 3px;
      top: auto;
      right: auto;
      left: 0px;
      bottom: 0px;
      background-color: #000000;
    }
  }
}

.unlock-description {
  h2 {
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #777777;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin: 0px 0px 20px;
  }
  .download_menu_option {
    align-items: flex-start !important;
  }
}

@media only screen and (max-width: 992px) {
  .unlock_title {
    h2 {
      font-size: 20px;
    }
  }
}

// Unlock Section Css

.ds-unlock-content {
  display: flex;
  column-gap: 30px;
}

.reputation-phn-content {
  width: 100%;

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    color: #777777;
    margin: 0px 0px 20px;

    a {
      color: #24537b;
    }
  }
  p.mobile_number {
    display: inline-block;
    direction: ltr;
    margin-bottom: 0px;
  }
  ul {
    &.reputation_listing_area {
      padding: 0px 0px;
      margin: 0px 0px;
      list-style: none;

      li {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        color: #000000;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid rgba(36, 83, 123, 0.2);
        padding-bottom: 15px;
        @media only screen and (min-width: 972px) {
          &:nth-last-child(-n + 2) {
            border: unset;
            padding-bottom: 0px;
            margin-bottom: 0px;
          }
        }

        span {
          margin-left: auto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}

ul {
  &.reputation_listing_area {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 15px;

    li {
      width: 48%;
      flex: 0 0 48%;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ds-unlock-content {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 30px;
  }

  .reputation-phn-content {
    p {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 455px) {
  .reputation-phn-content {
    ul {
      &.reputation_listing_area {
        li {
          width: 100%;
          flex: 0 0 100%;

          &:nth-last-child(2) {
            border-bottom: 1px solid rgba(36, 83, 123, 0.2);
            padding-bottom: 15px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1085px) {
  .reputation-phn-content {
    ul.reputation_listing_area {
      width: 100%;
      display: block;
      li {
        width: 100%;
      }
    }
  }
}

//Phone Type Section Css

.ds--secondary-head {
  width: 100%;
  border-radius: 5px;
  border: 1px dashed #000;
  background: rgba(217, 217, 217, 0.01);
  padding: 15px 20px;
  display: flex;
  align-items: center;
  column-gap: 30px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .ds--phone-copy {
    width: 100%;
    max-width: 40px;
    height: 40px;
    text-align: center;
    background-color: rgba(36, 83, 123, 0.1);
    border-radius: 50%;
    order: 1;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      display: inherit;
    }
  }

  .ds--phone-wrap {
    h3 {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.3;
      color: #000000;
      margin: 0px 0px 15px;
    }

    a {
      color: #000000;
    }

    .ds--phone-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 20px;

      p,
      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2;
        color: #000000;
        display: inline-block;
        vertical-align: top;
        margin: 0px 0px;
      }

      span {
        color: #777777;
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .ds--secondary-head {
    flex-wrap: wrap;
    row-gap: 15px;

    .ds--phone-copy {
      order: unset;
    }

    .ds--phone-wrap {
      width: 100%;

      h3 {
        font-size: 16px;
        margin: 0px 0px 10px;
      }

      .ds--phone-content {
        justify-content: space-between;
      }
    }
  }
}

// Possible Photos Section Css

.ds--unlock__title {
  > h2 {
    font-size: 24px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 575px) {
  .ds--unlock__title {
    > h2 {
      font-size: 18px;
    }
  }
}

.ds--unlock-photos {
  .ds-phone-slider {
    display: block;
    .slick-prev {
      left: -10px;
    }

    .slick-next {
      right: 10px;
    }

    .slick-list {
      width: 100%;
      max-width: 663px;
      margin: 0px auto;

      .slick-slide {
        text-align: center;
        padding: 0px 6px;
        div {
          &:focus-visible {
            outline: none !important;
          }
        }
        img {
          width: 100%;
          height: 250px;
          text-align: center;
          margin: 0px auto;
          cursor: pointer;
          object-position: top center;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }

    .slick-prev,
    .slick-next {
      transform: translateY(-50%);
      z-index: 999;
    }

    .slick-prev::before,
    .slick-next::before {
      font-size: 0px;
    }
  }
  // My css Mahesh

  .ds-phone-grid {
    display: none;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 16px;
    .grid-item {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 6px;
        aspect-ratio: 1;
      }
    }
  }
}

.faqs_description {
  display: none;
}

.active {
  display: block;
}

$color_1: #000000;
$color_2: #777777;
$background-color_1: #ecf7fb;
$background-color_2: unset;

.ds--accordion-collapse {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0px;
  }

  .ant-collapse-item {
    width: 100%;
    background-color: $background-color_1;
    border: unset !important;
    border-radius: 8px !important;
    padding: 15px 0px;

    .ant-collapse-content {
      background-color: $background-color_2;
      border: unset !important;

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color_2;
        margin: 0px 0px;
      }

      .ant-collapse-content-box {
        padding: 15px 0px 0px;
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
        margin: 15px 15px 0px;
      }
    }
    .ant-collapse-header {
      margin: 0px 15px;
      border-radius: 0px;
      padding: 0;

      span.ant-collapse-header-text {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2;
        color: $color_1;
        display: inline-block;
        vertical-align: top;
      }

      .ant-collapse-expand-icon {
        img.ant-collapse-arrow {
          width: 100%;
          max-width: 15px;
          height: 15px;
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      .ant-collapse-expand-icon {
        img {
          transform: rotate(180deg);
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }
  .ant-collapse {
    border: unset;
  }
}

// Possible Jobs Css

.ds--job-box {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  border-radius: 5px;
  border: 1px dashed #000;
  background: rgba(236, 247, 251, 0.3);
  padding: 20px 20px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1;
    color: #000000;
    margin: 0px 0px 15px;

    a {
      color: #000000;
    }
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    margin: 0px 0px;
    color: #777777;
    cursor: pointer;
    display: flex;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    b {
      width: 100%;
      max-width: 20%;
      display: inline-block;
      vertical-align: top;
      padding-right: 10px;
      color: #000000;
      @media only screen and (max-width: 767px) {
        max-width: 40%;
        margin-bottom: 3px;
      }
      @media only screen and (max-width: 535px) {
        max-width: 100%;
      }
    }
  }
}

.email--underline {
  p {
    a {
      color: #000000;
      text-decoration: underline;
    }
  }
}

p.address-btn {
  border-radius: 5px;
  background: #ecf7fb;
  padding: 10px 15px;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1;
  color: rgba(36, 83, 123, 1);

  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
}

.email--addresses--btn {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 15px;
}

@media only screen and (max-width: 676px) {
  .ds--job-box {
    padding: 15px 15px;

    h3 {
      font-size: 18px;
    }
  }
}

// Unlock Info Css

.light-bg-wrap {
  width: 100%;
  padding: 20px 20px;
  background-color: rgba(236, 247, 251, 0.3);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2) inset;
  margin-bottom: 30px;
  border-radius: 10px;
}

.unlock-information-sec {
  width: 100%;
  display: inline-block;
  vertical-align: top;

  h3 {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    margin: 0px 0px 15px;
    @media only screen and (max-width: 575px) {
      width: calc(100% - 45px);
    }
  }

  > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    color: #777777;
  }
}

@media only screen and (max-width: 676px) {
  .unlock-information-sec {
    img {
      margin-bottom: 15px;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
}

// Alert Section css

.ds--alert-box {
  display: flex;
  column-gap: 50px;
  padding: 10px;
  @media only screen and (max-width: 575px) {
    column-gap: 20px;
    padding: 0px 0px;
  }
  .alert--content-area {
    width: 100%;
    max-width: 110px;
    height: 110px;
    border-radius: 50%;
    text-align: center;
    background-color: #ecf7fb;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 675px) {
      max-width: 80px;
      height: 80px;
    }
    .alert--img {
      img {
        @media only screen and (max-width: 675px) {
          width: 100%;
          max-width: 35px;
        }
      }
    }
  }

  .unlock-information-sec {
    > p {
      width: 100%;
      max-width: 450px;
      line-height: 1.4;
      margin: 0px 0px 20px;
      @media only screen and (max-width: 575px) {
        margin-bottom: 5px;
      }
    }
  }
}

.ds-alert-search {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  padding: 15px 15px;
  @media only screen and (max-width: 575px) {
    padding: 10px 10px;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    color: #24537b;
    margin: 0px 0px;

    span {
      color: #000000;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
      @media only screen and (max-width: 575px) {
        margin-bottom: 5px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .ds--alert-box {
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 10px;
    padding: 0px 0px;
  }
}

// Complaints Section

a.complaints__btn {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  padding: 13px 30px;
  background-color: #24537b;
  color: #ffffff;
  border: 1px solid #24537b;
  border-radius: 100px;
  width: 100%;
  max-width: 200px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  transition: all 0.4s ease-in-out;
}

.complaints__btn {
  &:hover {
    background-color: transparent;
    border-radius: #24537b;
    color: #24537b;
  }

  width: 100%;
  display: inline-block;
  vertical-align: top;
}

// .complaints-img {
//   img {
//     width: 100%;
//     max-width: 200px;
//     display: inline-block;
//     vertical-align: top;
//     @media only screen and (max-width: 575px) {
//       max-width: 140px;
//     }
//     @media only screen and (max-width: 400px) {
//       max-width: 100px;
//     }
//   }
// }

// Phone Location
.trs__bg_phone {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
// new added
.phone__location_map {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  border-top: 1px solid rgb(0 0 0 / 10%);
  padding-top: 20px;

  .ds_location__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 25px;
    margin-bottom: 25px;

    .location_map__text {
      span {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.15;
        width: 100%;
        display: block;
        vertical-align: top;
        margin-bottom: 8px;
        cursor: pointer;
      }

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.14;
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin: 0px 0px;
        color: #777777;
      }
    }
  }
}

.ds_location__map {
  iframe {
    border-radius: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .trs__bg_phone {
    .phone__location_map {
      .ds_location__info {
        column-gap: 15px;
        margin-bottom: 15px;

        .location_map__text {
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}

// Social Profile Css

.social-profiles--boxes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 5px;
  border: 1px dashed #24537b;
  background: rgba(236, 247, 251, 0.3);
  padding: 15px 20px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .social-profiles-btn {
    margin-left: auto;

    p {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.14;
      color: #ffffff;
      border-radius: 4px;
      background: #24537b;
      border: 1px solid #24537b;
      cursor: pointer;
      a {
        padding: 11px 11px;
        color: #ffffff;
        img {
          display: inline-block;
          vertical-align: text-bottom;
          margin-left: 2px;
        }
      }
    }
  }

  .social-profiles-content {
    margin-left: 20px;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.15;
      width: 100%;
      display: block;
      vertical-align: top;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1;
      margin: 0px 0px;
      color: #777777;
      white-space: normal;
      word-break: break-word;
    }
  }
}

@media only screen and (max-width: 460px) {
  .social-profiles--boxes {
    justify-content: flex-start;
    row-gap: 15px;

    .social-profiles-btn {
      width: 100%;

      a {
        justify-content: center;
      }
    }
  }
}

// Address History Css

.address-history-map {
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(217 217 217 / 70%);
    margin: 0px 0px 20px;
  }
}

.address-history-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 20px;

  .history--box-cmt {
    display: flex;
    align-items: center;
    column-gap: 15px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px 15px;
    @media only screen and (max-width: 460px) {
      flex-wrap: wrap;
    }
    .history--cmt {
      cursor: pointer;
      h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.1;
        color: #24537b;
        margin: 0px 0px;
      }
    }
  }
}
@media only screen and (max-width: 460px) {
  @media only screen and (max-width: 460px) {
    .address-history-box {
      .history--box-cmt {
        .history--cmt {
          width: 100%;
          margin-top: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 676px) {
  .address-history-box {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 15px;

    .history--box-cmt {
      padding: 15px 15px;
    }
  }
}

@media only screen and (max-width: 350px) {
  .history--box-cmt {
    justify-content: center;
    row-gap: 15px;

    .history--cmt {
      width: 100%;
      text-align: center;

      h3 {
        br {
          display: none;
        }
      }
    }
  }
}

// Monitor Report Css

.monitor-cmp-wrap {
  text-align: center;
  border-radius: 10px;
  border: 1px dashed #24537b;
  background: rgba(217, 217, 217, 0.01);
  padding: 15px 15px;

  h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    color: #777777;
    margin: 15px 0px 15px;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: #777777;
    margin: 0px 0px 20px;
  }

  .complaints__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;

    a.complaints__btn {
      max-width: 270px;
      font-weight: 300;
      padding: 11px 10px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .monitor-cmp-wrap {
    .complaints__btn {
      a.complaints__btn {
        padding: 11px 10px;
        font-size: 15px;
        flex-wrap: unset;
        flex-direction: unset;
        row-gap: unset;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .monitor-cmp-wrap {
    .complaints__btn {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      row-gap: 15px;

      a.complaints__btn {
        max-width: 100%;
      }
    }
  }
}

// Data Checker

.data_box_info {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  border-radius: 10px;
  background-color: #fafdfe;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  .safedata_title {
    padding: 15px 15px;
    border-radius: 5px 5px 0px 0px;
    h3 {
      width: 100%;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.1;
      margin: 0px 0px;
      text-align: center;
      color: #ffffff;
      img {
        width: 100%;
        max-width: 20px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .safedata_description {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding: 30px;
    p {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #24537b;
      margin: 0px 0px;
      text-align: center;
      strong {
        color: #ee404c;
      }
    }
  }
}
.data_box_info.safe-data-box {
  .safedata_title {
    background-color: #13321d;
  }
}
.data_box_info.leaked-data-box {
  .safedata_title {
    background-color: #cc0000;
  }
}

@media only screen and (max-width: 575px) {
  .data_box_info {
    .safedata_description {
      padding: 15px 15px;
      p {
        font-size: 16px;
      }
    }
  }
}

// Main Accordion css

.track__accordion {
  border: 1px dashed #24537b;
  border-radius: 5px;

  .ant-collapse-item {
    .ant-collapse-content {
      .ant-collapse-content-box {
        margin-left: 0px;
        margin-right: 0px;
        background-color: #ffffff;
        padding: 15px 15px;
        border-radius: 0px 0px 5px 5px;
      }
    }
  }

  .ant-collapse-item.ant-collapse-item-active {
    padding-bottom: 0px;
  }

  .track_description {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    .track_logo {
      img {
        height: 50px;
      }
    }

    .track_date {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 15px;
      row-gap: 15px;

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;
        color: #777777;
      }
    }

    .track_countries {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
      padding-bottom: 20px;
      row-gap: 15px;
    }

    .track_countries {
      ul {
        padding: 0px 0px;
        margin: 0px 0px;
        list-style: none;
        display: flex;
        column-gap: 15px;

        li {
          img {
            width: 100%;
            max-width: 17px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
          }

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.4;
          color: #777777;
        }
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;
        color: #777777;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .track__accordion {
    .track_description {
      .track_countries {
        ul {
          display: block;
          column-count: 2;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .track__accordion {
    .track_description {
      .track_countries {
        ul {
          column-count: 1;
          li {
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 675px) {
  .social-profiles--boxes {
    justify-content: flex-start;

    .social-profiles-btn {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 460px) {
  .social-profiles--boxes {
    .social-profiles-content {
      width: 100%;
      margin-left: 0px;
    }
  }

  .social-profiles--boxes {
    .social-profiles-btn {
      p {
        margin: 0px 0px;
      }
    }
  }
}

#nuisanceCallDetection {
  .ds--alert-box {
    .unlock-information-sec {
      > p {
        max-width: 100%;
      }
    }
  }
}

// No Data Found SCSS

.data_notfound {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  border-radius: 5px;
  border: 1px dashed #000000;
  background: rgba(236, 247, 251, 0.3);
  padding: 20px 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  color: #000000;
  margin: 0px 0px;
  @media only screen and (max-width: 460px) {
    padding: 20px 10px;
  }
  img {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
  }
}

// New Reputation Chart Scss

.ds-unlock-content {
  .reputation__risk_score {
    flex-direction: column;
    margin-bottom: 15px;

    p.reputation__description_wrapper {
      text-align: center;
    }

    .wpl__reputation_wrapper {
      position: relative;
      width: 300px;
      height: 130px;

      .wpl__gauge_wrapper {
        height: 100%;
      }

      .wpl__gauge_info {
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        gap: 3px;
        display: flex;
        flex-direction: column;

        p.wpl__risk_Ppercentage {
          font-size: 13px;
          font-weight: 700;
          margin: 0px;
          color: rgb(0, 0, 0);
        }

        p.wpl__risk_leavel {
          margin: 0px;
          font-size: 14px;
          color: #76c651;
          font-weight: 700;
        }
      }

      .wpl__risk_category {
        width: 100%;
        text-align: center;
        border-top: 1px solid #f3f3f3;
        padding-top: 5px;

        .wpl__risk_info {
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          .wpl__category_low {
            p.wpl__lower_area {
              color: rgb(118, 198, 81);
              font-size: 12px;
              margin: 0px;
            }
          }

          .wpl__category_medium {
            p.wpl__medium_area {
              color: rgb(255, 164, 38);
              font-size: 11px;
              margin: 2px 0px 0px;
            }
          }

          .wpl__category_high {
            p.wpl__higher_area {
              color: rgba(255, 0, 0, 0.6);
              font-size: 12px;
              margin: 0px;
            }
          }

          .wpl__risk_area {
            span {
              color: rgb(0, 0, 0);
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .ds-unlock-content {
    .reputation__risk_score {
      margin-bottom: 15px;
    }
  }
}

.download_PDF_menu {
  position: relative;
  .premium_toggle {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 6px;
    border-radius: 3px;
    background-color: #ecf7fb;
    padding: 6px 10px;
    a {
      display: flex;
      height: 18.18px;
      align-items: center;
      img {
        margin: 0;
        width: 21px;
        height: 4px;
      }
    }
  }
}

// Toggle Button SCSS

.toggle_area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;

  .premium_toggle {
    border-radius: 3px;
    background-color: #ecf7fb;
    padding: 6px 10px;
    p {
      margin: 0;
      cursor: pointer;
    }

    img {
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0px;
    }
  }
}

.unlock-information-sec {
  h3.toggle_area {
    width: 100%;
  }
}

.unlock-information-sec {
  h3.toggle_area {
    width: 100%;
  }
}
.ant-modal-wrap {
  .multiple_popup__wrap {
    .wpb_report_popup {
      .mlt_popup__subscribe {
        .policy__content_wrap {
          align-items: flex-start;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .high__lang_wrapper {
    button.phone-btn-info {
      padding: 8px 55px 10px 8px;
    }
  }
}

#relationshipsAssociates,
#possiblePhotos {
  .ds--accordion-collapse {
    p.mobile_number {
      display: inline;
      direction: ltr;
    }
  }
}
