.overview_menu-title {
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  display: inline-block;
  vertical-align: top;

  .menu_item {
    a {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      color: #000000;
      padding: 10px 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.4s ease-in-out;
    }
  }
}

.menu_item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 2px;
  transition: all 0.4s ease-in-out;

  &:last-child {
    margin-bottom: 0px;
  }
}

.overview_menu-title .menu_item:hover {
  border-radius: 5px;
  background: rgba(36, 83, 123, 0.1);
}

// .menu_item_active {
//   border-radius: 5px;
//   background: rgba(36, 83, 123, 0.1);
// }

.menu_bg_wrap {
  border-radius: 10px;
  background: #ffffff;
  margin-bottom: 30px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
}
.menu-mobile {
  display: none;
  .fixed-mobile-menu {
    color: #ffffff;
    background-color: #24537b;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    .mobile-active-menu {
      display: flex;
      gap: 15px;
      align-items: center;
      .mobile-menu-icon {
        font-size: 30px;
      }
      p {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 20.61px;
      }
    }
    .mobile-menu-updown-icon {
      font-size: 27px;
    }
  }
}
.overview_menu_head {
  background-color: #24537b;
  border-radius: 10px 10px 0px 0px;
  padding: 12px 20px;

  span {
    a {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.4;

      img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
}
.sidebar-mobile-menu {
  .ant-drawer-body {
    padding: 20px;
    margin-bottom: 30px !important;
    .menu-head-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      svg {
        font-size: 32px;
        fill: #24537b;
      }
      p {
        color: #777777;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.32px;
        margin: 0;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .overview_menu-title {
    padding: 8px 8px;

    .menu_item {
      a {
        font-size: 14px;
        padding: 8px 8px;
      }
    }
  }
  .menu-title-mobile {
    padding: 0;
  }
  .overview_menu_head {
    span {
      a {
        font-size: 16px;
      }
    }
  }
}

.report__overview_menu {
  .premium-menu-width {
    .overview_menu-title {
      .menu_item {
        > a {
          img {
            opacity: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .ant-drawer-open {
    .ant-drawer-content {
      .ant-drawer-body {
        padding-top: 0px;
        .menu-head-mobile {
          position: fixed;
          min-height: 60px;
          height: 100%;
          max-height: 60px;
          width: 100%;
          left: 0px;
          right: auto;
          top: auto;
          bottom: auto;
          background-color: #ffffff;
          padding: 20px 0px;
          margin-bottom: 0px;
        }
        .menu-title-mobile {
          margin-top: 100px;
        }
      }
    }
  }
}

.overview_menu-title {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-gutter: stable;
  height: 100%;
  max-height: calc(100vh - 100px);
}

.overview_menu-title::-webkit-scrollbar {
  width: 12px;
}

.overview_menu-title::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 6px;
}

.overview_menu-title::-webkit-scrollbar-track {
  background: #f1f1f1;
}

// Overview Scrollbar SCSS

.overview_menu-title {
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background: #24537b;
		border-radius: 10px;
		&:hover {
			background: #24537b;
		}
	}
}