.users-main {
  .users-table {
    margin-top: 50px;
    overflow: auto;
  }
}
.actions {
  display: flex;
  gap: 10px;
  .action-svg {
    font-size: 25px;
    cursor: pointer;
  }
  .blue {
    color: #1664fa;
  }
  .red {
    color: #ff000091;
  }
  .green {
    color: green;
  }
}
.user-delete-confirm-model {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .user-delete-text {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
  }
  .user-delete-button {
    padding: 0 30px;
    margin-right: 10px;
    margin-top: 30px;
  }
  .user-delete-yes-button {
    padding: 0 30px;
    margin-right: 10px;
    margin-top: 30px;
  }
}

.add-uder-form {
  .add-userinputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .input {
      p {
        margin: 0;
        color: red;
      }
    }
    input {
      height: 40px;
    }
    .add-user-title {
      p {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        margin: 0;
        color: #1664fa;
      }
    }
    .add-user-df {
      display: flex;
      gap: 20px;
    }
    .add-user-submit {
      .add-user-button {
        button {
          width: 100%;
          border: none;
          background-color: #1664fa;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          padding: 0.75rem 1.5rem;
          border-radius: 1.5rem;
          text-transform: capitalize;
          font-size: 0.85rem;
          letter-spacing: 1px;
          gap: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
.anticon-warning {
  font-size: 100px;
}
