.contact-main-section {
  .contact-bg {
    .contact-container {
      max-width: 1650px;
      width: 100%;
      margin: 0 auto;
      .contact-contant {
        padding: 50px;

        .title-contant {
          h1 {
            font-size: 50px;
            margin: 0;
          }
        }
      }
    }
  }
}
.box-contact {
  margin-top: 30px;
  padding: 25px 30px;
  border: 0.5px solid;
  border-color: rgba(151, 151, 151, 0.642);
  border-radius: 25px;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 480px) {
    padding: 25px 15px;
  }
  .box-contact-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .box-contant-text {
    color: #868686;
    font-size: 20px;
  }
}
.df-contact {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  .contact-left-contant {
    width: 50%;
  }
  .contact-right-contant {
    width: 35%;
    text-align: center;
  }
}
@media only screen and (max-width: 1100px) {
  .df-contact {
    .right-image {
      img {
        width: 300px;
        height: auto;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .df-contact {
    .contact-right-contant {
      .right-image {
        img {
          width: 300px;
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .df-contact {
    margin-bottom: 70px;
    .contact-left-contant {
      width: calc(100% - 100px) !important;
    }
    .contact-right-contant {
      display: none;
      img {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .df-contact {
    .contact-left-contant {
      width: calc(100% - 30px) !important;
    }
  }
  .contact-bg {
    .df-contact {
      margin-bottom: 70px !important;
    }
  }
}
