.monitoring__report_modal {
	width: 100%;
	max-width: 575px;
	padding: 40px 40px;
	box-sizing: border-box;
	text-align: center;
	.monitoring__report_info {
		width: 100%;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 40px;
	}
	.monitoring__report_img {
		width: 100%;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 20px;
		img {
			width: 100%;
			max-width: 100px;
			height: 100%;
		}
	}
}
p.modal__popup_desc {
	width: 100%;
	display: inline-block;
	vertical-align: top;
	font-size: 16px;
	line-height: 1.3;
	color: #777777;
	margin-top: 0px;
	margin-bottom: 18px;
    a {
        color: #24537B;
    }
	&:last-of-type {
		margin-bottom: 0px;
	}
}
@media only screen and (max-width:767px) {
	.monitoring__report_modal {
		padding: 20px 20px;
	}
}
.report__popup_pay_btn {
	width: 100%;
	display: inline-block;
	vertical-align: top;
	p {
		margin: 0px 0px;
		button {
			width: 100%;
			display: inline-block;
			vertical-align: top;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 1.3;
			color: #ffffff;
			padding: 10px 15px;
			border-radius: 100px;
			background-color: #24537b;
			border: 1px solid #24537b;
			text-align: center;
			transition: all 0.4s ease-in-out;
			margin-bottom: 16px;
			cursor: pointer;
			&:hover {
				color: #24537B;
				border-color: #24537B;
				background-color: transparent;
			}
		}
		button.report_bg_color {
			&:hover {
				color: #24537B;
				border-color: #24537B;
				background-color: transparent;
			}
			color: #24537B;
			background-color: #E2F4F9;
			border: 1px solid rgba(226, 244, 249, 0.10);
			margin-bottom: 0px;
		}
	}
}
