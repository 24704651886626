.faqs__accordion_wrapper {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 80px 0px;
  .faqs__accordion_title {
    width: 100%;
    max-width: 650px;
    margin: 0px auto 50px;
    text-align: center;
    h2 {
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000000;
      margin: 0px 0px 20px;
    }
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #777777;
      margin: 0px 0px;
    }
  }
  .inner__collapse_accordion {
    .faqs__collapse_accordion {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .ant-collapse {
        border-radius: 10px;
        background: #ffffff;
        .ant-collapse-item {
          .ant-collapse-header {
            padding: 20px 20px;
            align-items: center;
            span.ant-collapse-header-text {
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
              color: #263238;
            }
          }
          .ant-collapse-content {
            border-top: unset;
          }
          .ant-collapse-content-box {
            padding: 0px 20px 20px;
            p {
              font-size: 17px;
              font-style: normal;
              font-weight: 400;
              line-height: 1.5;
              color: #555555;
              margin: 0px 0px;
            }
          }
        }
      }
    }
  }
  .faqs__collapse_accordion {
    .ant-collapse-header {
      .ant-collapse-expand-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 30px;
        height: 30px;
        background-color: #24537b;
        padding: 0px 0px !important;
        border-radius: 100%;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .faqs__accordion_wrapper {
    padding: 60px 0px;
    .faqs__accordion_title {
      h2 {
        font-size: 38px;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .faqs__accordion_wrapper {
    padding: 40px 0px;
    .faqs__accordion_title {
      margin-bottom: 30px;
      h2 {
        font-size: 32px;
      }
    }
  }
}
@media only screen and (max-width: 675px) {
  .faqs__accordion_wrapper {
    .faqs__accordion_title {
      h2 {
        font-size: 28px;
      }
    }
    .inner__collapse_accordion {
      .faqs__collapse_accordion {
        .ant-collapse {
          .ant-collapse-item {
            .ant-collapse-header {
              span.ant-collapse-header-text {
                font-size: 16px;
              }
            }
            .ant-collapse-content-box {
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .faqs__accordion_wrapper {
    .inner__collapse_accordion {
      .faqs__collapse_accordion {
        .ant-collapse {
          .ant-collapse-item {
            .ant-collapse-header {
              padding: 10px 10px;
            }
            .ant-collapse-content-box {
              padding: 0px 10px 10px;
            }
          }
        }
      }
    }
    .faqs__collapse_accordion {
      .ant-collapse-header {
        .ant-collapse-expand-icon {
          max-width: 24px;
          height: 24px;
          img.ant-collapse-arrow {
            width: 100% !important;
            max-width: 17px !important;
          }
        }
      }
    }
  }
}
