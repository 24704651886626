.header-main-section {
  opacity: 1;
  .track-header {
    top: 0 !important;
    padding: 16px 0 !important;
  }
  .header {
    position: absolute;
    width: 100%;
    padding: 25px 0;
    left: 0px;
    top: 50px;
    background: #ffd4cf82;
    @media only screen and (max-width: 767px) {
      padding: 12px 0;
      top: 20px;
    }
    .header-df {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      max-width: 1525px;
      margin: 0 auto;
      justify-content: space-between;
      .header-main-logo {
        margin-left: 50px;
      }
      .header-menu-nd-login {
        display: flex;
        align-items: center;
        margin-right: 50px;
        .header-menu {
          display: flex;
          gap: 35px;
          align-items: center;
          height: 100%;
          color: white;
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
          text-align: right;
          .track-menu {
            font-size: 0.85rem;
            line-height: 1.3;
            letter-spacing: 1px;
            font-weight: 500;
          }
          li {
            cursor: pointer;
            a {
              color: white;
              text-decoration: none;
              &::after {
                content: "";
                display: block;
                width: 0;
                height: 3px;
                background: #fff;
                transition: width 0.3s;
              }
              &:hover::after {
                width: 100%;
              }
            }
          }
        }
        .header-lang-drop {
          color: white;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          margin-left: 20px;
          li {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 7px;
            div {
              display: flex;
              align-items: center;
              gap: 2px;
              img {
                object-fit: cover;
                border-radius: 100%;
              }
            }
          }
        }
        .header-login-btn {
          margin-left: 30px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          a {
            text-decoration: none;
          }
          transition: 0.3s;
          &:hover {
            button {
              box-shadow: 0 0 5px 1px #1664fa;
              background-color: #fff;
              color: #1664fa;
            }
          }
          .hl_cta_wrap {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding: 10px 30px;
            border: 1px solid currentColor;
            border-radius: 14px;
            transition: 0.5s ease-in-out;
            z-index: 1;
            color: #1664fa;
            font-weight: 700;
            font-size: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              color: #fff !important;
              border-color: #1664fa;
            }
          }
        }
        .responsive-header-btn {
          margin-left: 30px;
          display: none;
          svg {
            font-size: 44px;
          }
        }
        li {
          list-style: none;
        }
      }
    }
  }
}

.ant-dropdown {
  min-width: 50% !important;
  .ant-dropdown-menu {
    background-image: linear-gradient(to right, #e558a0, #726bf3c9);
    li {
      a {
        color: white !important;
        font-size: 20px !important;
      }
      color: white !important;
      font-size: 20px !important;
    }
  }
}
.header-login-res-btn {
  display: none;
}
.modal-container {
  height: 100%;
  max-height: 570px;
  margin-top: 10px;
  width: 100%;
  overflow-x: visible;
  overflow-y: scroll;
  .modal-title {
    text-align: center;
    font-size: 1.75rem;
  }
  .modal-languages {
    .df-modal-language {
      display: flex;
      flex-direction: column;
      .lang {
        padding: 10px 0;
        text-align: center;
        border-top: 0.5px solid;
        border-color: #f1f1f5;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        cursor: pointer;
        span {
          font-size: 20px;
          font-weight: 400;
          color: #000;
        }
        &:hover {
          span {
            color: rgba(80, 71, 239, 0.8509803922);
          }
        }
      }
      .flag {
        width: 20px;
        img {
          object-fit: cover;
          border-radius: 100%;
        }
      }
      .active {
        width: 100%;
        border: 0;
        border-radius: 1.5rem;
        box-shadow: 0 0px 5px #24537b;
        background-color: #24537b;
        position: relative;
        span {
          color: #fff;
        }
        &:hover {
          span {
            color: #fff;
          }
        }
      }
    }
  }
}

.ant-modal-body {
  font-size: 14px;
  line-height: 1.5714285714285714;
  word-wrap: break-word;
  max-height: 700px;
  overflow: overlay;
  margin-top: 0;
  padding-top: 38px !important;
  .modal-title {
    p {
      margin: 0 0 25px;
    }
  }
}

@media only screen and (max-width: 635px) {
  .header-login-btn {
    display: none !important;
  }
  .header-login-res-btn {
    display: block !important;
  }
}
@media only screen and (max-width: 1024px) {
  .header-menu {
    display: none !important;
  }
  .responsive-header-btn {
    display: block !important;
    svg {
      font-size: 44px;
    }
  }
  .header-login-res-btn {
    button {
      cursor: pointer;
      padding: 10px 30px;
      outline: none;
      border: none;
      border-radius: 7px;
      background: #1664fa;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: white;
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        margin-bottom: -1px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .header-df {
    .header-main-logo {
      margin-left: 15px !important;
    }
    .header-menu-nd-login {
      margin-right: 15px !important;
      .responsive-header-btn {
        margin-left: 10px !important;
      }
    }
  }
}

//reversly header

.NewContainer {
  max-width: 1290px;
  margin: 0px auto;
  padding-left: 15px;
  padding-right: 15px;
}

// New Header SCSS

.NewHomeHeader {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 30px 0px;
  .Header_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .HeaderMenuList {
      ul {
        display: flex;
        list-style: none;
        padding: 0px 0px;
        margin: 0px 0px;
        li {
          display: inline-block;
          vertical-align: top;
          padding: 0px 22px;
          a {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #152536;
            font-family: "Inter", serif;
          }
        }
      }
    }
    .HeaderLogo__Button {
      display: inline-block;
      vertical-align: top;
      height: 100%;
      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        padding: 14px 50px;
        border-radius: 5px;
        background-color: #24537b;
        border: 1px solid #24537b;
        color: #ffffff;
        font-family: "Inter", serif;
        display: inline-block;
        vertical-align: top;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .NewHomeHeader {
    .Header_wrapper {
      .HeaderLogo__wrapper {
        img.HeaderLogo {
          width: 100%;
          max-width: 140px;
        }
      }
      .HeaderLogo__Button {
        a {
          padding: 10px 30px;
        }
      }
    }
  }
}

.newMenuDropDown {
  .header-login-res-btn {
    button {
      background-color: #ffffff;
      color: #24537b;
    }
  }
  .ant-dropdown-menu {
    background-color: #24537b;
    background-image: unset;
  }
}

.CallToAction__Content {
  .reverslyHomeBannerInput {
    position: relative;
    p.invelid-number-error {
      align-items: center;
      background: #ff3855;
      border-radius: 8px;
      box-shadow: 0 4px 5px 2px #fd8a97;
      color: #ffffff;
      display: flex;
      left: 20%;
      margin-bottom: 0;
      padding: 6px 15px;
      position: absolute;
      top: -40px;
      width: max-content;
    }
  }
}
.theme_dir__rtl {
  .CallToAction__Content {
    .reverslyHomeBannerInput {
      p.invelid-number-error {
        left: auto;
        right: 20%;
      }
    }
  }
}
