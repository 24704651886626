.lp-tutorial-section {
  width: 100%;
  height: 100%;
  // min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .tutorial_bg_wrap {
    width: 100%;
    max-width: 480px;
    margin: 0px 30px;
    padding: 30px 30px;
    border-radius: 20px;
    background: #fff;

    // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
    .tutorial__img {
      > img {
        width: 100%;
        max-width: 420px;
      }
    }

    .count__number {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin: 0px 0px 20px;

      .count__listing {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5;
        color: #2a313d;
        margin: 0px 0px;
      }
    }

    .tutorial__cmt {
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        color: #2a313d;
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin: 0px 0px 30px;

        strong {
          font-weight: 700;
        }
      }
    }

    button.btn-primary {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      border-radius: 5px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.4;
      padding: 14px 15px;
      color: #24537b;
      background-color: #e2f4f9;
      text-transform: uppercase;
      border: 1px solid #e2f4f9;
      cursor: pointer;
      transition: all 0.4s ease-in-out;

      &:hover {
        color: #24537b;
        background-color: transparent;
        border: 1px solid #e2f4f9;
      }
    }

    button.btn-primary.btn-border {
      color: #24537b;
      background-color: transparent;
      border: 1px solid #e2f4f9;
      margin-top: 15px;

      &:hover {
        background-color: #e2f4f9;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .lp-tutorial-section {
    .tutorial_bg_wrap {
      margin: 0px 15px;
      padding: 0px 15px 15px;

      .tutorial__cmt {
        p {
          font-size: 14px;
          margin-bottom: 15px;
        }
      }

      button.btn-primary {
        padding: 10px 15px;
      }
    }
  }
}

// Tutorial  Step 1

.lp-tutorial-section {
  box-sizing: border-box;

  .tutorial__img {
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 420px;
    @media only screen and (max-width: 475px){
      width: auto;
      margin: 0px -15px;
  }
    .ln_tutorial_mockup {
      display: block;
      width: 100%;
      max-width: 138.5px;
      margin: 0px auto;
      position: relative;
      z-index: 1;
      height: 323.5px;
      padding-top: 35px;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        left: calc(50% + 14.3px);
        right: auto;
        top: 0px;
        bottom: auto;
        width: 420px;
        height: 420px;
        display: block;
        transform: translateX(-50%);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url("../../../../assets/images/tutorial_phone_img.svg");
      }

      .ln_tutorial_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;

        .ln_tutorial_date {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          margin-bottom: 6px;

          p {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.3;
            margin: 0px 0px;
            color: #ffffff;
          }
        }

        .ln_tutorial_box {
          width: 100%;
          max-width: 150px;
          display: inline-block;
          vertical-align: top;
          padding: 6px 5px 10px 4px;
          background: rgba(255, 255, 255, 0.8);
          border-radius: 6px;

          .tutorial_message_box {
            .info_title_wrap {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 7px;

              .info_heading_title {
                font-size: 8px;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.3px;
                text-align: left;

                img {
                  width: 100%;
                  max-width: 9px;
                  display: inline-block;
                  vertical-align: text-top;
                }
              }

              .info__text {
                font-size: 8px;
                font-style: normal;
                font-weight: 500;
                line-height: 1.2;
                letter-spacing: 0.3px;
              }
            }

            .info_box_wrap {
              font-size: 8px;
              font-style: normal;
              font-weight: 700;
              line-height: 1.2;
              text-transform: uppercase;
              margin-bottom: 4px;
              text-align: left;
            }

            .info_link_wrap {
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 1.2;
              text-align: left;
              word-wrap: break-word;
            }
          }
        }

        .ln_tutorial_swip p {
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 1.2;
          margin: 0px 0px;
          color: #ffffff;
        }
      }
    }
  }
}

// Step 2 Tutorial

.step2_tutorial_section {
  .tutorial__img {
    .ln_tutorial_mockup {
      &::before {
        left: calc(50% + 9.3px);
        background-image: url("../../../../assets/images/new_step2_tutorial.svg");
      }
    }
  }
}

.step2_tutorial_wrap {
  width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: top;
  border-radius: 6px;
  padding: 10px 0px 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);

  .step2_tutorial_heading {
    h2 {
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      margin: 0px 0px 9px;
    }

    p {
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      padding: 0px 4px;
      margin: 0px 0px 13px;
    }
  }

  .step2_tutorial_title {
    width: 100%;
    display: inline-block;
    vertical-align: top;

    img {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 15px;
    }

    a.tutorial_defult_btn {
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      color: #ffffff;
      border-radius: 3px;
      background-color: #007aff;
      border: 1px solid #007aff;
      padding: 4px 9px;
      transition: all 0.3s ease-in-out;
      width: max-content;
      display: inline-block;
    }
  }
}

// Step 3 Tutorial

.step3_tutorial_section {
  .tutorial__img {
    .ln_tutorial_mockup {
      max-width: 154px;

      &::before {
        left: calc(50% + 9.5px);
        background-image: url("../../../../assets/images/new_step_tutorial_img_3.svg");
      }
    }
  }
}

.step3_tutorial_section {
  .tutorial__img {
    .ln_tutorial_mockup {
      .ln_tutorial_wrapper {
        justify-content: unset;
        padding-top: 30px;
        height: calc(100% - 30px);
        overflow: hidden;
        border-radius: 0px 0px 20px 20px;
        .step3_inner_wrap {
          .step3_tutorial_header {
            display: flex;
            margin-top: 5px;
            padding: 0px 5px;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 5px;

            .tutorial_logo_wrapper {
              img {
                width: 100%;
                max-width: 60px;
              }
            }

            .tutorial_header_trigger {
              display: flex;
              grid-column-gap: 5px;
              margin-left: auto;

              .tutorial_header_icon {
                img {
                  width: 100%;
                  max-width: 12px;
                }
              }
            }
          }

          .tutorial_report_wrapper {
            .tutorial_report_menu {
              display: flex;
              justify-content: space-between;
              background-color: #ffffff;
              box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
                rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
              padding: 5px 6px;
              margin: 0px 5px 5px;
              border-radius: 3px;

              p {
                margin: 0px;

                a {
                  font-size: 9px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.3;
                  margin: 0px 0px;
                  color: #24537b;
                  background-color: #ecf7fb;
                  padding: 4px 5px;
                  width: max-content;
                  display: inline-block;
                  vertical-align: top;
                  border-radius: 5px;

                  img {
                    width: 100%;
                    max-width: 10px;
                    display: inline-block;
                    vertical-align: text-top;
                  }
                }
              }
            }
          }

          .tutorial_report_header {
            background-color: #ffffff;
            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
            padding: 6px 6px;
            margin: 0px 5px 5px;
            border-radius: 3px;

            .tutorial_report_img {
              width: 100%;
              margin-bottom: 4px;
              display: flex;
              align-items: center;
              grid-column-gap: 10px;

              img {
                width: 100%;
                max-width: 20px;
              }
            }

            .tutorial_report_word {
              font-size: 9px;
              font-style: normal;
              font-weight: 600;
              line-height: 1.3;
            }

            .tutorial_report_info {
              width: 100%;
              display: inline-block;
              vertical-align: top;

              ul {
                list-style: none;
                padding: 0px 0px;
                margin: 0px 0px;
                text-align: left;

                li {
                  font-size: 8px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.3;

                  img {
                    width: 100%;
                    max-width: 8px;
                    display: inline-block;
                    vertical-align: text-top;
                    margin-right: 4px;
                  }

                  &:not(:last-child) {
                    margin-bottom: 4px;
                  }

                  a {
                    color: #24537b;
                  }
                }
              }
            }
          }

          .tutorial_location_info {
            background-color: #ffffff;
            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
            padding: 6px 6px;
            margin: 0px 5px;
            border-radius: 3px;

            .tutorial_gps_title {
              h2 {
                font-size: 10px;
                line-height: normal;
                font-weight: 600;
                margin: 0px 0px 7px;
                display: flex;
                align-items: center;
                justify-content: center;
                grid-column-gap: 3px;

                img {
                  width: 100%;
                  max-width: 8px;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }

            .tutorial_map_info {
              img {
                width: 100%;
                border-radius: 2px;
              }
            }

            .tutorial_table_info {
              width: 100%;
              display: inline-block;
              vertical-align: top;
              margin-bottom: 5px;

              table {
                width: 100%;
                background: rgba(199, 199, 199, 0.1);
                border-radius: 5px;
                border-collapse: collapse;
                overflow: hidden;

                thead {
                  border-radius: 5px 5px 0px 0px;
                  background: #ecf7fb;
                  width: 100%;

                  tr {
                    th {
                      font-size: 9px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 1.3;
                      padding: 4px 4px;
                    }
                  }
                }

                tbody {
                  tr {
                    td {
                      font-size: 9px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 1.3;
                      color: #24537b;
                      padding: 5px 2px;
                      text-align: center;
                      border-top: 1px solid rgba(0, 0, 0, 0.05);
                      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

                      span {
                        img {
                          width: 100%;
                          max-width: 8px;
                          display: inline-block;
                          vertical-align: middle;
                          margin-left: 3px;
                          animation: spin 4s infinite linear;
                        }
                      }
                    }
                  }
                }

                tr {
                  .border-right {
                    border-right: 1px solid rgba(0, 0, 0, 0.05);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.setp_tutorial_modal .ant-modal-content {
  background-color: transparent;
  box-shadow: unset;
}
