/**************Modal 1**************/

.ant-modal.wpb_unlock__one_wrapper {
    width: 100% !important;
    max-width: calc(595px - 30px) !important;

    @media screen and (max-width: 575px) {
        max-width: calc(100vw - 20px) !important;
    }
}

.wpb_unlock__one_wrapper {
    .ant-modal-content {
        padding: 0px 0px !important;
    }
}

/**************Modal 2**************/

.ant-modal.wpb_expired__wrapper {
    width: 100% !important;
    max-width: calc(595px - 30px) !important;

    @media screen and (max-width: 575px) {
        max-width: calc(100vw - 20px) !important;
    }
}

.wpb_expired__wrapper {
    .ant-modal-content {
        padding: 0px 0px !important;
    }
}

/**************Modal 3**************/


.ant-modal.wpb_buy__reports_wrapper {
    width: 100% !important;
    max-width: calc(595px - 30px) !important;

    @media screen and (max-width: 575px) {
        max-width: calc(100vw - 20px) !important;
    }
}

.wpb_buy__reports_wrapper {
    .ant-modal-content {
        padding: 0px 0px !important;
    }
}

/**************Modal Css**************/

  .wpb_report__wrapper {
    padding: 30px 30px;
    text-align: center;
    @media screen and (max-width: 575px){
        padding: 15px 15px;
    }
    .vc_icon__general {
      margin-bottom: 20px;
      @media screen and (max-width: 575px){
        margin-bottom: 10px;
    }
    }
    .vc_content__general {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      h2 {
        font-size: 24px;
        line-height: 1.1;
        margin: 0px 0px;
        margin-bottom: 15px;
        color:  #000000;
        @media screen and (max-width: 575px){
            font-size:20px;
        }
      }
      p {
        font-size: 18px;
        line-height: 1.2;
        margin: 0px 0px;
        margin-bottom: 20px;
        color:  #777777;
        @media screen and (max-width: 575px){
            font-size: 16px;
            margin-bottom: 10px;
        }
      }
    }

    .vc_info__general{
        p {
        font-size: 18px;
        line-height: 1.3;
        margin: 0px 0px;
        color: #777777;
        margin-top: 20px;
        @media screen and (max-width: 575px){
            font-size: 16px;
        }
    }
    }
    .vc_button__general {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      button.vc_btn3-inline {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        font-size: 18px;
        line-height: 1.3;
        font-weight: 500;
        color: #ffffff;
        padding: 10px 15px;
        background-color: #24537b;
        border: 1px solid #24537b;
        border-radius: 100px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        @media screen and (max-width: 575px){
            font-size: 16px;
        }
        &:hover {
          color: #24537b;
          border: 1px solid #24537b;
          background-color: transparent;
        }
        &:first-child {
          margin-bottom: 15px;
          @media screen and (max-width: 575px){
            margin-bottom: 8px;
        }
        }
      }
    }
  }