.faq-main-section {
  .back-grad-title {
    .span {
      font-size: 20px;
      text-align: center;
      color: white;
    }
  }
  .faq-bg {
    .faq-container {
      max-width: 1650px;
      width: 100%;
      margin: 0 auto;
      .bg-section {
        padding: 30px 30px 140px;
        .frequently-asked-question {
          .question-title {
            text-align: center;
            margin-bottom: 70px;
            p {
              margin: 0;
              font-style: normal;
              font-weight: 700;
              font-size: 55px;
              line-height: 66px;
              margin-bottom: 20px;
            }
            span {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
            }
          }
          .question-collapse {
            max-width: 1000px;
            margin: 0 auto;
            width: 100%;
            .collapse-box {
              background: white;
              padding: 20px 25px;
              border: 0.5px solid;
              border-color: #e3e3e3;
              border-radius: 12px;
              margin-bottom: 30px;
              cursor: pointer;
              .collapse-box-df-title {
                display: flex;
                justify-content: space-between;
                .collapse-box-title {
                  p {
                    margin: 0;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                  }
                }
              }
              .open-tab-desc {
                width: 80%;
                margin-top: 20px;
                p {
                  margin: 0;
                  font-weight: 400;
                  font-size: 17px;
                  line-height: 26px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .faq-main-section .faq-bg .faq-container .bg-section {
    padding: 30px 30px 70px;
  }
  .collapse-box-df-title {
    gap: 20px;
    align-items: center;
  }
  .collapse-box-icon {
    flex: 0 0 18px;
    img {
      max-width: 100%;
      display: block;
    }
  }
}
@media screen and (max-width: 480px) {
  .collapse-box {
    .open-tab-desc {
      width: 100% !important;
    }
  }
  .faq-main-section {
    .back-grad-title {
      .span {
        margin: 0 30px;
        margin-top: 12px;
      }
    }
  }
}
