.wpb_info_wrapper .mb-1 {
  margin-bottom: 30px;
}

.wpb_info_wrapper .mb-2 {
  margin-bottom: 20px;
}

.wpb_info_wrapper .mb-3 {
  margin-bottom: 10px;
}

.wpb_info_wrapper .mb-4 {
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .wpb_info_wrapper .mb-1 {
    margin-bottom: 15px;
  }
}

.border-bottom {
  border-bottom: 1px solid rgb(202 202 202 / 70%);
  padding-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .border-bottom {
    padding-bottom: 20px;
  }
}
/*********Policy Banner CSS*********/

.Policy-header-section {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  background-color: #24537b;
  padding: 70px 0px;
  text-align: center;
  border-radius: 0px 0px 60px 60px;
}

.Policy-header-section h1.Policy-title {
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  margin: 0px 0px;
  color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .Policy-header-section {
    padding: 50px 0px;
    border-radius: 0px 0px 40px 40px;
  }
  .Policy-header-section h1.Policy-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .Policy-header-section {
    padding: 40px 0px;
    border-radius: 0px 0px 20px 20px;
  }
  .Policy-header-section h1.Policy-title {
    font-size: 32px;
  }
}

/*********Policy Banner CSS*********/

.cms__poolicy_wrapper {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 30px 30px;
  border-radius: 10px;
  margin: 60px 0px;
  background: #ffffff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  strong.policy_desc {
    font-weight: 700;
  }
}

@media only screen and (max-width: 767px) {
  .cms__poolicy_wrapper {
    padding: 20px 20px;
  }
}

.wpb_summary_cms {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  border-radius: 16px;
  padding: 27px 30px;
  background: rgba(36, 83, 123, 0.1);
}

@media only screen and (max-width: 767px) {
  .wpb_summary_cms {
    padding: 15px 15px;
  }
}

h2.policy_titleh2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  margin: 0px 0px 20px;
  color: #24537b;
}

@media only screen and (max-width: 767px) {
  h2.policy_titleh2 {
    font-size: 26px;
    margin: 0px 0px 15px;
  }
}

/**********************************/

ul.wpb_list_wrapper {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 0px 0px;
  margin: 0px 0px;
  padding-left: 32px;
  list-style: none;
}

@media only screen and (max-width: 767px) {
  ul.wpb_list_wrapper {
    padding-left: 16px;
  }
}

ul.wpb_list_wrapper li {
  position: relative;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: #000000;
  line-height: 1.2;
  margin-bottom: 13px;
  padding-left: 26px;
}

@media only screen and (max-width: 767px) {
  ul.wpb_list_wrapper li {
    font-size: 16px;
  }
}

ul.wpb_list_wrapper li a {
  color: #000000;
}

ul.wpb_list_wrapper li b {
  font-weight: 700;
}

ul.wpb_list_wrapper li:last-child {
  margin-bottom: 0px;
}

ul.wpb_list_wrapper li:before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: auto;
  left: 0px;
  right: auto;
  width: 20px;
  height: 20px;
  background-image: url(../../assets/images/policy_listicon_blu.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.policy_desc {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #000000;
  margin-top: 0px;
  margin: 0px 0px;
}

.policy_desc a {
  color: #24537b;
}

// Inner Ul list

h3.policy_titleh3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin: 0px 0px 20px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  h3.policy_titleh3 {
    font-size: 22px;
  }
}

ul.inner_list_wrap {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 0px 0px;
  margin: 0px 0px;
  padding-left: 20px;
}

ul.inner_list_wrap li {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 8px;
}

ul.inner_list_wrap li:last-child {
  margin-bottom: 0px;
}
