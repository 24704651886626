.phone-owner-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 30px;
  @media only screen and (max-width: 500px){
    grid-template-columns: repeat(1, 1fr);
    row-gap: 15px;
  }
  .phone-owner-box {
    width: 100%;
    display: inline-block;
    vertical-align: top;

    hr {
      margin: 10px 0px 0px;
      border: unset;
      border-bottom: 1px solid rgba(36, 83, 123, 0.1);
    }

    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.4;
      margin: 0px 0px 0px;
      color: #24537B;
    }

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      color: #777777;
      margin-bottom: 13px;
      display: inline-block;
      vertical-align: top;
    }
  }
}

@media only screen and (max-width: 600px) {
  .phone-owner-wrap .phone-owner-box:nth-last-child(-n + 2) hr {
    border-bottom: 1px solid rgba(36, 83, 123, 0.1);
    margin-top: 10px;
  }

  .phone-info-wrap .phone-info-box:nth-last-child(-n + 2) hr {
    border: unset;
    margin: 0;
  }

  .phone-info-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 375px) {
  .phone-info-wrap {
    row-gap: 15px;
  }

  .phone-info-wrap .phone-info-box:nth-last-child(-n + 2) hr {
    border-bottom: 1px solid rgba(36, 83, 123, 0.1);
    margin-top: 10px;
  }

  .phone-info-wrap .phone-info-box:nth-last-child(-n + 1) hr {
    border: unset;
    margin: 0;
  }

  .phone-info-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

.blurred {
  filter: blur(5px);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

button {
  &.unlock-btn {
    display: inline-block;
    background-color: #24537b;
    padding: 14px 60px 14px 30px;
    border-radius: 30px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    border: none;
    cursor: pointer;
    span {
      font-size: 18px;
      line-height: 1.3;
      margin: 0px 0px;
      color: #ffffff !important;
      font-weight: 500;
      text-decoration: none;
      @media only screen and (max-width: 475px){
        font-size: 14px;
      }
    }
  }
}
.unlock-btn-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  line-height: 0px;
}
