.high__lang_wrapper {
    .ds_full_reportMenu {
      .menu_item_box_wrap {
        .menu_item_btn {
          row-gap: 13px;
          p {
            flex: auto;
            &:last-child {
              flex: inherit;
            }
            a.menu_button {
              width: 100%;
              text-align: center;
              img {
                vertical-align: text-bottom;
              }
            }
          }
        }
      }
    }
    .button-container {
      .info-button {
        width: max-content;
        img {
          margin-right: 5px;
        }
      }
    }
    .monitor-cmp-wrap {
      .complaints__btn {
        a.complaints__btn {
          max-width: max-content;
        }
      }
    }
  }
  @media screen and (min-device-width: 901px) and (max-device-width: 1199px) {
    .high__lang_wrapper {
      .social-profiles--boxes {
        .social-profiles-btn {
          width: 100%;
          p {
            width: max-content;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  @media screen and (min-device-width: 971px) and (max-device-width: 1085px) {
    .reputation-phn-content {
      ul.reputation_listing_area {
        li {
          &:nth-last-child(-n+2) {
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(36, 83, 123, 0.2);
            padding-bottom: 15px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 640px) {
    .high__lang_wrapper {
      .ds_full_reportMenu {
        .menu_item_box_wrap {
          .menu_item_btn {
            p {
              a.menu_button {
                justify-content: center;
              }
              &:last-child {
                flex: auto;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .high__lang_wrapper {
      .social-profiles--boxes {
        .social-profiles-btn {
          width: 100%;
          p {
            width: max-content;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 479px) {
    .high__lang_wrapper {
      .social-profiles--boxes {
        .social-profiles-content {
          width: 100%;
          margin-top: 10px;
          margin-left: 0px;
        }
      }
    }
  }
  


@media only screen and (max-width: 575px) {
	.high__lang_wrapper {
		.social-profiles--boxes {
			.social-profiles-btn {
				p {
					width: 100%;
					a {
						text-align: center;
						width: 100%;
					}
				}
			}
		}
	}
}



// Phone Reputation Score 
.high__lang_wrapper {
	.reputation-phn-content {
		ul.reputation_listing_area {
			display: inline-block;
			width: 100%;
			li {
				width: 100%;
				padding-bottom: 15px;
				margin-bottom: 15px;
				border-bottom: 1px solid rgba(36, 83, 123, 0.2);
				&:last-child {
					margin-bottom: 0px;
					border: unset;
					padding-bottom: 0px;
				}
			}
		}
	}
}


// Download PDF Modal
.ant-modal-wrap {
	.multiple_popup__wrap {
		.wpb_report_popup {
			.mlt_popup__payment {
				form {
					.dsl_report {
						.dsl_report_payment {
							span {
								font-size: 15px;
							}
						}
						.dsl_report_field {
							span {
								font-size: 15px;
							}
						}
						span.report_radio_wrap {
							&:before {
								width: 16px;
								height: 16px;
								margin-right: 9px;
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 575px) {
	.ant-modal-wrap {
		.multiple_popup__wrap {
			.wpb_report_popup {
				.mlt_popup__payment {
					form {
						.dsl_report {
							flex-wrap: wrap;
							.dsl_report_field {
								width: 100%;
								margin-bottom: 15px;
							}
							.dsl_report_payment {
								width: 100%;
								display: flex;
								justify-content: space-between;
							}
						}
					}
				}
			}
		}
	}
}



@media only screen and (max-width: 575px) {
	.button-container {
		flex-wrap: wrap;
	}
	.high__lang_wrapper {
		.button-container {
			.info-button {
				width: 100%;
				justify-content: center;
			}
		}
	}
}
